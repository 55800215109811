// modified on: 2019-11-11 15:58:59
$body-bg: #FFFFFF;
$body-color: #212529;
$font-family-sans-serif: "Open Sans";
$font-family-monospace: "Courier New";
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$white: #FFFFFF;
$gray-100: #F8F9FA;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #6C757D;
$gray-700: #495057;
$gray-800: #343A40;
$gray-900: #212529;
$black: #000000;
$blue: #007BFF;
$indigo: #6610F2;
$purple: #6F42C1;
$pink: #E83E8C;
$red: #DC3545;
$orange: #FD7E14;
$yellow: #FFC107;
$green: #28A745;
$teal: #20C997;
$cyan: #17A2B8;
$primary: #007BFF;
$secondary: #6C757D;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$border-radius: .25rem;
$border-radius-lg: .3rem;
$border-radius-sm: .2rem;
$box-shadow: 0 .5rem 1rem rgba($black, .15);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-grid-classes: true;
$enable-print-styles: true;
