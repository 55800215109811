.tx-powermail{
    form.powermail_morestep{
        .powermail_fieldset{
            margin-bottom: 0px;
            display: none;
            &.current{
                display: block;
            }
        }
        input[type="submit"]{
            display: none;
        }
    }
}

.tx-rscwleadhandling-fom-navigation{
    .form-navigation-body{
        padding: 0 0 1rem 0;
        .form-section{
            display: none;
            &.current{
                display: block;
            }

            .sub-topic-container{
                &.hide{
                    display: none;
                }
            }

            .topic{
                position:relative;
                width: 100%;
                height: 100%;
                label{
                    overflow: hidden;
                    border:   1px solid #fff;
                    padding:  20px 10px;
                    position: relative;
                    cursor:   pointer;
                    box-shadow:      0 0 1px #333;
                    display:         flex;
                    align-items:     center;
                    justify-content: center;
                    text-align:      center;
                    width: 100%;
                    height: 100%;
                    height: 135px;

                    @include media-breakpoint-down(lg){
                        height: 240px;
                    }
                    @include media-breakpoint-down(md){
                        height: 175px;
                    }
                    @include media-breakpoint-down(sm){
                        height: 200px;
                    }
                    @include media-breakpoint-down(xs){
                        height: 160px;
                    }

                    .icon{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        max-height: 100%;
                        img{
                            min-height: 24px;
                        }
                        p{
                            margin-top: 0.25rem;
                            text-transform: uppercase;
                            line-height: 18px;
                            margin-bottom: 0px;
                        }
                    }
                }


                .icon_dark {
                    display: none;
                }

                .checked-mark{
                    display: none;
                    content: "";
                    position:absolute;
                    margin:-30px;
                    width:60px;
                    height:60px;
                    transform:rotate(45deg);
                    background-color:$rhenus-blue; /* to see where it is */
                    top: 0;
                    left: 0;
                    i{
                        transform:rotate(-45deg);
                        color: #fff;
                        position: relative;
                        bottom: -20px;
                        right: -20px;
                    }
                }

                :checked + label .checked-mark{
                    display: block;
                }

                :checked + label .icon_dark, label:hover .icon_dark {
                    display: inline;
                }
                :checked + label .icon_light, label:hover .icon_light {
                    display: none;
                }

                :checked + label,label:hover {
                    box-shadow: 0 0 5px #333;
                    border:     none;
                    font-weight: bold;
                    color: $rhenus-blue;
                }

                :checked + label::before,label:hover::before {
                    box-shadow: 0 0 5px #333;
                    z-index:    -1;
                }
                input[type=radio],
                input[type=button] {
                    position: absolute;
                    opacity:  0;
                    width:    0;
                    height:   0;
                }
            }
            &.first-level{
                .topic label{
                    height: 210px;
                    @include media-breakpoint-down(lg){
                        height: 175px;
                    }
                    @include media-breakpoint-down(md){
                        height: 275px;
                    }
                    @include media-breakpoint-down(sm){
                        height: 200px;
                    }
                    @include media-breakpoint-down(xs){
                        height: 160px;
                    }
                    .icon p{
                        margin-top: 1rem;
                    }
                }
            }

            h1{
                margin-bottom: 2.5rem;
            }

            .subheader{
                font-size: 1.5rem;
                font-weight: 300;
                line-height: 2.5rem;
                margin-bottom: 2.5rem;
            }

            .second-level-header-transport,.second-level-header-default{
                display: none;
            }
        }
    }
    .spinner-border{
        width: 4rem;
        height: 4rem;
    }
}

.multistep-form-navigation{
    position: relative;

    .previous:not(.button-ghost){
        background: transparent;
        border: none;
        color: $rhenus-blue;
    }

    &.affix{
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 3;
    }
    @include media-breakpoint-down(xs){
        button,input{
            width: 100%;
        }
    }
}

.multistep-form-navigation,.powermail_form,.tx-rscwleadhandling-fom-navigation,.navigation-and-form,#tx-rscwleadhandling-progressbar,.powermail_fieldset,.only-show-b2b{

    &.hide{
        display: none !important;
    }

}

#tx-rscwleadhandling-progressbar{
    margin-bottom: 2rem;

    .navigation-form-label{
        &.small-label{
            @extend .h4;
            margin-bottom: 1.25rem;
        }
    }

    .progressbar-content{
        margin-left: -5px;
        margin-right: -5px;
        &>div{
            padding-left: 5px;
            padding-right: 5px;
        }
        .divider{
            width: 100%;
            height: 0.5rem;
            background-color: $rhenus-blue;
            margin: 8px 0px;
        }
        .title{
            font-weight: bold;
            text-transform: uppercase;
            color: $rhenus-blue;
        }
        .icon{
            height: 32px;
            width: 32px;
            display: block;
            img{
                width: 100%;
                height: auto;
            }
        }
        .rest-col{
            .divider{
                background-color: $rhenus-blue-lighter;
            }
        }
        .mobile-label{
            display: none;
            font-weight: bold;
            text-transform: uppercase;
            color: $rhenus-blue;
        }
        @include media-breakpoint-down(sm){
            .title{
                display: none;
            }
            .mobile-label{
                display: block;
            }
        }
    }
}

.powermail_confirmation{
    .button-edit{
        background-color: transparent;
        border: transparent;
        color: $rhenus-gray-dark;
        i{
            color: $rhenus-gray-dark;
            margin-right: 1rem;
            @include media-breakpoint-down(sm){
                margin-right: 0px;
            }
        }
        span{
            @include media-breakpoint-down(sm){
                display: none;
            }
        }
        &:hover{
            text-decoration: underline;
        }
    }
    .editable-answers{
        padding-left: 15px;
        padding-right: 15px;
        .div-table>.row,.div-table>div>.row{
            &:nth-of-type(odd)>div{
                background-color: #fff;
            }
            &:nth-of-type(even)>div{
                background-color: $rhenus-gray-teaser-back;
            }
            &>div{
                min-height: 53px;
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                word-break: break-word;
                &:first-child{
                    text-transform: uppercase;
                }
            }
            &.row-head{
                overflow: hidden;
                &>div{
                    background-color: #fff;
                    border-bottom: 2px solid $rhenus-content-alert-background-alt;
                    color: $rhenus-gray-dark;
                    vertical-align: middle;
                    font-weight: 300;
                    text-transform: uppercase;
                    height: 66px;
                    &:last-child{
                        position: relative;
                        background-color: #fff;
                        border-left: 1px solid #fff;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        border-right: 2px solid $rhenus-content-alert-background-alt;
                        @include media-breakpoint-down(sm){
                            border-left: none;
                            border-top: 1px solid #fff;
                        }
                        form,.button-edit{
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                        &::after{
                            content: "";
                            position:absolute;
                            margin: -15px;
                            width: 30px;
                            height: 30px;
                            transform:rotate(45deg);
                            background-color:#fff; /* to see where it is */
                            bottom: 0;
                            right: 0;
                            border-left: 2px solid $rhenus-content-alert-background-alt;
                        }
                    }
                    .table-page-title{
                        width: 100%;
                    }
                }
                .accordion-header{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    cursor: pointer;

                    .accordion-indicator {
                        display:        inline-block;
                        margin:         0px 1rem 0px 0px;
                        vertical-align: -7px;

                        &:before {
                            font-size:      33px;
                            @include material-icon;
                            display:        inline-block;
                            content:        "remove";
                            vertical-align: -2px;
                            color: $rhenus-gray-dark;
                        }
                    }
                    &.collapsed {
                        .accordion-indicator {
                            &:before {
                                content: "add";
                            }
                        }
                    }
                }
                &.active{
                    //margin-bottom: 0rem;
                    &>div{
                        background-color: $rhenus-content-alert-background-alt-01;
                        &:last-child{
                            background-color: #fff;
                        }
                        // color: #fff;
                        // .accordion-indicator{
                        //     &::before{
                        //         color:   $white;
                        //     }
                        // }
                        // .button-edit,.button-edit i{
                        //     color:   $white;
                        // }
                    }
                }
            }
        }
        .accordion-content{
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 0.5rem;
        }
    }

    @include media-breakpoint-down(sm){
        .editable-answers+.btn-group{
            &,.button-primary,.edit-form{
                display: block;
                width: 100%;
            }
        }
    }
    .confirmation-submit, .confirmation-submit-form, .confirmation-btn-group{
        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }
}

#confirmation-edit-form{
    .white-popup{
        padding: 80px 40px 36px;
    }
    h2{
        margin-bottom: 1.75rem;
    }
    p{
        &:last-of-type{
            margin-bottom: 40px;
        }
    }
}
