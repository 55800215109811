// add gray color map as bg classes
@each $color, $value in $grays {
    @include bg-variant(".bg-gray-#{$color}", $value);
}

.bg-rhenus-blue-light{
    background-color: $rhenus-blue-light;
}
.bg-rhenus-blue-lighter{
    background-color: $rhenus-blue-lighter;
}

.list-with-divider {
    > li:not(:last-child) {
        position:     relative;
        margin-right: 1.5rem;

        &:after {
            position:   absolute;
            right:      -1rem;
            margin-top: -1px;
            content:    "|";
            color:      $gray-500;
        }
    }
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;

    * {
        outline: none !important;
    }
}

body {
    font-family: "TradeGothicLTW05", "PT Sans", serif;
    overflow-x:  hidden;
    //font-family: 'TradeGothicNextLT';
    overflow-y:  hidden;

    html[lang="ru-RU"] & .frame {
        font-family: "PT Sans", "TradeGothicLTW05", serif;
    }
}

.page-body {
    /**
        Space between the content and the left/right screen should be 30px on XS-Viewports.
        By setting the padding-{right|left} of .container-elements to 30px (instead of 15px per default) we not only
        set the space to 30px, but also dont interfere with elements that should be displayed without any space to the
        left or the right (e.g. "24 Extended Stripe").
     */
    @include media-breakpoint-only(xs) {
        .container {
            padding-right: 30px;
            padding-left:  30px;

            .container {
                padding-right: initial;
                padding-left:  initial;
            }
        }
    }

    &.transparancy-80 {
        .main-header.navigation-desktop {
            background-color: rgba($rhenus-blue-secondary, 0.8);
        }
    }
}

body > #typo3-preview-info {
    top:   9px !important;
    right: 9px !important;
}

.page-wrapper {
    display:        flex;
    flex-direction: column;
    width:          100%;
    min-height:     100vh;


    .page-body {
        margin-top: 120px;
    }

    &.page-type-simple_form {
        .page-body {
            margin-top: 0;
        }
    }

    &.page-type-simple,
    &.page-type-news_detail_new {
        &:not(.with-lead-cta) {
            .stage, .big-stage-container {
                margin-top: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            .page-body {
                margin-top: 48px;
            }
        }
    }

    &.page-type-simple {
        .page-body {
            margin-top: 48px;
        }

        &.page-type-simple_without-main-menu {
            @include media-breakpoint-up(xl) {
                .page-body {
                    margin-top: 65px;
                }
            }
        }

        &.page-type-simple_with-main-menu {
            @include media-breakpoint-up(xl) {
                .page-body {
                    margin-top: 120px;
                }
            }
        }
    }

    &.page-type-simple_form{
        .page-body{
            &>.container{
                &:first-child{
                    .frame-type-list{
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .footer-grey,&+.footer-grey{
            min-height: 80px;
        }
    }
}

.page-body {
    flex:       1;
    flex-basis: auto;
}

.no-hover-text-decoration:hover {
    text-decoration: none;
}

.section__background {
    background-repeat:   no-repeat;
    background-position: center center;
    background-size:     cover;
}

.section__content {
    position: relative;
    z-index:  2;
}

.section__dimmer {
    position: relative;
    z-index:  1;

    &:after {
        position: absolute;
        top:      0;
        right:    0;
        bottom:   0;
        left:     0;
        content:  "";
    }

    &.--dimmer__dark {
        &:after {
            background: rgba(0, 0, 0, .5);
        }
    }

    &.--dimmer__light {
        &:after {
            background: rgba(255, 255, 255, .5);
        }
    }
}

.section__attachment {
    &.--attachment__scroll {
        background-attachment: scroll;
    }

    &.--attachment__fixed {
        background-attachment: fixed;
    }
}

// responsive videos

.frame-video {
    .ce-outer,
    .ce-inner,
    .ce-column {
        float:   none !important;
        padding: 0 !important;
    }

    figure.video {
        display:          block;
        margin-block-end: 0;
    }
}

.video-embed {
    position:       relative;
    overflow:       hidden;
    height:         0;
    padding-top:    30px;
    padding-bottom: 56.25%;

    iframe,
    object,
    embed,
    video {
        position: absolute;
        top:      0;
        left:     0;
        width:    100%;
        height:   100%;
        border:   none;
    }

    /*
    video.video-embed-item {
        width:  100%;
        height: auto;
    }
    */
}

.hidden {
    display: none;
}

.row.smaller-row{
    &,.row{
        margin-left: -5px;
        margin-right: -5px;
        &>div{
            padding-left: 5px;
            padding-right: 5px;
        }
        &>.container{
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.bilgenentoelung, .rheintank{
    .main-header-top{
        display: none;
    }
    .main-navigation{
        @include media-breakpoint-up(lg) {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
