/**
    In order to prevent collisions with .extended-image-teaser-infobox (element of .extended-image-teaser), we'll wrap
    the the block with a class that is unique.

    DO NOT USE .extended-image-teaser-infobox WITHOUT WRAPPING IT WITH .extended-image-teaser-infobox-container!
 */
.extended-image-teaser-infobox-container {
    overflow: hidden;

    /** Block */
    .extended-image-teaser-infobox {
        position: relative;
    }

    /** Elements */
    .extended-image-teaser-infobox__info-text {
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top:      80px;
            right:    50%;
            z-index:  10;
            width:    465px;
            color:    $white;
        }

        @include media-breakpoint-up(xl) {
            width: 555px;
        }

        > div {
            h2 {
                margin-bottom: 24px;

                font-size:     20px;
                line-height:   28px;

                @include media-breakpoint-up(md) {
                    font-size:   32px;
                    line-height: 40px;
                }
            }

            p {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .extended-image-teaser-infobox__info-number {
        @include media-breakpoint-up(md) {
            position: absolute;
            bottom:   0;
            left:     0;
            z-index:  10;
            width:    100%;
        }

        @include media-breakpoint-up(lg) {
            top:           80px;
            right:         0;
            bottom:        unset;
            left:          50%;
            width:         500px;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            width: 555px;
        }

        > div {
            display:         flex;
            flex-flow:       row wrap;
            justify-content: flex-start;
            gap:             40px 0;
            padding:         40px 30px;
            margin-left:     auto;
            background:      linear-gradient(90deg, #007CCB 0px, $rhenus-blue 100%);
            color:           $white;

            @media (min-width: 450px) {
                gap: 20px 0;
            }

            @include media-breakpoint-up(md) {
                gap:             40px 0;
                background:      linear-gradient(495deg, transparent 25px, rgba($rhenus-blue, 0.8) 25px);
                justify-content: space-around;
            }

            @include media-breakpoint-up(lg) {
                width:           calc(100% - 30px);
                padding:         40px;
                justify-content: flex-start;
            }

            .extended-image-teaser-infobox__info-number-manual-break {
                display: none;
                width:   100%;

                @media (min-width: 450px) {
                    display: block;
                    height:  0;
                }

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }

        &-item {
            flex: 0 1 100%;

            @media (min-width: 450px) {
                flex:          0 1 50%;
                margin-bottom: 0;
            }

            @include media-breakpoint-only(md) {
                flex: 0 1 auto;
            }

            @include media-breakpoint-up(lg) {
                flex: 0 1 50%;
            }

            h3 {
                margin-bottom:  12px;

                font-weight:    700;
                font-size:      16px;
                line-height:    24px;
                text-transform: none;

                @include media-breakpoint-up(xl) {
                    margin-bottom: 4px;

                    font-size:     20px;
                    line-height:   25px;
                }
            }

            span {
                font-weight: 700;
                font-size:   32px;
                line-height: 40px;

                @include media-breakpoint-up(xl) {
                    font-size:   38px;
                    line-height: 48px;
                }
            }
        }
    }

    .extended-image-teaser-infobox__image {
        figure {
            margin: 0;
        }
    }
}
