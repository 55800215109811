.solr-news-filter {
    padding:          40px 0 0;
    background-color: $rhenus-blue-accordion-header;

    @include media-breakpoint-down(md) {
        margin: 0;

        &_fixed-mobile {
            position: fixed;
            top:      48px;
            left:     0;
            right:    0;
            z-index:  100;
            padding:  40px 0;

            .solr-news-filter__hide-fixed {
                display: none;
            }

            &.solr-news-filter_scroll-up {
                padding: 40px 0 0;

                .solr-news-filter__hide-fixed {
                    display: block;
                }
            }
        }
    }

    .category-button-list {
        .selected-option {
            .btn {
                color:            $white;
                border:           1px solid $rhenus-blue-secondary;
                background-color: $rhenus-blue-secondary;
            }
        }
    }
}

.solr-news-filter__headline {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    i.material-icons {
        font-size: 24px;
        position:  relative;
        top:       3px;
    }

    h3 {
        display: inline-block;
    }
}

.solr-news-filter__filter-button {
    font-size:        16px;
    line-height:      16px;
    position:         relative;
    display:          block;
    width:            100%;
    padding:          15px 20px;
    color:            $rhenus-content-alert-background-alt;
    border:           1px solid $rhenus-content-alert-background-alt;
    background-color: $white;

    &_active, &:hover, &:focus, &:active, &[aria-expanded="true"] {
        text-decoration: none;
        color:           $rhenus-blue;
        border:          1px solid $rhenus-blue;
    }

    i.material-icons {
        font-size:  24px;
        position:   absolute;
        top:        13px;
        right:      12px;
        transition: transform .1s ease-in-out;
        color:      $rhenus-content-alert-background-alt;
    }

    &[aria-expanded="true"] {
        i.material-icons {
            transform: rotateX(180deg);
        }
    }

    span {
        font-size:        12px;
        font-weight:      bold;
        line-height:      12px;
        display:          inline-block;
        width:            16px;
        height:           16px;
        padding:          2px;
        text-align:       center;
        color:            $white;
        border-radius:    20px;
        background-color: $rhenus-blue;
    }
}

.solr-news-filter__filter-icon-button {
    display:    block;
    width:      48px;
    height:     48px;
    background: $rhenus-blue;

    .material-icons {
        font-size:   24px;
        line-height: 24px;
        padding:     12px;
        color:       $white;

        &:nth-child(2) {
            display: none;
        }
    }

    &[aria-expanded="true"] {
        background: $white;

        .material-icons {
            color: $rhenus-gray-dark;

            &:nth-child(1) {
                display: none;
            }

            &:nth-child(2) {
                display: block;
            }
        }
    }

    &:hover, &:active, &:focus {
        text-decoration: none;
    }
}

.solr-news-filter__filter-search-box {
    input {
        font-size:        16px;
        line-height:      16px;
        position:         relative;
        display:          block;
        width:            100%;
        height:           48px;
        padding:          12px 20px;
        color:            $rhenus-gray;
        border:           1px solid $rhenus-blue;
        border-radius:    0;
        background-color: $white;
    }

    .material-icons-wrapper {
        float:            right;
        width:            48px;
        height:           48px;
        padding:          11px;
        border:           1px solid $rhenus-blue;
        background-color: $rhenus-blue;
    }

    .material-icons {
        font-size:   24px;
        line-height: 1;
        color:       $white;
    }
}

.solr-news-filter__filter-bar {
    margin: 40px 0 0;

    @include media-breakpoint-down(md) {
        margin:  0;
        padding: 0;
    }
}

.solr-news-filter__subfilter {
    ul.category-button-list {
        margin: 0;

        li.news-category-button-list-item {
            padding-top: 24px;

            a {
                .btn {
                    border:           1px solid $rhenus-blue;
                    background-color: $white;
                }
            }

            a.selected-option, a:hover, a:active, a:focus {
                .btn {
                    color:            $white;
                    background-color: $rhenus-blue;
                }
            }
        }
    }

    &_text-search {
        width:       100%;
        padding-top: 60px;

        input {
            border: 1px solid $rhenus-blue;
        }
    }
}

.solr-news-filter__selected-filter-bar {
    &_empty-spacer {
        .wrapper {
            padding: 20px 0;
        }
    }

    .wrapper {
        padding: 24px 0 40px;
    }

    a {
        & {
            font-size:        14px;
            font-weight:      400;
            line-height:      20px;
            position:         relative;
            display:          inline-block;
            margin-right:     12px;
            padding:          4px 24px 4px 8px;
            color:            $white;
            border:           1px solid $rhenus-blue;
            border-radius:    24px;
            background-color: $rhenus-blue;

            &:hover, &:active, &:focus {
                text-decoration:  none;
                border:           1px solid $rhenus-gray-dark;
                background-color: $rhenus-gray-dark;
            }

            .material-icons {
                font-size:   16px;
                font-weight: 400;
                line-height: 1;
                position:    absolute;
                top:         6px;
                right:       6px;
            }
        }

        &.remove-all {
            padding-left:  24px;
            padding-right: 12px;

            .material-icons {
                left:  6px;
                right: unset;
                width: 16px;
            }
        }
    }

    span {
        & {
            font-size:        14px;
            font-weight:      400;
            line-height:      20px;
            position:         relative;
            display:          inline-block;
            margin-right:     12px;
            padding:          4px 24px 4px 8px;
            color:            $white;
            border:           1px solid $rhenus-blue;
            border-radius:    24px;
            background-color: $rhenus-blue;

            &:hover, &:active, &:focus {
                text-decoration:  none;
                border:           1px solid $rhenus-gray-dark;
                background-color: $rhenus-gray-dark;
            }

            .material-icons {
                font-size:   16px;
                font-weight: 400;
                line-height: 1;
                position:    absolute;
                top:         6px;
                right:       6px;
            }
        }

        &.remove-all {
            font-size:        16px;
            font-weight:      bold;
            line-height:      24px;
            padding:          3px 32px 3px 23px;
            text-transform:   uppercase;
            color:            $rhenus-blue;
            border:           none;
            background-color: transparent;

            .material-icons {
                font-size:   24px;
                line-height: 24px;
                top:         3px;
                right:       unset;
                left:        6px;
            }
        }
    }
}

.solr-news-filter__filter-bar_mobile {
    #open-filter-bar-mobile {
        display:         flex;
        align-items:     center;
        justify-content: center;
        gap:             15px;
        line-height:     16px;
        font-size:       18px;
        padding-top:     8px;
        padding-bottom:  9px;

        &:hover, &:active, &:focus, &:visited {
            color: $white;
        }

        &.button-ghost {
            color: $rhenus-blue;

            &:hover {
                color: $white;
            }
        }

        &.button-secondary {
            .filter-count {
                display:          inline-block;
                background-color: $white;
                color:            $rhenus-blue;
                border-radius:    10px;
                width:            20px;
                height:           20px;
                font-size:        12px;
                margin-left:      10px;
                display:          flex;
                align-items:      center;
                justify-content:  center;
            }
        }

        .material-icons {
            font-size:   20px;
            line-height: 28px;
        }

        .filter-count {
            display: none;
        }
    }

    .solr-filter-bar-mobile-content {
        > .container:first-child {
            padding-top:    48px;
            padding-bottom: 24px;
        }
    }

    .solr-news-filter__subfilter_text-search {
        margin-top:  24px;
        padding-top: 0;
    }

    .solr-news-filter__selected-filter-bar {
        padding: 0;

        .wrapper {
            padding-top: 12px;
        }
    }

    .mobile-selected-filter {
        & > a {
            margin-top: 12px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .close-filter-bar-mobile {
        cursor: pointer;

        i.material-icons {
            font-size:   24px;
            line-height: 32px;
        }
    }

    .filter-button-mobile-col {
        margin-bottom: 30px;
    }

    ul.category-button-list {
        border:           1px solid $rhenus-blue;
        border-top-width: 0;
        padding:          7px;

        li.news-category-button-list-item {
            padding-top: 7px;
            margin:      0 7.5px 0 0 !important;
        }
    }
}

.mfp-content {
    .solr-filter-bar-mobile-content {
        position:   relative;
        overflow-y: scroll;
        width:      100%;
        height:     100vh;
        padding:    24px 30px 0 30px;
        background: $white;

        .filter-icon {
            font-size: 22px;
            position:  relative;
            top:       3px;
        }

        .mfp-close {
            top:     7px;
            right:   20px;
            color:   #505050;
            opacity: 1;

            span {
                font-size: 36px;
                transform: translateY(10px);
            }
        }

        div.solr-news-filter__filter-search-box {
            margin-bottom: 31px;
            border-bottom: 1px solid #333333;

            input, span {
                background-color: $rhenus-gray-teaser-back;
            }

            .material-icons-wrapper {
                border: unset;

                i {
                    color: #424B5A;
                }
            }

            input {
                border:       unset;
                box-shadow:   unset;
                padding-left: 12px;
            }
        }

        .solr-news-filter__filter-button {
            padding-left:  12px;
            border-bottom: 1px solid $rhenus-content-alert-background-alt;
            margin-top:    31px;

            &.collapsed {
                border-bottom: 1px solid $rhenus-blue;
            }
        }

        .solr-news-filter__selected-filter-bar {
            .wrapper {
                padding-bottom: 28px; // 40px - 12px

                .facet {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .remove-all svg {
                    font-size:   24px;
                    font-weight: 400;
                    line-height: 24px;
                    position:    absolute;
                    top:         3px;
                    right:       unset;
                    left:        -3px;
                    fill:        $rhenus-blue;
                }
            }
        }

        .solr-news-filter__subfilter {
            &.solr-news-filter__subfilter_text-search {
                padding-top: 24px;
            }

            .wrapper {
                border:     1px solid $rhenus-blue;
                border-top: unset;

                ul.category-button-list {
                    display:        flex;
                    flex-direction: column;
                    padding-top:    0;

                    li.news-category-button-list-item {
                        border-bottom: 1px solid #D2D8DB;

                        a {
                            .btn {
                                border:     none;
                                color:      $rhenus-gray-dark;
                                text-align: left;
                                padding:    12px;
                                height:     auto;
                            }
                        }
                    }
                }
            }
        }

        .filter-button-mobile-col {
            .form-group {
                margin-bottom: 31px;
                position:      relative;

                .bootstrap-select {
                    width: 100% !important;

                    .btn {
                        background-color: $white;
                        border:           1px solid $rhenus-content-alert-background;
                        color:            $rhenus-content-alert-background;
                        border-radius:    0;
                        padding:          8px 12px;

                        &.dropdown-toggle {
                            &:after {
                                @extend .material-icons;
                                content:     "expand_more";
                                font-size:   18px;
                                display:     inline-block;
                                line-height: 1.5;
                                color:       #333333;
                                border:      unset;
                            }
                        }

                        &:focus {
                            outline:    unset !important;
                            box-shadow: unset !important;
                        }

                        &:active {
                            box-shadow: unset !important;
                        }

                        .filter-option {
                            position: relative;
                        }
                    }

                    div.dropdown-menu {
                        margin:        0 !important;
                        padding:       0 !important;
                        width:         100% !important;
                        border-radius: 0;
                        border:        1px solid $rhenus-blue;
                        border-top:    0;
                        min-width:     unset !important;
                        position:      unset !important;
                        transform:     unset !important;

                        ul.dropdown-menu {
                            li {
                                border-bottom: 1px solid #D2D8DB;

                                .dropdown-item {
                                    padding: 12px 12px 12px 38px;

                                    span.check-mark {
                                        display:  block;
                                        position: absolute;
                                        left:     14px;
                                        top:      6px;
                                    }
                                }

                                &:last-child {
                                    border-bottom: 0;
                                }

                                &.selected {
                                    .bs-ok-default {
                                        &:after {
                                            color:   $rhenus-blue;
                                            content: 'radio_button_checked';
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.show {
                        .btn {
                            border-left-color:  $rhenus-blue;
                            border-top-color:   $rhenus-blue;
                            border-right-color: $rhenus-blue;

                            &.dropdown-toggle {
                                &:after {
                                    content: "expand_less";
                                }
                            }
                        }
                    }

                    &.show-tick .dropdown-menu .selected span.check-mark {
                        top: 6px !important;
                    }

                    .bs-ok-default {
                        &:after {
                            @extend .material-icons;
                            content:     'radio_button_unchecked';
                            font-size:   20px;
                            display:     inline-block;
                            line-height: 1.5;
                            border:      unset;
                        }

                        &:first-child {
                            content: '';
                        }
                    }
                }

                .filter-count-bubble {
                    position:         absolute;
                    top:              9px;
                    right:            36px;
                    height:           24px;
                    width:            24px;
                    border-radius:    12px;
                    background-color: $rhenus-blue;
                    color:            $white;
                    font-size:        12px;
                    display:          flex;
                    justify-content:  center;
                    align-items:      center;
                }
            }
        }

        #remove-all-filter {
            font-size:        14px;
            font-weight:      400;
            line-height:      20px;
            position:         relative;
            display:          inline-block;
            padding:          4px 12px 4px 24px;
            color:            $white;
            border:           1px solid $rhenus-blue;
            border-radius:    24px;
            background-color: $rhenus-blue;
            text-decoration:  none;

            i.material-icons {
                left:     6px;
                right:    unset;
                width:    16px;
                position: absolute;
                top:      6px;
            }
        }

        .apply-filter {
            position:         fixed;
            bottom:           0;
            left:             0;
            right:            0;
            width:            100vw;
            background-color: $rhenus-blue;
            border:           2px solid rgba($rhenus-blue-secondary, .5);
            height:           58px;

            &.disabled {
                background-color: $rhenus-gray;
                color:            $rhenus-gray-light;
                border-color:     $rhenus-gray;

                &:hover {
                    box-shadow: none;
                    cursor:     default;
                }
            }

            &.sticky {
                position:    sticky;
                margin-left: -15px;
            }
        }

        @include media-breakpoint-down(md) {
            .tx-solr-autosuggest {
                display: none !important;
            }
        }
    }
}
