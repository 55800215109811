/**
    Breakpoint for toggling the menu from mobile to desktop.

    E.g. a value of "lg" would mean, that up and including the lg-viewport would show the mobile menu.
 */
$menu-mobile-breakpoint:                     lg;
$menu-mobile-breakpoint-up:                  xl;
$menu-mobile-language-dropdown-item-padding: 0 15px;

/** Logo Swapping */
#projected-logo-container {
    min-height: 60px;

    .projected-logo {
        position: relative;
        z-index:  301;
        display:  inline-block;
        float:    right;

        object {
            width:  166.668px;
            width:  auto;
            height: 18px;
            @include media-breakpoint-up(xl) {
                height: 31px;
            }

            svg {
                width: 166.668px;
            }
        }
    }

    .subbrand-label {
        line-height: 1;
    }

    .subbrand-logo {
        opacity: 0;
    }
}

/** Visibility depending on viewport */
.navigation-mobile {
    display: none;

    @include media-breakpoint-down($menu-mobile-breakpoint) {
        display: block;
    }
}

.navigation-desktop {
    @include media-breakpoint-down($menu-mobile-breakpoint) {
        display: none;
    }
}

body {
    &.navigation-open {
        overflow: hidden;
    }

    &.mobile-navigation-open {
        overflow: hidden;
        height:   100vh;
    }

    &.navigation-open {
        .navi-background {
            @include media-breakpoint-up($menu-mobile-breakpoint-up) {
                position:         fixed;
                z-index:          301;
                top:              120px;
                right:            0;
                bottom:           0;
                left:             0;
                display:          block;
                transition:       top 0.4s ease-out;
                background-color: rgba($rhenus-blue-secondary, 0.8);
            }
        }

        &.navigation-desktop-active {
            .navi-background {
                @include media-breakpoint-up($menu-mobile-breakpoint-up) {
                    top: 66px;
                }
            }
        }

        .main-header.navigation-desktop {
            z-index: 302;
        }

        &.navigation-open-search {
            .navi-background {
                @include media-breakpoint-up($menu-mobile-breakpoint-up) {
                    background-color:        rgba(0, 0, 0, .7);
                    top:                     0;
                    -webkit-backdrop-filter: blur(10px);
                    backdrop-filter:         blur(10px);
                    -webkit-transition:      all .3s ease-in-out;
                    transition:              all .3s ease-in-out;
                }
            }

            .main-header-overlay {
                position:   absolute;
                top:        0px;
                left:       0px;
                width:      100%;
                height:     100%;
                background: rgba(0, 0, 0, .7);
                z-index:    5;
            }

            &.blue {
                height: auto;

                .navi-background {
                    @include media-breakpoint-up($menu-mobile-breakpoint-up) {
                        -webkit-backdrop-filter: unset;
                        backdrop-filter:         unset;
                    }
                }

                .career-sidebar-cta-container {
                    z-index: 300;
                }
            }
        }
    }
}

/** Mobile navigation */
.header-bar-mobile {
    line-height:      48px;
    position:         fixed;
    z-index:          1000;
    top:              0;
    right:            0;
    left:             0;
    height:           48px;
    color:            $white;
    background-color: rgba($rhenus-blue-secondary, 0.7);
    transition:       background-color 0.4s ease-out;

    .mobile-navigation-open & {
        background-color: rgba($rhenus-blue-secondary, 0.9);
    }

    .container {
        position: relative;
    }

    .mobile-flyout-trigger, .mobile-search-flyout-trigger {
        cursor: pointer;

        &:before {
            @include material-icon;
            font-size:  28px;
            display:    inline-block;
            margin-top: 10px;
            content:    "menu";
        }

        &.active:before {
            content: "close";
        }
    }

    .mobile-search-flyout-trigger {
        margin-left: 20px;

        &:before {
            font-size:  22px;
            margin-top: 0;
            content:    "search";
            position:   relative;
            top:        -3px;
        }

        &.open {
            &:before {
                content: "close";
            }
        }
    }

    .logo {
        position: absolute;
        top:      -1px;
        right:    15px;

        @include media-breakpoint-down(sm) {
            right: 30px;
        }

        img {
            width:  auto;
            height: 18px;
        }
    }
}

.mobile-language-bar {
    position:         fixed;
    top:              48px;
    right:            0;
    left:             0;
    height:           48px;
    z-index:          1001;
    background-color: $rhenus-blue-lighter;
    opacity:          0;
    transition:       all 0.4s ease-out;
    transform:        translateY(-48px);

    .mobile-navigation-open & {
        opacity:          1;
        transform:        translateY(0);
        background-color: rgba($rhenus-blue-lighter, 0.9);
    }

    @include media-breakpoint-up($menu-mobile-breakpoint-up) {
        display: none;
    }

    .container {
        display:         flex;
        flex-flow:       row nowrap;
        justify-content: space-between;
        align-items:     center;
        height:          100%;
    }

    /**
        The mobile language switcher will be displayed as a horizontal listing or as a dropdown depending on the number
        of languages. See fluid-partial 'LangMenuMM' for more details.
     */
    .mobile-language-switcher {
        display:         flex;
        flex-flow:       row nowrap;
        justify-content: flex-end;
        align-items:     baseline;

        .mobile-language-switcher-link {
            padding:      0 10px;
            border-right: 1px solid $rhenus-blue;

            &_current {
                font-weight: 700;
            }

            &:last-child {
                border-right:  unset;
                padding-right: 0;

                @include media-breakpoint-up(sm) {
                    padding-right: 15px;
                }

                @include media-breakpoint-up(md) {
                    padding-right: 0;
                }

                @include media-breakpoint-up(lg) {
                    padding-right: 15px;
                }
            }
        }

        &_dropdown {
            .dropdown-toggle {
                height:          48px;
                display:         flex;
                flex-flow:       row nowrap;
                align-items:     center;
                justify-content: flex-start;
                padding:         $menu-mobile-language-dropdown-item-padding;

                &[aria-expanded="true"] {
                    background-color: rgba($rhenus-blue-dark, 0.9);
                    color:            $white;

                    span {
                        font-weight: 700;
                    }

                    i.material-icons {
                        transform: rotate(180deg);
                    }
                }

                &, &:hover, &:active, &:focus {
                    text-decoration: none;
                    box-shadow:      none;
                }

                &:after {
                    display: none;
                }

                i.material-icons {
                    font-size:   24px;
                    line-height: 24px;
                }
            }

            .dropdown-menu {
                background-color: rgba($rhenus-blue-dark, 0.9);
                padding:          0;
                border:           none;
                box-shadow:       $box-shadow;
                border-radius:    0;
            }

            .dropdown-item {
                color:           $white;
                min-height:      48px;
                display:         flex;
                align-items:     center;
                justify-content: flex-start;
                margin-bottom:   8px;
                padding:         $menu-mobile-language-dropdown-item-padding;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover, &:active, &:focus {
                    color:            $rhenus-blue;
                    background-color: $white;
                }

                &.active {
                    font-weight: 700;
                }
            }
        }
    }

    .rhenus-worldwide {
        margin-left: 5px;

        .country-icon {
            padding-right: 5px;

            svg {
                fill:      $rhenus-blue;
                width:     20px;
                height:    20px;
                transform: translateY(-2px);
            }
        }

        a {

            display:     flex;
            flex-flow:   row nowrap;
            align-items: center;
            height:      100%;
        }
    }
}

.mobile-navi-trigger {
    .container {
        position:      relative;
        padding-right: 60px;

        span {
            font-size: 22px;
            position:  absolute;
            top:       4px;
            right:     40px;
        }
    }
}

.mobile-navi-flyout {
    position:         absolute;
    z-index:          302;
    top:              0;
    left:             -105%;
    overflow-x:       hidden;
    overflow-y:       scroll;
    width:            100vw;
    min-height:       100%;
    padding-bottom:   120px;
    transition:       left 0.4s ease-out, opacity 0.4s ease-out;
    opacity:          0;
    background-color: $white;

    &.active {
        left:        0;
        padding-top: 135px;
        opacity:     1;
    }

    a.mobile-nav-link {
        line-height:    30px;
        display:        block;
        padding-top:    15px;
        padding-bottom: 15px;
        border-bottom:  2px solid $rhenus-blue-light;

        &.active-menu-point {
            background-color: $rhenus-blue-light;
        }
    }

    .close-navigation-page {
        display:       block;
        margin-bottom: 24px;
        color:         $rhenus-blue;

        span {
            font-size:      18px;
            margin-right:   12px;
            vertical-align: -3px;
        }
    }

    .mobile-navigation-submenu-title {
        span {
            font-size:      14px;
            font-weight:    bold;
            display:        block;
            padding-top:    2px;
            padding-bottom: 12px;
            text-transform: uppercase;
            color:          $rhenus-gray-dark;
        }
    }

    .mobile-navigation-page {
        position:         absolute;
        z-index:          $zindex-dropdown;
        top:              0;
        right:            -101%;
        width:            100%;
        padding-top:      116px;
        transition:       right 0.4s ease-out;
        background-color: $white;

        & .mobile-navigation-page {
            bottom: 0;
        }

        &.active {
            right:  0;
            bottom: 0;
        }

        .close-navigation-page {
            cursor: pointer;
            color:  $rhenus-blue;
        }
    }

    .mobile-bottom-navi {
        font-size:  12px;
        padding:    45px 30px 0;
        text-align: center;

        .container {
            padding-left:  0;
            padding-right: 0;
        }

        .meta-navi {
            display:         flex;
            flex-flow:       row wrap;
            gap:             24px;
            justify-content: center;

            a {
                display: inline-block;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

/** Desktop navigation */
.main-header.navigation-desktop {
    position:         fixed;
    z-index:          200;
    top:              0;
    right:            0;
    left:             0;
    height:           120px;
    transition:       height 0.4s ease-out;
    background-color: rgba($rhenus-blue-secondary, 0.8);

    &.without-main-menu {
        height: auto;
    }

    .expand-navigation {
        font-size:   24px;
        position:    absolute;
        top:         1px;
        display:     none;
        margin-left: -24px;
        cursor:      pointer;
        color:       $white;
    }

    .main-header-top {
        font-size:      13px;
        position:       relative;
        padding-top:    32px;
        padding-bottom: 10px;
        transition:     height 0.4s ease-out, padding 0.4s ease-out, border-bottom 0.4s;
        border-bottom:  2px solid rgba(255, 255, 255, 0.5);

        &.without-main-menu {
            min-height:     65px;
            padding-top:    20px;
            padding-bottom: 20px;
            border-bottom:  0;
        }

        .language-switcher,
        .meta-navi,
        .rhenus-worldwide {
            display:    inline-block;
            transition: height 0.4s ease-out, padding 0.4s ease-out;

            a {
                color: $white;
            }

            &:first-child {
                // do not show the left divider, if this element is the first in
                // .main-header-top
                &:before {
                    display: none;
                }

                & > a:first-child {
                    margin-left: 0;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .rhenus-worldwide {
            position:     relative;
            margin-right: 24px;

            a {
                font-size:   14px;
                margin-left: 24px;
                color:       #ffffff;

                .country-icon {
                    margin-right: 6px;
                    transform:    translateY(2px);

                    svg {
                        margin-top: -5px;
                        fill:       $white;
                    }
                }

                i {
                    position:    relative;
                    top:         4px;
                    float:       right;
                    margin-left: 15px;

                    &.up {
                        display: none;
                    }
                }

                &.opened {
                    i {
                        &.up {
                            display: inline-block;
                        }

                        &.down {
                            display: none;
                        }
                    }
                }
            }

            &:before {
                display:          inline-block;
                width:            1px;
                height:           18px;
                content:          "";
                vertical-align:   -4px;
                background-color: $white;
            }
        }

        .meta-navi {
            position: relative;

            &:before {
                display:          inline-block;
                width:            1px;
                height:           18px;
                content:          "";
                vertical-align:   -4px;
                background-color: $white;
            }

            a {
                margin-left: 24px;
            }
        }

        .logo {
            position: absolute;
            top:      16px;
            right:    0;

            img {
                width:  auto;
                height: 31px;
            }
        }
    }

    .main-navigation {
        ul:not(.styled) {
            margin:     0;
            padding:    0;
            list-style: none;

            li {
                position: static;
                display:  inline-block;
                margin:   0;

                &:before {
                    display: none;
                }

                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }

        .level-1-navi-link {
            line-height:     55px;
            position:        relative;
            display:         inline-block;
            height:          55px;
            margin-right:    24px;
            transition:      opacity 0.4s ease-out;
            text-decoration: none;
            text-transform:  uppercase;
            color:           $white;

            &:hover,
            &.active,
            &.active-menu-point {
                text-decoration: none;

                &:before {
                    position:         absolute;
                    top:              -1px;
                    right:            0;
                    left:             0;
                    display:          block;
                    width:            100%;
                    height:           5px;
                    content:          "";
                    background-color: $white;
                }
            }

            i {
                font-size:   20px;
                font-weight: 700;
                position:    relative;
                top:         4px;
            }
        }

        .level-2-navi-overlay {
            position:   fixed;
            top:        120px;
            right:      0;
            left:       0;
            bottom:     15px;
            display:    none;
            transition: top 0.4s ease-out;
            z-index:    20;

            &.active {
                display: flex;
            }

            &:not(.search) .container {
                background:            $white;
                position:              relative;
                overflow-y:            scroll;
                max-width:             1110px;
                background:            $white url("$typo3conf/ext/rscw_provider/Resources/Public/Icons/rhenus-slash-blue-lighter.svg") no-repeat;
                background-attachment: scroll;
                background-size:       500px 270px;
                background-position:   calc(100% - 6px) calc(100% + 10px);
                scrollbar-width:       none;

                &::-webkit-scrollbar {
                    display: none;
                }

                &:before,
                &:after {
                    position:         absolute;
                    top:              20px;
                    bottom:           20px;
                    width:            1px;
                    content:          "";
                    background-color: $rhenus-blue-light;
                }

                &:before {
                    left: 33.7%;
                }

                &:after {
                    right: 33.7%;
                }
            }

            ul {
                li {
                    display: block;
                }
            }

            &.search {
                background: transparent;
                overflow:   visible;
                z-index:    -1;
            }

            .solr-spinner {
                @extend .spinner-border;
                position: fixed;
                z-index:  10000;
                top:      calc(50% - 16px);
                left:     calc(50% - 16px);
                width:    32px;
                height:   32px;
                color:    $white;
                display:  none;
            }
        }

        .navi-flyout-close {
            font-size: 28px;
            position:  absolute;
            z-index:   20;
            top:       15px;
            right:     15px;
            cursor:    pointer;
        }

        .navigation-flyout-headline {
            display:        block;
            margin-top:     7px;
            text-transform: uppercase;
            color:          $rhenus-blue;
        }

        .level-2-navi-link {
            font-weight: bold;
            line-height: 48px;
            display:     block;
            padding:     0 30px;

            &:hover,
            &.active-menu-point {
                background-color: $rhenus-blue-light;
            }
        }

        .level-2-navi-col-1,
        .level-2-navi-col-2 {
            position:    relative;
            float:       left;
            width:       33.3333%;
            padding-top: 60px;
            z-index:     20;
        }

        .level-2-navi-col-1 {
            padding-right: 30px;
            padding-left:  30px;
        }

        .level-3-navi-trigger {
            position: relative;

            span {
                font-size:  26px;
                position:   absolute;
                top:        50%;
                right:      30px;
                margin-top: -12px;
            }
        }

        .level-3-navi-overlay {
            position: absolute;
            top:      60px;
            right:    -100%;
            display:  none;
            width:    100%;

            &.active {
                display: block;
            }
        }

        .level-3-navi-link {
            line-height: 48px;
            display:     block;
            padding:     0 30px;

            &:hover,
            &.active-menu-point {
                background-color: $rhenus-blue-light;
            }
        }

        .overview-link {
            margin-bottom: 50px;
        }
    }

    &.active {
        height: 66px;

        .language-switcher,
        .meta-navi,
        .rhenus-worldwide {
            overflow: hidden;
            height:   0;
            padding:  0;
        }

        .main-header-top {
            height:        0;
            padding:       0;
            border-bottom: 2px solid rgba($white, 0);

            .logo {
                @include media-breakpoint-only(lg) {
                    top: 19px;
                    img {
                        height: 26px;
                    }
                }
            }
        }

        .main-navigation {
            .level-1-navi-link {
                @include media-breakpoint-up(xl) {
                    font-size:    14px;
                    line-height:  64px;
                    margin-right: 20px;
                }
            }

            .level-2-navi-overlay {
                top: 66px;
            }
        }
    }
}

.bilgenentoelung, .rheintank {
    .main-header.navigation-desktop {
        background-color: rgba(110, 110, 110, .7);
    }

    .header-bar-mobile {
        background-color: rgba(110, 110, 110, .7);
    }
}

body .language-switcher {
    position:     relative;
    margin-right: 24px;

    a {
        position:    relative;
        display:     inline-block;
        margin-left: 8px;

        &.active {
            font-weight: bold;
            z-index:     5;

            &:focus {
                text-decoration: none;
            }

            span {
                &:after {
                    @extend .material-icons;

                    font-size: 16px;
                    display:   inline-block;
                    content:   "expand_more";
                    transform: translateY(2px);

                    @include media-breakpoint-down(sm) {
                        content: none;
                    }
                }
            }
        }

        &.mobile {
            margin-left: 14px;

            &:before {
                width:         1px;
                padding-right: 14px;
                content:       "";
                border-left:   1px solid #d2e4f1;
            }
        }
    }

    .language-select {
        position:         absolute;
        z-index:          2;
        top:              -4px;
        left:             0;
        display:          none;
        flex-direction:   column;
        padding:          28px 20px 4px 8px;
        background-color: #004994;

        hr {
            width:         calc(100% + 14px);
            margin-top:    0;
            margin-bottom: 5px;
            border-top:    1px solid #ffffff;
        }

        a {
            width:       calc(100% + 14px);
            margin-left: 0;
        }
    }

    &.opened {
        a {
            span {
                color: #ffffff;
            }

            &.active {
                span {
                    &:after {
                        content: "expand_less";
                    }
                }
            }
        }

        .language-select {
            display: flex;
        }
    }
}

#mobile-search-container {
    display:     none;
    z-index:     999999;
    position:    fixed;
    top:         48px;
    height:      100%;
    width:       100%;
    padding-top: 0;

    &.open {
        display: block;
    }

    .mobile-search-overlay {
        position:   absolute;
        top:        48px;
        left:       0px;
        width:      100%;
        height:     100%;
        background: rgba(0, 0, 0, .7);
    }

    .tx-solr-search-form-mobile {
        .tx-solr-search-form-pi-results {
            .tx-solr-q {
                padding: 0.375rem 0.75rem;
            }

            .tx-solr-autosuggest {
                width:      100% !important;
                max-width:  100% !important;
                margin-top: unset;
            }
        }

    }
}
