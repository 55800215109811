.filter-flyout{
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #fff;
    transition: all 0.5s ease-in-out;

    &>.filter-flyout-inner{
        padding-top: 50px;
        padding-bottom: 50px;
        overflow: auto;
        position: relative;
        width: 100%;
        height: 100%;
    }

    &.open{
        left: 0%;
    }

    .flyout-search-button{
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .filter-pill{
        .remove{
            &,&:hover,&:focus{
                color: $rhenus-gray-dark;
            }
            span{
                font-size: 1.5rem;
                margin-right: 0.5rem;
                top: 5px;
                position: relative;
            }
        }
    }

    .link-chevron {        
        &:before {
            content: "chevron_left";
        }
    }

    .setted-filters{
        display: none;
    }
}

.open-flyout{
    .filter-amount{
        margin-left: 0.5rem;
        font-weight: 100;
    }
    .material-icons{
        position: relative;
        top: 2px;
        margin-right: 0.5rem;
    }
}

.tx-news-year-link.active{
    font-weight: bold;
    text-decoration: underline;
}