/**
    .icon-with-button is used to style a textmedia content-element that consist of ONE image and a one button within
    the bodytext-field.

    The class, .icon-with-button, has to be assigned to the row-field within the textmedias surrounding grid element.
 */

@import "../variables";

.row.icon-with-button {
    .col.image {
        margin-bottom: 40px;
        text-align:    center;
    }

    img {
        max-width: 300px;
        border:    1px solid $rhenus-blue;
    }

    .ce-bodytext {
        p {margin: 0}
    }
}
