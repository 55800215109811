.solr-searchinfo__row-result-found {
    margin-top: 45px;
    
    p {
        font-size:     32px;
        line-height:   36px;
        margin-bottom: 0;
    }
}

.solr-searchinfo__row-no-result-found {
    margin: 0 0 40px 0;
    
    h3 {
        font-size:   32px;
        font-weight: bold;
        line-height: 36px;
        color:       $rhenus-gray-dark;
    }
    
    h4 {
        
        font-size:      24px;
        font-weight:    bold;
        line-height:    32px;
        margin-top:     39px;
        letter-spacing: 0;
        color:          $rhenus-gray-dark;
    }
    
    p {
        font-size:      16px;
        letter-spacing: 0;
        color:          $rhenus-gray-dark;;
    }
}