#picture-credit-popup {
    .white-popup {
        max-height: 90vh;
        overflow: auto;
        padding: 48px;
        @include media-breakpoint-down(md){
            padding: 20px;
        }
        .mfp-close {
            top: 16px;
            color: $rhenus-very-dark-gray;
            @include media-breakpoint-down(md){
                right: 8px;
                top: 12px;
            }
        }
        .popup-heading {
            margin-bottom: 50px;
            @include media-breakpoint-down(md){
                margin-bottom: 24px;
            }
        }
        .picture-credits {
            .credit {
                margin-bottom: 20px;
                .credit-box {
                    padding: 10px;
                    border: 2px solid $rhenus-gray;
                    height: 100%;
                    .image {
                        margin-bottom: 12px;
                        display: block;
                        width: 100%;
                        height: auto;
                        position: relative;
                        overflow: hidden;
                        padding: 56.25% 0 0 0;
                        background-color: #e3e3e3;
                        img {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: auto;
                        }
                    }
                    .filename {
                        font-weight: bold;
                        margin-bottom: 0px;
                        word-break: break-all;
                        font-size: 14px;
                    }
                    .copyright {
                        margin-top: 8px;
                        margin-bottom: 0;
                        word-break: break-all;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}