$mobile-breakpoint:  sm;
$tablet-breakpoint:  lg;
$desktop-breakpoint: xl;

.podcast {
    position:     relative;
    margin-right: -30px;
    margin-left:  -30px;
    padding:      40px 30px 0;
    background:   $rhenus-blue-accordion-header;
}

.podcast__tag {
    line-height:    1;
    position:       absolute;
    top:            -14px;
    right:          30px;
    display:        inline-block;
    padding:        7px 8px 5px;
    text-transform: uppercase;
    color:          $white;
    background:     $rhenus-blue;

    @include media-breakpoint-up($mobile-breakpoint) {
        line-height: unset;
        top:         -16px;
        right:       30px;
        padding:     4px 28px;
    }

    @include media-breakpoint-up($desktop-breakpoint) {
        right: 65px;
    }
}

.podcast__image {
    position:  relative;
    display:   inline-block;
    max-width: 100%;

    @include media-breakpoint-only(xs) {
        @include box-aspect-ratio(1);
    }

    @include media-breakpoint-up($mobile-breakpoint) {
        width:  320px;
        height: 320px;
        margin: 0 30px 35px calc(50% - 160px);
    }

    @include media-breakpoint-up($tablet-breakpoint) {
        margin: 0 30px 140px;
    }

    @include media-breakpoint-up($desktop-breakpoint) {
        margin: 20px 30px 55px;
    }

    img {
        @include fill-parent();
    }
}

.podcast__content {
    position: relative;
    display:  inline-block;
    padding:  160px 0 0;

    @include media-breakpoint-up($mobile-breakpoint) {
        padding: 135px 0 0 0;
    }

    @include media-breakpoint-up($tablet-breakpoint) {
        position:   absolute;
        top:        40px;
        right:      35px;
        left:       415px;
        min-height: 320px;
        padding:    unset;
    }

    @include media-breakpoint-up($desktop-breakpoint) {
        top:   55px;
        right: 65px;
    }

    > span {
        display:       block;
        margin-bottom: 18px;
        color:         $rhenus-blue;
    }

    p {
        margin-bottom:  0;
        padding-bottom: 30px;
        color:          #333333;
    }

    .green-audio-player {
        position: absolute;
        top:      25px;
        right:    0;
        left:     0;

        @include media-breakpoint-up($mobile-breakpoint) {
            top: 0;
        }

        @include media-breakpoint-up($tablet-breakpoint) {
            top:    unset;
            right:  0;
            bottom: -135px;
            left:   -355px;
        }

        @include media-breakpoint-up($desktop-breakpoint) {
            bottom: -25px;
            left:   0;
        }

        svg,
        img {
            display: block;
        }

        .holder {
            position:   relative;
            margin-top: 11px;
            text-align: center;

            @include media-breakpoint-up($tablet-breakpoint) {
                margin-top: 0;
            }

            .replay-forward-btn {
                position:      relative;
                top:           -18px;
                display:       inline-block;
                width:         35px;
                height:        35px;
                padding:       2.5px;
                cursor:        pointer;
                text-align:    center;
                color:         $rhenus-blue;
                border-radius: 50px;

                outline:       none;

                &:focus, &:hover, &:active {
                    color:            $white;
                    background-color: $rhenus-blue;
                }

                &:focus {
                    outline: none;
                }

                span {
                    display: inline-block;
                    width:   30px;
                    height:  30px;

                    i.material-icons {
                        font-size: 30px;
                    }
                }
            }

            .play-pause-btn {
                display:      inline-block;
                width:        65px;
                height:       65px;
                margin-right: 25px;
                margin-left:  25px;
                cursor:       pointer;
                text-align:   center;
                outline:      none;

                @include media-breakpoint-up($desktop-breakpoint) {
                    margin-bottom: 15px;
                }

                &:focus {
                    outline: none;
                }

                span {
                    display: inline-block;
                    width:   100%;
                    height:  100%;

                    i.material-icons {
                        font-size: 65px;
                        color:     $rhenus-blue;
                    }
                }

                &[aria-label="Play"] {
                    .play-pause-btn__play-button_play {display: inline-block;}

                    .play-pause-btn__play-button_hover {display: none;}

                    .play-pause-btn__play-button_pause {display: none;}
                }

                &:hover[aria-label="Play"] {
                    .play-pause-btn__play-button_play {display: none;}

                    .play-pause-btn__play-button_hover {display: inline-block;}

                    .play-pause-btn__play-button_pause {display: none;}
                }

                &[aria-label="Pause"] {
                    .play-pause-btn__play-button_play {display: none;}

                    .play-pause-btn__play-button_hover {display: none;}

                    .play-pause-btn__play-button_pause {display: inline-block;}
                }
            }
        }

        .slider {
            position:  relative;
            flex-grow: 1;
            cursor:    pointer;

            .gap-progress {
                position:         absolute;
                pointer-events:   none;
                border-radius:    inherit;
                background-color: $rhenus-orange;

                .pin {
                    position:         absolute;
                    width:            16px;
                    height:           16px;
                    pointer-events:   all;
                    border-radius:    8px;
                    background-color: $rhenus-orange;

                    &::after {
                        display:       block;
                        width:         200%;
                        height:        200%;
                        margin-top:    -50%;
                        margin-left:   -50%;
                        content:       "";
                        border-radius: 50%;
                    }
                }
            }
        }

        .controls {
            font-family: "Open Sans", sans-serif;
            font-size:   12px;
            font-weight: 600;
            line-height: 17px;
            color:       $rhenus-blue;
            outline:     none;

            .controls__slider {
                height:        6px;
                border-radius: 6px;
                background:    $white;

                .controls__progress {
                    width:  0;
                    height: 100%;

                    .progress__pin {
                        top:   -6px;
                        right: -8px;
                    }
                }
            }

            &__current-time {
                position: relative;
                top:      5px;
            }

            &__total-time {
                float:      right;
                margin-top: 5px;
            }

            span {
                cursor: default;
            }

            &:focus {
                outline: none;
            }
        }

        .volume {
            position: relative;
            top:      27px;
            display:  none;

            @include media-breakpoint-up($tablet-breakpoint) {
                position: absolute;
                top:      15px;
                right:    0;
            }

            @include media-breakpoint-up($desktop-breakpoint) {
                top:     25px;
                display: block;
            }

            &__button {
                display: inline-block;
                cursor:  pointer;
                outline: none;

                &:focus {
                    outline: none;
                }

                path {fill: $rhenus-blue;}
            }

            &__controls {
                font-size:     12px;
                font-weight:   600;
                line-height:   17px;
                display:       inline-block;
                width:         120px;
                margin-bottom: 9px;
                margin-left:   10px;
                color:         $rhenus-blue;
                outline:       none;

                .volume__slider {
                    height:        3px;
                    border-radius: 6px;
                    background:    $white;

                    .volume__progress {
                        width:            0;
                        height:           100%;
                        background-color: $rhenus-blue;

                        .volume__pin {
                            top:              -4.75px;
                            right:            -8px;
                            width:            12px;
                            height:           12px;
                            background-color: $rhenus-blue;
                        }
                    }
                }

                &:focus {
                    outline: none;
                }

                &.hidden {
                    display: none;
                }

                &.top {
                    bottom: 52px;
                    left:   -3px;
                }

                &.middle {
                    bottom: -54px;
                    left:   54px;
                }

                &.bottom {
                    bottom: -164px;
                    left:   -3px;
                }
            }
        }

        .download {
            display:     none;
            margin-left: 16px;
            cursor:      pointer;
            outline:     none;

            &:focus {
                outline: none;
            }
        }

        &:focus {
            outline: none;
        }
    }
}

.podcast__subscribe-bar {
    position:         relative;
    overflow:         hidden;
    height:           80px;
    margin-right:     -30px;
    margin-left:      -30px;
    padding:          16px 0 0;
    background-color: $white;

    @include media-breakpoint-down($tablet-breakpoint) {
        padding-left: 30px;
    }

    &_hidden {
        height: 30px;
    }

    span {
        text-transform: uppercase;
    }

    &::before {
        position:   absolute;
        top:        0;
        left:       0;
        width:      66.5px;
        height:     30px;
        content:    "";
        background: $rhenus-blue-accordion-header;

        @include media-breakpoint-down($tablet-breakpoint) {
            width: 30px;
        }
    }

    &::after {
        position:   absolute;
        top:        -7px;
        left:       27px;
        width:      25px;
        height:     100px;
        content:    "";
        transform:  rotateZ(45deg);
        background: $white;

        @include media-breakpoint-down($tablet-breakpoint) {
            left: -7px;
        }
    }

    div {
        font-weight:  700;
        line-height:  32px;
        position:     relative;
        z-index:      10;
        top:          -8px;
        display:      inline-block;
        width:        100%;
        height:       32px;
        margin-right: 32px;
        padding-left: 35px;
        color:        #333333;

        @include media-breakpoint-down($tablet-breakpoint) {
            padding-left: 0;
        }

        @include media-breakpoint-up($tablet-breakpoint) {
            width:        unset;
            padding-left: 30px;
        }

        @include media-breakpoint-up($desktop-breakpoint) {
            padding-left: 60px;
        }
    }

    a {
        position:     relative;
        z-index:      10;
        display:      inline-block;
        width:        32px;
        height:       32px;
        margin-right: 16px;

        svg {
            @include fill-parent();

            &:first-child {
                opacity: 1;
            }

            &:last-child {
                opacity: 0;
            }
        }

        &:hover {
            svg:first-child {
                opacity: 0;
            }

            svg:last-child {
                opacity: 1;
            }
        }
    }
}

.newsroom-detail {
    .podcast__image {
        @include media-breakpoint-up($tablet-breakpoint) {
            height: 330px;
        }

        @include media-breakpoint-up($desktop-breakpoint) {
            height: 320px;
        }
    }

    .podcast__content {
        p {
            @include media-breakpoint-up($desktop-breakpoint) {
                padding-bottom: 18px;
            }
        }

        .green-audio-player {
            @include media-breakpoint-up($tablet-breakpoint) {
                top: unset;
            }

            .holder {
                @include media-breakpoint-up($desktop-breakpoint) {
                    margin-top: 4px;
                    text-align: unset;
                }
            }

            .volume {
                &__controls {
                    @include media-breakpoint-up($desktop-breakpoint) {
                        width: 80px;
                    }
                }
            }
        }
    }
}

.tabular-content{
    .podcast{
        background: #fff;
        .podcast__content{
            .podcast__player{
                .controls{
                    .controls__slider{
                        background: #e1e9f3;
                    }
                }
                .volume__controls{
                    .volume__slider{
                        background: #e1e9f3;
                    }
                }
            }
        }
        .podcast__subscribe-bar{
            background: #e1e9f3;
            &:before{
                background: #fff;
            }
            &:after{
                background: #e1e9f3;
            }
        }
    }
}