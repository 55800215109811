@import "news-item-standard-teaser";
@import "news-item-fokus-teaser";
@import "contact-teaser";
@import "news-slider";

.more-news-trigger-container {
    max-height: 200px;
    text-align: center;

    &.clicked {
        overflow:   hidden;
        max-height: 0;
        transition: max-height 0.4s ease-out;
    }

    // .more-news-trigger {
    // }
}

.keyvisual {
    img {
        max-width: 100%;
        height:    auto;
    }
}

.news-category-filter {
    &.unstyled-list {
        li {
            position: relative;
            margin:   8px 0;
            color:    $rhenus-gray-dark;

            > a {
                display: inline-block;
                color:   $rhenus-gray-dark;

                &.active {
                    color: $rhenus-blue;
                }

                span.checkmark {
                    border: 2px solid $rhenus-gray-dark;
                }

                label {
                    margin-bottom: 1rem;
                }

                &:hover, &:focus, &:active {
                    color: $rhenus-blue;

                    label {
                        color: $rhenus-blue;

                        span.checkmark {
                            color:            $white;
                            border:           2px solid $rhenus-blue;
                            background-color: #e6f1f8;
                        }
                    }
                }
            }
        }
    }
}

.news-teaser-container {
    .news-teaser {
        &:not(:nth-child(2)):before {
            display:          block;
            width:            100%;
            height:           1px;
            margin:           2rem 1rem;
            content:          "";
            background-color: $rhenus-gray;
        }

        .news-teaser-item {
            margin-bottom: 1rem;
        }
    }

    .news-teaser-headline {
        a {
            &, &:hover, &:active, &:focus {
                color: $rhenus-gray-dark;
            }
        }
    }

    .news-teaser-image {
        img {
            width: 100%;
        }
    }

    .news-teaser-text {
        span.link-chevron {
            color: $rhenus-blue;
        }
    }
}

.cut-off-corner {
    background: $rhenus-blue-dark linear-gradient(315deg, $white 18px, $rhenus-blue-light 18px) no-repeat;
}

.news-img-wrapper {
    img {
        width:  100%;
        height: auto;
    }

    a {
        display: block;
    }
}

.frame-space-before-large-md {
    @include media-breakpoint-down(md) {
        margin-top: 4em;
    }
}

.news-media-type-flag {
    position:   relative;
    top:        -10px;
    display:    inline;
    padding:    5px 15px;
    color:      $white;
    background: $rhenus-blue;

    @include media-breakpoint-up(lg) {
        top: 28px;
    }
}

.newsroom-detail-new {
    .breadcrumb-container, .breadcrumb-container-mobile {
        background-color: $rhenus-gray-teaser-back;
    }
}

.newsroom-detail {
    @include media-breakpoint-down(md) {
        margin-top: 48px !important;
    }

    .breadcrumb-container {
        margin:  0;
        padding: 45px 0;
    }

    .newsroom {
        background-color: $rhenus-gray-teaser-back;

        @include media-breakpoint-down(lg) {
            padding-top: 40px;
        }

        .newsroom-detail-mainbox {
            position:         relative;
            padding-bottom:   40px;
            background-color: #fff;

            @include media-breakpoint-down(md) {
                margin-right:  -30px;
                margin-left:   -30px;
                padding-right: 30px;
                padding-left:  30px;
            }

            @include media-breakpoint-down(xs) {
                padding-right: 15px;
                padding-left:  15px;
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: 80px;
            }

            .newsroom-detail-flag {
                position:         absolute;
                top:              -12px;
                right:            188px;
                width:            max-content;
                padding:          2px 8px;
                color:            #fff;
                background-color: $rhenus-blue;

                @include media-breakpoint-down(md) {
                    right: unset;
                    left:  30px;
                }

                @include media-breakpoint-down(xs) {
                    left: 15px;
                }
            }

            .triangle {
                position:     absolute;
                top:          0;
                left:         0;
                width:        0;
                height:       0;
                border-top:   80px solid $rhenus-gray-teaser-back;
                border-right: 80px solid transparent;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .newsroom-detail-date {
                * {
                    color: $rhenus-blue;
                }

                .additional-information {
                    margin: 84px 0 32px 0;
                    @include media-breakpoint-down(md) {
                        margin-top: 44px;
                    }
                }
            }

            h1 {
                @include media-breakpoint-down(md) {
                    font-size:   24px;
                    line-height: 28px;
                }

                @include media-breakpoint-down(sm) {
                    font-size:   20px;
                    line-height: 28px;
                }

                margin-bottom: 16px;
            }
        }

        .newsroom-detail-keywords {
            @extend .space-after-small;

            text-align: center;

            .category-button-list {
                margin: 0;
            }
        }

        .company-information-stripe {
            padding: 40px 15px;

            @include media-breakpoint-down(md) {
                &__header .h1 {
                    font-size:   24px;
                    line-height: 28px;
                }
            }

            @include media-breakpoint-down(sm) {
                &__header .h1 {
                    font-size:   20px;
                    line-height: 28px;
                }
            }

            @include media-breakpoint-up(sm) {
                padding: 40px 30px;
            }

            @include media-breakpoint-up(lg) {
                padding: 80px 0;
            }
        }
    }

    .contact-teaser {
        &__headline_company {
            @include media-breakpoint-down(md) {
                font-size:   24px;
                line-height: 28px;
            }

            @include media-breakpoint-down(sm) {
                font-size:   20px;
                line-height: 28px;
            }
        }
    }

    .related-news-container {
        margin-top:    112px;
        margin-bottom: 112px;

        @include media-breakpoint-down(md) {
            margin-top:    40px;
            margin-bottom: 40px;
        }

        .heading {
            margin-bottom: 44px;
            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
            }
        }
    }
}

.news-link-direct-product {
    @extend .h4;
    padding-bottom: 30px;
    text-align:     left;
    color:          $rhenus-blue;

    @include media-breakpoint-up(lg) {
        text-align: right;
    }

    & a:hover, & a:focus, & a:active {
        text-decoration: none !important;
    }

    i {
        font-size: 24px;
        position:  relative;
        top:       6px;
    }

    .news-teaser-container .news-teaser-container & {
        display: none !important;
    }
}
