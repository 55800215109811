$mobile-breakpoint:               lg;

$company-information-stripe-icon: (
                                      "size": 40px,
                                      "background-size": 70px,
                                      "desktop": (
                                          "size": 60px,
                                          "background-size": 120px
                                      )
                                  );

.company-information-stripe {
    position:         relative;
    margin-right:     -30px;
    margin-left:      -30px;
    padding:          40px 30px;
    background-color: $rhenus-blue-light;

    @include media-breakpoint-up($mobile-breakpoint) {
        margin-right: unset;
        margin-left:  unset;
        padding:      80px 30px;
    }
}

.company-information-stripe__header {
    .h1 {
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
            font-size:   28px;
            line-height: 36px;
        }
    }
}

.company-information-stripe__text {
    h3, p {
        margin-bottom: 24px;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.company-information-stripe__icon {
    position:         absolute;
    top:              - map-get($company-information-stripe-icon, "background-size") / 2;
    right:            30px;

    width:            map-get($company-information-stripe-icon, "background-size");
    height:           map-get($company-information-stripe-icon, "background-size");
    border-radius:    map-get($company-information-stripe-icon, "background-size");
    background-color: $rhenus-blue;

    > img {
        width:  map-get($company-information-stripe-icon, "size");
        height: map-get($company-information-stripe-icon, "size");
        margin: (map-get($company-information-stripe-icon, "background-size") - map-get($company-information-stripe-icon, "size"))/2;
    }

    @include media-breakpoint-up($mobile-breakpoint) {
        top:           - map-get(map-get($company-information-stripe-icon, "desktop"), "background-size") / 2;
        width:         map-get(map-get($company-information-stripe-icon, "desktop"), "background-size");
        height:        map-get(map-get($company-information-stripe-icon, "desktop"), "background-size");
        border-radius: map-get(map-get($company-information-stripe-icon, "desktop"), "background-size");

        > img {
            width:  map-get(map-get($company-information-stripe-icon, "desktop"), "size");
            height: map-get(map-get($company-information-stripe-icon, "desktop"), "size");
            margin: (map-get(map-get($company-information-stripe-icon, "desktop"), "background-size") - map-get(map-get($company-information-stripe-icon, "desktop"), "size"))/2;
        }
    }
}

