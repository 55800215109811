// Font
body {
    font-size: $font-size-base;
    color:     $rhenus-gray-dark;
}

.color-light {
    color: $rhenus-gray;
}

// Headlines
h1,
.h1 {
    @include h1-font-attributes;
}

h2,
.h2 {
    @include h2-font-attributes;
}

h3,
.h3 {
    @include h3-font-attributes;
}

h4,
.h4 {
    @include h4-font-attributes;
}

h5,
.h5 {
    @include h5-font-attributes;
}

h6,
.h6 {
    @include h6-font-attributes;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight:    bold;
    text-transform: uppercase;
    hyphens:        auto;
}

// Text

p {
    font-size:   16px;
    line-height: 24px;
    hyphens:     auto;
}

.introtext {
    font-size:   1.5rem;
    line-height: 2.4rem;
}

header {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin-bottom: 24px;
    }
}

/** Mobile Improvements */
@include media-breakpoint-down(sm) {
    h1, .h1 {
        font-size:     28px;
        line-height:   36px;
        margin-bottom: 24px;
    }

    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        font-size:     24px;
        line-height:   32px;
        margin-bottom: 16px;
    }

    p {
        font-size:   16px;
        line-height: 24px;
    }

    .introtext {
        font-size:   16px;
        line-height: 24px;
    }
}

/**
    .no-hyphens class - removes hyphens from all headlines and paragraphs

    This class can be added by setting `noHyphens: 1` in the config.yaml of the sites-config for a specific language.
 */
.no-hyphens {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    p,
    .introtext {
        hyphens: none !important;
    }
}
