.lead-cta-container {
    position:    fixed;
    top:         135px;
    @include media-breakpoint-down(sm) {
        top: 125px;
    }
    right:       0;
    left:        0;
    height:      60px;
    z-index:     300;
    transition:  transform 0.4s ease-out, opacity 0.2s ease-out;
    will-change: top;
    opacity:     1;

    &.inactive {
        opacity: 0;
    }

    &.stage-margin {
        position: absolute;
    }

    &.active {
        transform: translate(0, -70px);

        .lead-cta {
            a {
                height:      40px;
                line-height: 36px;
                min-height:  40px;
            }

            .phone-call {
                width:   40px;
                padding: 0;

                &.phone-call-large {
                    i {
                        vertical-align: -1px;
                    }
                }

                i {
                    font-size: 20px;
                }
            }

            .lead-cta-text {
                line-height: 32px;

                @include media-breakpoint-down(md) {
                    width: 40px;
                }

                svg {
                    width:  20px;
                    height: 20px;
                    top:    6px;
                }

                i {
                    font-size:      20px;
                    vertical-align: -5px;
                }

                span {
                    font-size: 16px;
                }
            }
        }
    }

    &.active-2 {
        transform: translate(0, -90px);

        @include media-breakpoint-down(md) {
            transform: translate(0, -70px);
        }
    }

    .lead-cta {
        a {
            height:         60px;
            line-height:    55px;
            vertical-align: top;
            font-size:      22px;
            padding-top:    0;
            padding-bottom: 0;
            box-shadow:     0 2px 20px 0 rgba(0, 0, 0, 0.2);
            transition:     box-shadow 0.2s ease-out, width 0.2s ease-out, height 0.2s ease-out;

            &:hover {
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
            }
        }

        .phone-call {
            width:        60px;
            padding:      0;
            text-align:   center;
            font-weight:  normal;
            margin-right: 6px;
            padding-top:  6px;

            &.phone-call-large {
                @include media-breakpoint-up(lg) {
                    width:   auto;
                    padding: 0 32px;
                }

                i {
                    margin-right:   8px;
                    vertical-align: -5px;
                    @include media-breakpoint-down(md) {
                        margin-left:    8px;
                        vertical-align: 0;
                    }
                }

                span {
                    font-size:      18px;
                    vertical-align: 2px;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                width:       48px;
                height:      48px;
                line-height: 48px;
                padding-top: 2px;
            }

            i {
                font-size:      30px;
                vertical-align: -1px;
                margin-left:    1px;

                @include media-breakpoint-down(md) {
                    font-size:      24px;
                    vertical-align: 1px;
                }
            }
        }

        .lead-cta-text {
            font-size:     16px;
            font-weight:   normal;
            padding-left:  16px;
            padding-right: 16px;
            overflow:      hidden;

            @include media-breakpoint-down(md) {
                height:      40px;
                width:       48px;
                line-height: 45px;
                padding:     0 0 0;
                text-align:  center;
            }

            i {
                font-size:      32px;
                margin-right:   8px;
                vertical-align: -9px;

                @include media-breakpoint-down(md) {
                    font-size:      24px;
                    margin-right:   0;
                    vertical-align: -6px;
                    display:        inline-block;
                }
            }

            svg {
                width:    32px;
                height:   32px;
                fill:     #fff;
                display:  block;
                float:    left;
                top:      12px;
                position: relative;

                .st0 {
                    fill: #fff;
                }
            }

            span {
                font-size:      18px;
                vertical-align: -1px;
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .hover-icon {
                margin-top:   10px;
                float:        left;
                margin-right: 0px;
            }

            .hover-text {
                transition:   all .3s ease-in-out;
                display:      block;
                float:        left;
                padding-left: 0.5rem;
                opacity:      1;

                &.length-processed {
                    span {
                        display: block;
                    }
                }
            }

            &:not(:hover) {
                .hover-text.length-processed {
                    width:        0px !important;
                    opacity:      0 !important;
                    padding-left: 0 !important;
                    // padding-left: 16px !important;
                    .span {
                        display: block;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .page-type-simple {
        .lead-cta-container {
            top: 60px;

            &.active, &.active-2 {
                transform: translate(0, -4px);
            }
        }
    }
}

.active-fixed {
    position: fixed;
}
