.getaquote-container {
    overflow: hidden;
    padding-bottom: 100px;

    .getaquote {
        background: $rhenus-orange;
        background: linear-gradient(135deg, transparent 30px, $rhenus-orange 30px);
        position: relative;
        padding: 50px 0 40px 95px;
        min-height: 260px;

        @include media-breakpoint-down(md) {
            padding: 40px 32px 32px;
        }

        &:after {
            content: "";
            position: absolute;
            display: block;
            background-color: $rhenus-orange;
            top: 0;
            bottom: 0;
            width: 300%;
            right: -300%;
        }
    }

    .getaquote-image {
        text-align: right;
        display: flex;
        align-items: flex-end;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .headline {
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    p {
        margin-bottom: 16px;
    }

    .button-ghost {

    }

    img {
        max-height: 230px;
        max-width: 100%;
        height: auto;
        width: auto;
        margin-bottom: -100px;
        position: relative;
        z-index: 1;

        @include media-breakpoint-down(md) {
            max-height: 100px;
            margin-top: 40px;
        }
    }
}
