#road-freight-tool{
    position: fixed;
    top: auto !important;
    right: 0;
    bottom: 50%;
    left: auto;
    a{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        border: 1px solid $rhenus-blue-secondary;
        color: $rhenus-blue-secondary;
        margin-bottom: 0.5rem;
        border-right: 0px;

        &:hover{
            background-color: $rhenus-blue-secondary;
            border: 1px solid $rhenus-blue-secondary;
            border-right: 0px;
            color: #fff;
        }

        i,span{
            vertical-align: 0px;
        }

        @include media-breakpoint-down(md) {
            i{
                margin-right: 0px;
            }
        }
    }
}
