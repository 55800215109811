.text-image-image-fullwidth__fullwidth-image {
    display: none;
    height:  300px;

    @include media-breakpoint-up(md) {
        display: block;
    }
}

.text-image-image-fullwidth__image-with-content {
    padding: 32px 0;

    @include media-breakpoint-up(md) {
        padding: 64px 0;
    }

    p {
        font-size:   18px;
        font-family: "Open Sans", sans-serif;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.text-image-image-fullwidth__image-with-content-divider {
    display:          none;
    height:           100%;
    width:            1px;
    margin-left:      calc(50% - 1px);
    background-color: $rhenus-gray-dark;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.text-image-image-fullwidth__content {
    padding: 8px 0;
}
