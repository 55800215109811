@import "../variables";

.frame-type-rscwcontentelements_iq4estage {
    margin-bottom: 0;

    header {
        display: none;
    }
}

.iq4e-stage_in-header {
    margin-top: -120px;
    position:   relative;

    .iq4e-stage__inner-wrapper {
        @media (orientation: portrait) {
            @include box-aspect-ratio(1);
        }

        @media (orientation: landscape) {
            @include box-aspect-ratio(21/9);
        }
    }

    .iq4e-stage__background {
        @include fill-parent;

        overflow: hidden;
        margin:   0;

        > div {
            @include fill-parent;
        }

        .background-image {
            background-repeat: no-repeat;
            background-size:   cover;
        }
    }

    .iq4e-stage__header {
        @include fill-parent;

        display:     flex;
        align-items: center;
        text-align:  center;
        text-shadow: 0 2px 24px rgba(0, 0, 0, .5);

        h1, h2 {
            font-size:      28px;
            line-height:    36px;
            text-transform: uppercase;
            color:          #fff;

            @include media-breakpoint-up(md) {
                font-size:   3rem;
                line-height: 3.5rem;
            }

            @include media-breakpoint-up(lg) {
                font-size:   4rem;
                line-height: 4.5rem;
            }
        }

        p {
            font-size:   1.5rem;
            line-height: 2.5rem;
            color:       white;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .iq4e-stage__form-box {
        .iq4e-stage__form {
            box-shadow: 0 1px 2px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
            transform:  translateY(-40px);
            background: $white;
            padding:    20px;

            @include media-breakpoint-up(lg) {
                padding:   20px 60px;
                transform: translateY(-50%);
            }

            .col-form-label {
                font-weight: 700;
            }

            .form-control {
                margin-bottom: 16px;
                border-radius: 0;
                border:        1px solid $rhenus-content-alert-background-alt;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                    height:        46px;
                }

                &::placeholder {
                    color:   $rhenus-gray;
                    opacity: 1;
                }

                &:hover,
                &:focus,
                &:active {
                    border-color: $rhenus-blue-secondary;
                }
            }

            .iq4e-stage__form-submit-button-box {
                display:     flex;
                flex-flow:   row nowrap;
                align-items: flex-end;
                height:      100%;

                button {
                    margin:         16px 0 0;
                    width:          100%;
                    text-transform: unset;

                    @include media-breakpoint-up(lg) {
                        margin: 0;
                    }
                }
            }
        }
    }

    .iq4e-stage__form-link-box {
        a {
            display:     inline-flex;
            flex-flow:   row nowrap;
            color:       $rhenus-orange-primary;
            font-size:   16px;
            line-height: 25px;
            transform:   translateX(-7px);

            &:hover,
            &:focus,
            &:active {
                color:           $rhenus-blue;
                text-decoration: none;
            }

            .material-icons {
                font-size: 25px;
            }
        }
    }
}

.iq4e-stage_in-content {
    margin-bottom: 60px;
    position:      relative;
    background:    $rhenus-blue;

    @include media-breakpoint-up(lg) {
        margin-bottom: 160px;
    }

    @include media-breakpoint-up(lg) {
        background: unset;
    }

    .iq4e-stage__inner-wrapper {
        background: $rhenus-blue;
        position:   relative;

        &:after {
            position:   absolute;
            top:        0;
            bottom:     0;
            left:       -300%;
            display:    block;
            width:      300%;
            content:    "";
            background: $rhenus-blue;
        }
    }

    .iq4e-stage__header {
        background: $rhenus-blue;
        color:      $white;
        padding:    40px 0 0 0;

        h1, h2 {
            font-size:      28px;
            line-height:    32px;
            text-transform: uppercase;
            margin-bottom:  24px;

            @include media-breakpoint-up(md) {
                font-size:   32px;
                line-height: 40px;
            }

            @include media-breakpoint-up(lg) {
                font-size:   3rem;
                line-height: 3.5rem;
            }
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .iq4e-stage__form-box {
        .iq4e-stage__form {
            box-shadow: 0 1px 2px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
            transform:  translateY(40px);
            background: $white;
            padding:    20px;

            @include media-breakpoint-up(lg) {
                padding: 20px 60px;
                width:   calc(100% - 30px);
            }

            @media (min-width: 1375px) {
                width: calc(100% + 120px);
            }

            .col-form-label {
                font-weight: 700;
            }

            .form-control {
                margin-bottom: 16px;
                border-radius: 0;
                border:        1px solid $rhenus-content-alert-background-alt;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                    height:        46px;
                }

                &::placeholder {
                    color:   $rhenus-gray;
                    opacity: 1;
                }

                &:hover,
                &:focus,
                &:active {
                    border-color: $rhenus-blue-secondary;
                }
            }

            .iq4e-stage__form-submit-button-box {
                display:     flex;
                flex-flow:   row nowrap;
                align-items: flex-end;
                height:      100%;

                button {
                    margin:         16px 0 0;
                    width:          100%;
                    text-transform: unset;

                    @include media-breakpoint-up(lg) {
                        margin: 0;
                    }
                }
            }
        }
    }

    .iq4e-stage__form-link-box {
        a {
            display:     inline-flex;
            flex-flow:   row nowrap;
            color:       $rhenus-orange-primary;
            font-size:   16px;
            line-height: 25px;
            transform:   translateX(-7px);

            &:hover,
            &:focus,
            &:active {
                color:           $rhenus-blue;
                text-decoration: none;
            }

            .material-icons {
                font-size: 25px;
            }
        }
    }
}

.search-input-group {
    position: relative;
    z-index:  20;

    &_2 {
        z-index: 10;
    }
}

.iq4e-stage {
    .iq4e-stage__geolaction {
        position:        absolute;
        right:           0;
        bottom:          0;
        top:             0;
        padding:         0 16px;
        display:         flex;
        align-items:     center;
        justify-content: center;
        cursor:          pointer;

        .material-icons {
            color: $rhenus-blue;
        }
    }

    .mdc-text-field__input {
        padding-right: 38px;
    }

    .mdc-text-field {
        height:    48px;
        max-width: unset;
    }

    .powermail_fieldwrap {
        margin-bottom: 0;
    }

    .form-group {
        gap: 16px;

        @include media-breakpoint-up(lg) {
            gap: unset;
        }
    }
}

#iq4e-stage-form-from,
#iq4e-stage-form-to {
    & + .autocomplete-container {
        max-height:       500px;
        background-color: #fff;
        position:         absolute;
        top:              50px;
        left:             0;
        width:            100%;
        z-index:          999;
        text-align:       left;
    }

    & + .autocomplete-container ul {
        padding: 0px;
        margin:  0px;
        border:  1px solid #cce0ee;
    }

    & + .autocomplete-container li {
        padding:       0;
        margin:        0;
        border-bottom: 1px solid #cce0ee;
    }

    & + .autocomplete-container li:last-child {
        border-bottom: none;
    }

    & + .autocomplete-container li a {
        display:       block;
        padding:       10px 15px;
        margin:        0;
        font-size:     14px;
        color:         #4d4d4d;
        overflow:      hidden;
        white-space:   nowrap;
        text-overflow: ellipsis;
    }

    & + .autocomplete-container li a i {
        color:        #cfcfd1;
        margin-right: 5px;
        position:     relative;
        top:          2px;
        font-size:    1rem;
    }

    & + .autocomplete-container li a span {
        margin-left: 5px;
    }

    & + .autocomplete-container li:hover a,
    & + .autocomplete-container li.highlight a {
        background-color: #00469B;
        color:            #fff;
        text-decoration:  none;
    }

    & + .autocomplete-container li:hover a i,
    & + .autocomplete-container li.highlight a i {
        color: #fff;
    }
}
