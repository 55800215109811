.news-slider-container {
    position: relative;

    &__show-all-recent-news {
        margin-top: 40px;
        width:      100%;

        @include media-breakpoint-up(lg) {
            width: unset;
        }
    }

    &__news-slider-progress-mobile {
        margin: 40px 0;
    }

    &__headline {
        font-size:     20px;
        line-height:   28px;
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            font-size:   32px;
            line-height: 40px;
        }
    }

    .slick-slider {
        margin-bottom: 0;
    }

    img {
        width:     100%;
        max-width: 100%;
        height:    auto;
    }

    .media-type-flag {
        width:            max-content;
        padding:          2px 8px;
        color:            #fff;
        background-color: $rhenus-blue;
        margin-bottom:    24px;
    }

    .headline {
        text-transform: uppercase;
        font-size:      20px;
        line-height:    28px;
        margin-bottom:  24px;

        @include media-breakpoint-up(md) {
            font-size:   32px;
            line-height: 40px;
        }
    }

    .sub-headline {
        font-size:      $font-size-base;
        font-weight:    normal;
        display:        block;
        margin-bottom:  24px;
        text-transform: uppercase;
        color:          $rhenus-blue;
    }

    .slider-text {
        margin-bottom: 32px;
    }

    .row > div,
    .news-slider-left {
        position: static;
    }

    .news-slider-right {
        @include media-breakpoint-down(md) {
            margin-top: 24px;
            min-height: 232px;
        }
    }

    .headline-container-mobile {
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .headline-container-desktop {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.single-slider {
    .news-slider-right {
        .tns-item {
            min-height: max-content;
            @include media-breakpoint-only(xl) {
                height: 309px;
            }
            @include media-breakpoint-only(lg) {
                height: 259px;
            }
        }

        .media-type-flag {
            display:       block;
            margin-bottom: 24px;
        }

        .headline {
            @include media-breakpoint-only(lg) {
                font-size:   24px;
                line-height: 36px;
            }
        }

        .link-chevron {
            position: absolute;
            bottom:   0;
            @include media-breakpoint-down(md) {
                position: relative;
                bottom:   unset;
            }
        }
    }
}

.news-slider-navigation {
    position:   relative;
    margin-top: -28px;

    .news-slider-button {
        width:      49px;
        height:     49px;
        padding:    0;
        text-align: center;

        i {
            font-size:   2rem;
            line-height: 2.8rem;
        }

        &.slider-prev {
            margin-right: 1px;

        }
    }
}

.news-slider-current-slide {
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
        margin-top: 16px;
    }

    span {
        display: none;
    }

    span:nth-of-type(1) {
        display: inline-block;
    }

    .news-slider-current {
        position:       relative;
        display:        inline-block;
        margin-right:   6px;
        padding-right:  6px;
        vertical-align: bottom;

        &:after {
            position:         absolute;
            top:              7px;
            right:            0;
            display:          inline-block;
            width:            1px;
            height:           50%;
            content:          "";
            background-color: $rhenus-gray-dark;
        }
    }
}

.news-slider-progress {
    position:         absolute;
    right:            50%;
    left:             0;
    height:           2px;
    background-color: $rhenus-gray;

    @include media-breakpoint-only(sm) {
        margin-right: 156px;
    }

    @include media-breakpoint-only(md) {
        margin-right: 246px;
    }

    @include media-breakpoint-only(lg) {
        margin-right: 366px;
    }

    @include media-breakpoint-only(xl) {
        margin-right: 456px;
    }

    .slider-progress-bar {
        width:            0;
        height:           100%;
        background-color: $rhenus-blue;
    }
}

.stripe {
    .news-slider-container {
        padding-bottom: 0;

        .headline {
            margin-bottom: 20px;
        }
    }
}

.news-slider-neu {
    &__plugin-headline {
        font-size:     32px;
        line-height:   36px;
        margin-bottom: 45px;
    }
}
