/**
    Note:   The landbridge map-element and especially its containing svg-element
            has been moved to this partial from '_landbridge.scss'. This was
            done in order to keep '_landbridge.scss' clean as the rules for
            inner-elements of the svg got really big and really really repetitive.
*/

$landbridge-svg-width:  1725.5px;
$landbridge-svg-height: 970.5px;

.landbridge-map {
    display:          none;
    background-color: #cce0ef;

    @include media-breakpoint-up(xl) {
        position: absolute;
        top:      0;
        bottom:   0;
        left:     30%;
        display:  block;
        overflow: hidden;
        width:    70%;

        #svg-landbridge-routes {
            position: relative;

            svg {
                position: absolute;
                width:    $landbridge-svg-width;
                height:   $landbridge-svg-height;
            }
        }
    }
}

.landbridge-map svg {
    // train that will be animated following the route
    .landbridge-map-vehicle-icon {
        position: relative;
        z-index:  9999999999;
    }

    // visibility of pins
    #Städte_Europa, #Städte_Asien {
        path {
            display: none;
        }

        path.show {
            display: block !important;
        }
    }

    #Rail-Connections {
        g#Routen-Rail_Shenyang,
        g#Routen-Rail_Changchun,
        g#Routen-Rail_Suzhou,
        g#Routen-Rail_Changsha,
        g#Routen-Rail_Xiamen,
        g#Routen-Rail_Wuhan,
        g#Routen-Rail_Hefi,
        g#Routen-Rail_Yiwu,
        g#Routen-Rail_Chengdu,
        g#Routen-Rail_Chongqing,
        g#Routen-Rail_Xi_an,
        g#Routen-Rail_Zhengzhou {
            path {
                display: none;
            }
        }
    }

    #Sea-Rail-Connections {
        g#Routen-Sea-Rail_Korea_Inchon,
        g#Routen-Sea-Rail_China_Shenzhen,
        g#Routen-Sea-Rail_China_Hongkong,
        g#Routen-Sea-Rail_Vietnam_Haiphong,
        g#Routen-Sea-Rail_Vietnam_HoChiMiinh,
        g#Routen-Sea-Rail_Thailand_Laem_Chabang {
            path {
                display: none;
            }
        }
    }
}
