#career-floating-action{
    position: fixed;
    top: auto !important;
    right: 0;
    bottom: 10%;
    left: auto;
    .career-floating-action-drop-shadow{

        &:hover{
            filter: drop-shadow(0px 2px 20px rgba(0,0,0,.2));
        }

        a{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            background-color: $rhenus-orange;
            border: 2px solid $rhenus-orange;
            color: #fff;
            margin-bottom: 0.5rem;
            box-shadow: none;
            max-width: 230px;
            width: 230px;
            -webkit-clip-path: polygon(0% 24px, 24px 0%, 0% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 24px, 24px 0%, 100% 0%, 100% 100%, 0% 100%);
            transition: all 0.3s ease-in-out;

            &:hover{
                text-decoration: none;
            }

            &:focus{
                border: 2px solid rgba(1,70,152,0.50);
                border-right: 0px;
            }

            &.link{
                .right{
                    max-width: 145px;
                    @include media-breakpoint-down(md) {
                        max-width: 85px;
                    }
                    .floating-action-text{
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                }
            }

            .left,.right{
                padding: 0px;
                font-weight: bold;
                position: relative;
                float: left;
            }
            .left{
                font-size: 40px;
                line-height: 56px;
                top: -3px;
            }
            .right{
                padding-left: 8px;
                font-size: 16px;
                line-height: 1.125;
                text-transform: uppercase;
                top: -1px;
                max-width: 85px;
            }

            &.active-2{
                height: 60px;
                max-width: 90px;
                width: 90px;
                justify-content: center;
                .left{
                    display: none;
                }
                .right{
                    padding-left: 20px;
                    font-size: 14px;
                    text-align: left;
                }
            }
            @include media-breakpoint-down(md) {
                height: 60px;
                max-width: 90px;
                width: 90px;
                justify-content: center;
                .left{
                    display: none;
                }
                .right{
                    padding-left: 20px;
                    font-size: 14px;
                    text-align: left;
                }

            }
            &.scroll-link {
                cursor: pointer;
            }
        }
    }
    &.active{
        transform: none;

        .lead-cta {
            a {
                height: 60px;
                line-height: 55px;
            }

            .lead-cta-text {
                line-height: 55px;

                @include media-breakpoint-down(md) {
                    width: auto;
                }
            }
        }
    }
    &.active-2{
        .career-floating-action-drop-shadow{
            a{
                height: 60px;
                max-width: 90px;
                width: 90px;
                justify-content: center;
                .left{
                    display: none;
                }
                .right{
                    padding-left: 20px;
                    font-size: 14px;
                    text-align: left;
                }
                &.link{
                    padding-right: 4px;
                    padding-left: 22px;
                    .right{
                        word-break: break-word;
                        padding-left: 0;
                        .floating-action-text{
                            line-height: 1.2;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
