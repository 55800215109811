.frame-video figure.video {
    width: 100%;

    iframe {
        border: none !important;
    }
}

.video-embed {
    padding-top: 0;
}

.video-embed-precheck {
    position:       relative;
    width:          100%;
    height:         auto;
    padding-bottom: 56.25%;

    .video-embed-overlay {
        position:        absolute;
        top:             0;
        left:            0;
        right:           0;
        bottom:          0;
        background-size: cover;
        align-items:     center;
        height:          100%;
        cursor:          pointer;
        color:           $white;
        z-index:         1;

        &:before {
            content:          "";
            position:         absolute;
            top:              0;
            left:             0;
            right:            0;
            bottom:           0;
            background-color: $rhenus-blue-dark;
            opacity:          0;
            transition:       opacity 0.2s ease-out;
            @include media-breakpoint-down(md) {
                opacity: 0.6;
            }
        }

        &:after {
            content:   "play_circle_filled";
            @include material-icon;
            position:  absolute;
            font-size: 160px;
            top:       50%;
            left:      50%;
            margin:    -80px 0 0 -80px;
            color:     $rhenus-blue-dark;
            opacity:   0.6;

            @include media-breakpoint-down(md) {
                font-size: 130px;
                margin:    -65px 0 0 -65px;
            }
        }

        .button-ghost {
            margin-top: 24px;
        }
    }

    &_has-poster {
        .video-embed-overlay {
            &:hover {
                &:before {
                    opacity: 0.6;
                }
            }
        }
    }

    &:not(&_has-poster) {
        .video-embed-overlay {
            &:after {
                color: $rhenus-white-alt;
            }
        }
    }

    &_local-file {
        img {
            @include fill-parent;
        }
    }
}
