$transition-speed: 0.5s;
$transition-speed-fast: 0.15s;

.img-crossfade {
    position: relative;
    overflow: hidden;

    img {
        transition: 0.3s opacity ease-in, $transition-speed transform ease;
        width: 100%;
        transform: scale(1);
    }
    // > img:not(.--img-fader) would be better, but does not work on ie maybe, so we use first child
    img:not(:first-child) {
        opacity: 0;
    }

    &.--img-loaded img {
        opacity: 1;
        position: relative;
    }

    > img.--img-fader {
        // only position fader absolute, so natural flow is not broken
        position: absolute !important;
        left: 0;
        top: 0;
    }

    &.--img-loaded > img.--img-fader {
        opacity: 0;
    }
}

.effect-transition {
    .img-crossfade img {
        -webkit-transition: -webkit-transform 1s ease-out, 0.8s opacity ease-in;
        transition: -webkit-transform 1s ease-out, 0.8s opacity ease-in;
        transition: transform 1s ease-out, 0.8s opacity ease-in;
        transition: transform 1s ease-out, -webkit-transform 1s ease-out, 0.8s opacity ease-in;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .img-crossfade.--img-loaded img {
        -webkit-transition: -webkit-transform 1s ease-out, 0.8s opacity ease-in;
        transition: -webkit-transform 1s ease-out, 0.8s opacity ease-in;
        transition: transform 1s ease-out, 0.8s opacity ease-in;
        transition: transform 1s ease-out, -webkit-transform 1s ease-out, 0.8s opacity ease-in;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}
