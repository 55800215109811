.col-alert{
    padding: 0px;
    margin-top: -1px;
}

.content-alert-container{
    background-color: $rhenus-content-alert-background;
    padding: 60px 0px 45px;
    top: -6px;
    position: relative;

    &>.container{
        position: relative;
    }

    h1,h2,h3,h4,h5,h6,p,span,ul,li{
        color: #fff;
    }
    a,a:hover,a:focus{
        color: #fff;
    }

    .icon{
        font-size: 1.5rem;
        margin-right: 0.5rem;
        top: 5px;
        position: relative;
    }

    .content-alert-close{
        position: absolute;
        top: -45px;
        right: 0;
        z-index: 999;
        span{
            font-size: 1.5rem;
        }
    }

    &.closed{
        display: none;
    }
}