.text-teaser {
    position:         relative;
    margin-bottom:    24px;
    border:           1px solid #f1f1f1;
    border-top-width: 0;

    @include media-breakpoint-up(lg) {
        height:        100%;
        margin-bottom: 0;
    }

    &_is-last {
        margin-bottom: 0;
    }

    &_mobile-collapse-enabled {
        @include media-breakpoint-down(md) {
            &.text-teaser_is-collapsed-mobile {
                margin-bottom: 48px;

                &.text-teaser_is-last {
                    margin-bottom: 24px;
                }
            }
        }
    }
}

.text-teaser-header {
    display:          flex;
    justify-content:  center;
    align-items:      center;
    padding:          24px;
    background-color: $rhenus-blue;
    text-align:       center;

    h3 {
        margin-bottom: 0;
        color:         $white;
        font-size:     16px;
        line-height:   24px;

        @include media-breakpoint-up(md) {
            font-size:   18px;
            line-height: 32px;
        }
    }
}

.text-teaser-content {
    position: relative;
    padding:  24px;

    .text-teaser_is-collapsed-mobile.text-teaser_mobile-collapse-enabled & {
        @include media-breakpoint-down(md) {
            padding:    24px 24px 48px;
            max-height: 100px;
            overflow:   hidden;

            &:after {
                @include fill-parent;

                top:        22%;
                content:    '';
                background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($white, 1) 75%, rgba($white, 1) 100%);
            }
        }
    }

    .page-body & ul {
        margin: 0;

        li {
            margin: 12px 0 12px 28px;

            &:last-child {
                margin: 0 0 0 28px;
            }
        }
    }

    p {
        margin-bottom: 12px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.text-teaser-cta {
    text-align: center;
    margin-top: 24px;
}

.text-teaser-collapse-trigger {
    position:         absolute;
    bottom:           -24px;
    left:             calc(50% - 24px);
    z-index:          10;
    display:          none;
    justify-content:  center;
    align-items:      center;
    width:            48px;
    height:           48px;
    border-radius:    50%;
    background-color: $rhenus-blue;
    color:            $white;

    .text-teaser_mobile-collapse-enabled.text-teaser_is-collapsed-mobile & {
        display: flex;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .text-teaser_is-collapsed-mobile & {
        @include pulse-animation();
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
        color:           $white;
    }

    .material-icons {
        font-size:   32px;
        line-height: 32px;
    }
}
