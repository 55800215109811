$news-item-standard-teaser-border-color: #f1f1f1;

.news-item-standard-teaser {
    display:          block;
    width:            100%;
    height:           100%;
    border-right:     1px solid $news-item-standard-teaser-border-color;
    border-bottom:    1px solid $news-item-standard-teaser-border-color;
    border-left:      1px solid $news-item-standard-teaser-border-color;
    background-color: $white;
    
    &:hover {
        cursor:          pointer;
        text-decoration: none;
        box-shadow:      0 2px 24px 0 rgba($black, 0.1);
    }
    
    &_product {
        .news-item-standard-teaser__content {
            position:   relative;
            min-height: unset;
            padding:    35px 30px 32px;
            
            .headline {
                @extend .h4;
                margin-bottom: 0;
            }
        }
    }
}

.news-item-standard-teaser__image {
    width:     100%;
    max-width: 100%;
    height:    auto;
}

.news-item-standard-teaser__content {
    position: relative;
    padding:  35px 30px 32px;
    
    .media-type-flag {
        line-height: 30px;
        position:    absolute;
        top:         -15px;
        height:      30px;
        padding:     0 15px;
        color:       $white;
        background:  $rhenus-blue;
    }
    
    .additional-information {
        margin:         0;
        padding-bottom: 16px;
        color:          $rhenus-blue;
    }
    
    .headline {
        margin-bottom: 0;
        color:         $rhenus-gray-dark;
    }
}

.news-item-standard-teaser-container,
.news-slider-container-neu {
    .tns-controls {
        button {
            font-size:        30px;
            position:         absolute;
            z-index:          2;
            top:              calc(50% - 24px);
            width:            48px;
            height:           48px;
            margin-top:       0;
            @include material-icon;
            text-align:       center;
            color:            $rhenus-blue;
            
            border:           none;
            background-color: rgba(255, 255, 255, 0);
            
            &:nth-child(1) {
                left: -24px;
            }
            
            &:nth-child(2) {
                right: -24px;
            }
        }
    }
}

.news-slider-container-neu {
    .tns-controls button {
            @include media-breakpoint-up(xl) {
            background-color: rgba($white, 1);
            
            &:nth-child(1) {
                left: -55px;
            }
        
            &:nth-child(2) {
                right: -55px;
            }
        }
    }
    
    .news-item-standard-teaser {
        @at-root .stripe & {
            border: 0 !important;
        }
        
        /** Within a slider, all teaser need to be of the same height. */
        .headline {
            min-height: 160px;
            
            @include media-breakpoint-only(lg) {
                min-height: 220px;
            }
        }
    }
}