#tx-solr-faceting {
    .tab-subfilter-headline {
        margin-bottom: 36px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 48px;
        }

        .material-icons {
            font-size: 20px;
        }

        .h3 {
            line-height:   24px;
            margin-bottom: 0;

            i {
                font-size: 1.75rem;
                position:  relative;
                top:       5px;
            }
        }
    }

    .facet-type-hierarchy {
        .list-group .list-group-item {
            padding-left: 0 !important;
        }
    }

    .panel-group {
        .panel {
            margin-bottom: 16px;

            .panel-heading {
                .panel-title a {
                    font-size:        16px;
                    line-height:      16px;
                    position:         relative;
                    display:          block;
                    width:            100%;
                    padding:          15px 20px;
                    color:            $rhenus-content-alert-background-alt;
                    border:           1px solid $rhenus-content-alert-background-alt;
                    background-color: $white;

                    &:hover, &:active, &:focus {
                        text-decoration: none;
                        color:           $rhenus-blue-dark;
                        border-color:    $rhenus-blue;
                    }

                    &[aria-expanded="true"] {
                        color:        $rhenus-blue-dark;
                        border-color: $rhenus-blue;

                        .material-icons {
                            transition: all .2s ease-in-out;
                            transform:  rotateX(180deg);
                        }
                    }

                    &:not([aria-expanded="true"]) {
                        .material-icons {
                            transition: all .2s ease-in-out;
                            transform:  rotateX(0deg);
                        }
                    }

                    .material-icons {
                        font-size: 24px;
                        position:  absolute;
                        top:       13px;
                        right:     12px;
                    }

                    .facet-result-count.badge {
                        font-size:        12px;
                        font-weight:      700;
                        line-height:      20px;
                        display:          inline-block;
                        width:            20px;
                        height:           20px;
                        padding:          0;
                        text-align:       center;
                        color:            $white;
                        border-radius:    20px;
                        background-color: $rhenus-blue;
                    }
                }

                &:not(:first-child) {
                    margin-top: 16px;
                }
            }

            .list-group {
                .list-group-item {
                    padding:       0;
                    border:        0;
                    border-radius: 0;

                    a, .facet-option-input {
                        font-size:        16px;
                        line-height:      16px;
                        position:         relative;
                        display:          block;
                        width:            100%;
                        padding:          15px 20px;
                        color:            $rhenus-gray-dark;
                        border:           1px solid $rhenus-content-alert-background-alt;
                        background-color: $white;
                        margin-bottom:    0px;

                        &:hover, &:active, &:focus {
                            text-decoration: none;
                            color:           $rhenus-blue;
                        }

                        input {
                            margin-right: 10px;
                        }

                        &.label-text_empty {
                            text-decoration: line-through;
                        }

                    }

                    &:first-child > a, &:first-child > .facet-option-input {
                        border-top: 0;
                    }
                }

                &.show {
                    .list-group-item > a,
                    .list-group-item > .facet-option-input {
                        border-right-color: $rhenus-blue;
                        border-left-color:  $rhenus-blue;
                    }

                    .list-group-item:last-child > a,
                    .list-group-item:last-child > .facet-option-input {
                        border-bottom-color: $rhenus-blue;
                    }
                }
            }

            .custom-radio-checkbox.checkbox {
                .label-text {
                    position: relative;
                    top:      6px;
                    display:  inline-block;

                    &_empty {
                        text-decoration: line-through;
                    }
                }

                .checkmark {
                    width:  18px;
                    height: 18px;
                }
            }
        }
    }
}

.link-disabled {
    pointer-events:  none;
    cursor:          default;
    text-decoration: none;
}

.open-filter-bar-mobile {
    &.active {
        background-color: $rhenus-blue;
        color:            $white;
        display:          flex;
        flex-wrap:        wrap;
        justify-content:  center;
        align-items:      center;

        .filter-count {
            background-color: $white;
            color:            $rhenus-blue;
            width:            18px;
            height:           18px;
            display:          flex;
            flex-wrap:        wrap;
            justify-content:  center;
            align-items:      center;
            border-radius:    50%;
            margin-left:      10px;
            font-size:        0.75rem;
        }
    }
}
