.accordion-container {
    .accordion-element {
        .accordion-header {
            line-height:         1.5rem;
            position:            relative;
            display:             flex;
            overflow:            hidden;
            flex-direction:      row;
            align-items:         center;
            min-height:          64px;
            margin-bottom:       8px;
            padding:             5px 32px 5px 0;
            cursor:              pointer;
            text-transform:      uppercase;
            background:          $rhenus-blue-light;
            background:          linear-gradient(315deg, transparent 20px, $rhenus-blue-accordion-header 20px);
            background-repeat:   no-repeat;
            background-position: bottom right;

            @include media-breakpoint-up(md) {
                height:  auto;
                padding: 0 32px 0 0;

                &:before {
                    top:        11px;
                    margin-top: 0;
                }
            }

            .custom-icon {
                position:   relative;
                display:    inline-block;
                width:      65px;
                min-width:  65px;
                text-align: center;

                .svg-container {
                    width: 100%;
                }

                svg {
                    margin-top: 16px;

                    @include media-breakpoint-up(md) {
                        margin-top: 21px;
                    }

                    &.rhenus-icon {
                        width:      40px;
                        margin-top: 0;
                    }
                }

                &:after {
                    position:         absolute;
                    top:              -200px;
                    right:            0;
                    bottom:           -200px;
                    display:          block;
                    width:            1px;
                    content:          "";
                    background-color: $white;
                }

                .rhenus-icon-container {
                    display:    inline-block;
                    overflow:   hidden;
                    width:      65px;
                    height:     48px;
                    margin-top: 10px;

                    img {
                        width:      45px;
                        height:     auto;
                        margin-top: 2px;

                        &.not-active-icon {
                            display: inline;
                        }

                        &.active-icon {
                            display: none;
                        }
                    }
                }
            }

            .accordion-indicator {
                margin: 0 12px;

                @include media-breakpoint-up(md) {
                    margin: 0 24px;
                }

                &:before {
                    @include material-icon;

                    font-size:   33px;
                    line-height: 48px;
                    content:     "add";
                }
            }

            .accordion-header-text {
                display:     flex;
                align-items: center;
                align-self:  center;
                height:      100%;

                .pre-headline {
                    font-weight:    bold;
                    display:        block;
                    text-transform: uppercase;
                }
            }
        }

        .accordion-content {
            padding: 16px 24px;

            @include media-breakpoint-up(md) {
                padding: 24px;
            }

            .frame-type-textmedia.frame-layout-0 {
                width: unset;
            }
        }

        &.active {
            .accordion-header {
                color:      $white;
                background: $rhenus-blue;
                background: linear-gradient(315deg, transparent 20px, $rhenus-blue 20px);

                .custom-icon {
                    .rhenus-icon-container {
                        img {
                            &.not-active-icon {
                                display: none;
                            }

                            &.active-icon {
                                display: inline;
                            }
                        }
                    }
                }

                .accordion-indicator {
                    &:before {
                        content: "remove";
                        color:   $white;
                    }
                }
            }
        }
    }
}

