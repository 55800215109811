@import "../variables";

.big-stage {
    position:   relative;
    overflow:   hidden;
    margin-top: -120px;
    text-align: center;

    /** Aspect Ratio */
    @include media-breakpoint-down(md) {
        @include box-aspect-ratio(3/4);
    }

    @include media-breakpoint-up(md) {
        padding-bottom: #{ceil(42.8571) + "%"};
    }

    /** Modifier */
    &_image {
        @include media-breakpoint-up(md) {
            padding-bottom: unset;
        }

        @include media-breakpoint-down(md) {
            height:         auto;
            padding-bottom: unset;
        }

        picture {
            img {
                width:  100%;
                height: auto;
            }
        }
    }

    /** Elements */
    &__mobile-wrapper {
        @include fill-parent();
        line-height: 0;
        display:     none;

        img {
            width:  auto;
            height: 100%;
        }

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    &__desktop-wrapper {
        @include fill-parent();
        line-height: 0;

        img, video {
            width:     100%;
            max-width: 100%;
            height:    auto;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.big-stage__content {
    @include fill-parent();

    display:     flex;
    align-items: center;

    .headline {
        font-size:      5rem;
        line-height:    5.5rem;
        margin:         0 auto;
        text-transform: uppercase;
        color:          $white;
        text-shadow:    0 2px 24px rgba(0, 0, 0, 0.5);

        @include media-breakpoint-only(lg) {
            font-size:   4rem;
            line-height: 4.5rem;
        }

        @include media-breakpoint-down(md) {
            font-size:   3rem;
            line-height: 3.5rem;
            max-width:   100%;
        }

        @include media-breakpoint-down(sm) {
            font-size:     28px;
            line-height:   36px;
            margin-bottom: 24px;
        }
    }

    p {
        font-size:   1.5rem;
        line-height: 2.5rem;
        max-width:   80%;
        margin:      24px auto 0;
        color:       $white;

        @include media-breakpoint-down(md) {
            font-size:   1rem;
            line-height: 1.5rem;
            max-width:   100%;
        }

        @include media-breakpoint-down(sm) {
            font-size:   16px;
            line-height: 24px;
        }
    }

    .button-secondary {
        margin-top:     24px;
        pointer-events: initial;
    }

    .big-stage_collision-with-header & {
        .headline {
            font-size:   3rem;
            line-height: 3.5rem;

            @include media-breakpoint-down(md) {
                font-size:   2rem;
                line-height: 2.5rem;
                max-width:   100%;
            }

            @include media-breakpoint-down(sm) {
                font-size:     24px;
                line-height:   32px;
                margin-bottom: 24px;
            }
        }
    }

    .big-stage_collision-with-header-smaller & {
        .headline {
            font-size:   2rem;
            line-height: 2.5rem;

            @include media-breakpoint-down(md) {
                font-size:   1rem;
                line-height: 1.5rem;
                max-width:   100%;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 24px;
            }
        }
    }
}
