.solr-search-document {
    position:   relative;
    display:    block;
    transition: all .2s ease-in-out;
    border:     1px solid $rhenus-gray;

    &:hover {
        cursor:     pointer;
        border:     1px solid $white;
        box-shadow: $box-shadow;

        .results-topic {
            text-decoration: underline;
        }
    }

    &.list-group-item {
        border-radius: 0.125rem;
    }

    &.search-result {
        margin-bottom: 30px;
    }

    .rootline-bar {
        line-height:   16px;
        margin-top:    10px;
        margin-bottom: 20px;
        color:         $rhenus-gray-light;

        .rootline-path-text {
            position: relative;
            top:      -6px;
            display: inline-block;

            i.material-icons {
                position: relative;
                top:      6px;
            }
        }

        .material-icons {
            font-size:   24px;
            line-height: 24px;
            width:       24px;
            height:      24px;

            &.home-icon {
                margin-right: 5px;
            }
        }
    }

    .results-topic {
        margin-bottom: 15px;
        color:         $rhenus-gray-dark;
    }

    .document-link {
        position:    absolute;
        z-index:     1000;
        top:         0;
        right:       0;
        bottom:      0;
        left:        0;
        display:     block;
        text-indent: -9999px;
    }
}
