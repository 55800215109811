.powermail_fieldwrap {
    margin-bottom: 30px;
}

.powermail_field {
    input[disabled] {
        background-color: #f2f2f2 !important;
    }
}

// required dot

.mdc-text-field__input:required~.mdc-floating-label::after, .mdc-text-field__input:required~.mdc-notched-outline .mdc-floating-label::after,
label .mandatory {
    display: none;
}

.required-dot {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 5px;
    width: 5px;
    background-color: $rhenus-gray-dark;
    opacity: 0.6;
    pointer-events: none;
    border-radius: 50%;
}

.select-required{
    .required-dot {
        display: block;
        border-radius: 50%;
        z-index: 5;
    }
}

.mdc-text-field {
    .required-dot {
        display: none;
    }

    .mdc-text-field__input:required + .required-dot {
        display: block;
        border-radius: 50%;
    }

    /*
    &.mdc-text-field--invalid {
        .mdc-text-field__input:required + .required-dot {
            background-color: $red;
            opacity: 1;
        }
    }
    */
    &.mdc-text-field--focused {
        .mdc-text-field__input:required + .required-dot {
            top: 9px;
            right: 9px
        }
    }

    &.mdc-text-field--invalid{
        background-color: $rhenus-gray-form-back !important;
        .mdc-text-field__input {
            caret-color: transparent;
        }
        &:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
            border-bottom-color: rgba(0,0,0,.42) !important;
        }
    }

    .parsley-error{
        background-color: $rhenus-form-error-background;
        border-bottom-color: $rhenus-form-error-red;
    }

}

ul.parsley-errors-list.filled + button[data-toggle=dropdown]{
    background-color: $rhenus-form-error-background !important;
    border-bottom-color: $rhenus-form-error-red !important;
}

.page-body {
    .parsley-errors-list:not(.unstyled-list) {
        position: absolute;
        bottom: -100%;
        font-size: 12px;
        li {
            padding: 0;
            margin-left: 0;
            &:before {
                display: none;
            }
        }
    }
    .mauticform-errormsg{
        font-size: 12px;
    }
    .powermail_fieldwrap .powermail_field{
        .mauticform-text, .mauticform-email, .mauticform-tel{
            .mauticform-errormsg{
                position: absolute;
                bottom: -100%;
                height: 56px;
            }
        }
    }
}

ul.powermail_message.powermail_message_error {
    color: red;
    li {
        margin-left: 0;
        &:before {
            display: none;
        }
    }
}

.powermail_fieldwrap.hidden {
    display: none!important;
}

.tx-powermail.only-show-b2b{
    position: relative;
    .b2b-button-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        padding: 80px 20px 20px 20px;
        z-index: 1;
        color: #000;
    }
    .b2b-form-container{
        opacity: 0.1;
        transition: opacity .5s ease-in-out;
    }
}

.b2b-hide{
    display: none!important;
}

.tx-powermail fieldset.margin-bottom-small{
    margin-bottom: 1rem;
}
