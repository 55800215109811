.category-button-list {
    list-style-type: none;
    margin:          0 0 0 -20px;

    .page-type-news_detail & {
        margin: 24px 0 48px;
    }

    li {
        margin: 0 12px 0 0 !important;

        &::before {
            display: none !important;
        }
    }

    a {
        display: inline-block;

        .btn {
            color:         $rhenus-blue-secondary;
            height:        38px;
            padding:       0 16px;
            border:        2px solid $rhenus-blue-secondary;
            border-radius: 24px;
            box-shadow:    none !important;
            display:       inline-flex;
            align-items:   center;
        }

        &:hover .btn {
            color:            $white;
            border:           2px solid $rhenus-blue-secondary;
            background-color: $rhenus-blue-secondary;
        }

        &:active .btn {
            color:            $white;
            border:           2px solid $rhenus-blue-secondary-dark;
            background-color: $rhenus-blue-secondary-dark;
        }

        &:focus .btn {
            color:            $white;
            border:           2px solid $rhenus-blue-secondary;
            background-color: $rhenus-blue-secondary;
        }

        &:disabled .btn {
            color:            $rhenus-gray-light;
            border:           2px solid #979797;
            background-color: $white;
        }
    }
}

