.solr-options-single {
    margin-bottom: 35px;
    border-bottom: 1px solid $rhenus-blue;

    @include media-breakpoint-up(md) {
        margin-bottom: 85px;
    }

    .tabular-options {
        padding: 16px 0;

        @include media-breakpoint-down(sm) {
            .col {
                display:         flex;
                justify-content: space-between;

            }
        }
    }

    .tabular-options__item {
        text-align: center;

        @include media-breakpoint-up(md) {
            display:       inline-block;
            padding-right: 40px;
            text-align:    left;
        }

        a.facet {
            font-size:        16px;
            font-weight:      700;
            padding:          21px 0;
            transition:       all ease-in-out .33s;
            text-decoration:  none;
            text-transform:   uppercase;
            color:            $rhenus-gray-light;
            border:           0;
            border-bottom:    4px solid transparent;
            border-radius:    0;
            background-color: transparent;

            @include media-breakpoint-up(md) {
                font-size: 24px;
            }

            &.selected, &:hover, &:active, &:focus {
                padding:       21px 0 17px;
                color:         $rhenus-blue;
                border-bottom: 4px solid $rhenus-blue;
            }

            .tabular-options__document-count {display: none}
        }
    }
}
