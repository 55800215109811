.powermail_fieldwrap {
    .mdc-text-field--textarea {
        border-bottom: 1px solid $rhenus-gray-dark;

        textarea {
            width: 100%;

            &.parsley-error {
                margin-bottom: 15px;
            }
        }

        &:not(.mdc-text-field--disabled) {
           background-color: #f5f5f5;

           .mdc-text-field__input {
               padding-top: 20px;
           }
        } 
       
        * {
           border: none;
           border-width: 1px;
           border-radius: 0;
        }

        .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
            transform: translateY(-40%) scale(0.75);
        }
   
        &.mdc-text-field--focused,
        &:hover {
            background-color: #e6f1f8;
            color: #00469b;
        }

        &.mdc-text-field--invalid {
            background-color: #ffe6e6;
            color: $rhenus-gray-dark;
            border-bottom: 1px solid #ff0000;
        }

    }
}

.page-body {
    .mdc-text-field--textarea {
        .parsley-errors-list:not(.unstyled-list) {
            bottom: -55px;
        }
    }

    .powermail_fieldwrap :not(.mdc-text-field--disabled).mdc-text-field .mdc-floating-label {
        @media all and (-ms-high-contrast:none) {
            top: 15px;

            *::-ms-backdrop { 
                top: 15px;
            }
        }
    }
}