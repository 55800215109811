.extended-stripe-container {
    &_has-image {
        padding-bottom: 40px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 65px;
        }
    }

    .extended-stripe {
        position:   relative;
        min-height: 310px;
        padding:    60px 95px 50px 0;
        color:      $white;
        background: $rhenus-blue;
        background: linear-gradient(315deg, transparent 80px, $rhenus-blue 80px);

        @include media-breakpoint-down(sm) {
            background: linear-gradient(315deg, transparent 50px, $rhenus-blue 50px);
        }

        @include media-breakpoint-down(md) {
            min-height: unset;
            padding:    40px 30px 40px 0;
        }

        &:after {
            position:   absolute;
            top:        0;
            bottom:     0;
            left:       -300%;
            display:    block;
            width:      300%;
            content:    "";
            background: $rhenus-blue;
        }

        .row {
            position: relative;
            @include media-breakpoint-down(md) {
                position: initial;
            }
        }
    }

    .headline {
        margin-bottom:  16px;
        text-transform: uppercase;
    }

    p {
        margin-bottom: 16px;
    }

    img {
        position: absolute;
        z-index:  1;
        top:      0;
        left:     -120px;
        display:  block;
        width:    450px;
        height:   auto;

        @include media-breakpoint-down(md) {
            top:    40px;
            width:  70%;
            height: auto;
            left:   15%;
            right:  15%;
        }

        @include media-breakpoint-up(lg) {
            left: -170px;
        }
    }

    .extended-stripe.extended-stripe_image-bottom-alignment {
        @include media-breakpoint-up(lg) {
            .row {
                position: unset;
            }

            img {
                top:         unset;
                bottom:      -40px;
                margin-left: -15px;
            }
        }
    }

    a {
        color: #fff;

        &.link-chevron {
            font-weight: 700;
            display:     block;
            margin-top:  16px;
            margin-left: 27px;
        }
    }
}
