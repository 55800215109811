.rhenus-event-detail {
    &__back-to-list {
        margin-top:    40px;
        margin-bottom: 40px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
        }
        
        .material-icons {
            font-size:      25px;
            margin-right:   -3px;
            vertical-align: -7px;
        }
    }
    
    &__headline {
        font-size:     32px;
        margin-top:    40px;
        margin-bottom: 40px;
        
        @include media-breakpoint-up(md) {
            margin-top: 80px;
        }
    }
    
    &__infoline {
        margin-top:    40px;
        margin-bottom: 40px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
        }
        
        span {
            display: inline-block;
            padding: 0 5px 0 0;
            
            &:first-child::after {
                padding: 0 5px;
                content: "|";
            }
            
            &:last-child {
                display:    block;
                margin-top: 20px;
                padding:    0;
                
                @include media-breakpoint-up(md) {
                    float:      right;
                    margin-top: unset;
                }
            }
            
            .material-icons {
                font-size:      25px;
                margin-right:   -3px;
                vertical-align: -7px;
            }
        }
    }
    
    &__program {
        margin-top:    40px;
        margin-bottom: 40px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
        }
        
        .h3 {
            margin-bottom: 25px;
        }
        
        > div:last-child {
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
        
        table.table {
            th, td {border-top: 0}
            
            tr:nth-child(odd) {background-color: $rhenus-gray-teaser-back;}
            
            tr:nth-child(even) {background-color: $white;}
        }
    }
    
    &__description {
        margin-top:    40px;
        margin-bottom: 40px;
    }
    
    &__event-image {
        margin-top:    40px;
        margin-bottom: 40px;
    }
}