/**
 * Please note, that the partial "_ext/News/Detail/BlogDetail.html" and "Templates/ContentElements/EditorialStage.html",
 * despite using the same class names, are not the same! Therefore, when styling the blog detail page, you have to wrap
 * your styles in ".frame-type-list" to avoid styling the editorial stage-content element.
 */
.frame-type-list {
    .editorial__stage {
        margin-bottom: 25px;

        @include media-breakpoint-only(md) {
            height: 460px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 25px;
        }

        .editorial__stage__container {
            @include media-breakpoint-down(sm) {
                position: relative;

                &:after {
                    content:    "";
                    display:    block;
                    position:   absolute;
                    bottom:     0;
                    left:       0;
                    width:      100%;
                    height:     100px;
                    z-index:    10;
                    background: $white;
                }
            }

            @include media-breakpoint-down(md) {
                padding-top: 120px;
            }

            .editorial__stage__imagebox {
                @include media-breakpoint-down(sm) {
                    position:   relative;
                    z-index:    20;
                    transform:  translateY(0);
                    margin-top: 0;
                }

                @include media-breakpoint-only(md) {
                    padding-top: 0;
                    height:      460px;
                }

                figure {
                    margin: 0;
                }

                .editorial__stage__image {
                    width: 100%;

                    @include media-breakpoint-only(md) {
                        height:     auto;
                        max-height: unset;
                    }
                }
            }

            .editorial__stage__content {
                @include media-breakpoint-between(md, lg) {
                    height: calc(100% - 120px);
                }

                .headline {
                    margin-top:     0;
                    margin-bottom:  24px;
                    text-transform: none;

                    @include media-breakpoint-between(md, lg) {
                        font-size:     24px;
                        line-height:   1.2;
                        margin-bottom: 24px;
                    }
                }

                .blog__detail__share {
                    @include media-breakpoint-down(sm) {
                        display:          block;
                        position:         fixed;
                        bottom:           -48px;
                        left:             0;
                        width:            100%;
                        background-color: $rhenus-blue;
                        z-index:          $zindex-fixed;
                        height:           48px;

                        .blog__detail__share__title {
                            position:        absolute;
                            left:            0;
                            top:             0;

                            margin-bottom:   0 !important;
                            justify-content: center;
                            color:           $white;
                            height:          48px;
                            width:           100%;
                        }

                        .blog__detail__share__links {
                            position: absolute;
                            left:     0;
                            top:      0;
                            height:   48px;
                            width:    100%;

                            .share-item {
                                background-color: transparent;
                                margin-right:     0;
                                justify-content:  left;

                                &-close {
                                    position: absolute;
                                    right:    0;
                                }

                                svg {
                                    .share-item-color {
                                        fill: $white;
                                    }
                                }

                                .share-icon-box {
                                    .material-icons {
                                        color:       $white;
                                        font-size:   24px;
                                        line-height: 24px;
                                    }
                                }
                            }
                        }
                    }

                    @include media-breakpoint-down(xs) {
                        .blog__detail__share__links {
                            left:  30px;
                            right: 30px;
                            width: calc(100% - 60px);
                        }
                    }

                    @include media-breakpoint-up(md) {
                        display:     flex;
                        flex-flow:   row wrap;
                        align-items: center;

                        .container > .row > div {
                            padding-left:  0;
                            padding-right: 0;
                        }
                    }

                    .blog__detail__share__links {
                        display:   inline-flex;
                        flex-wrap: wrap;

                        .share-item {
                            border-radius:   20px;
                            width:           32px;
                            height:          32px;
                            display:         flex;
                            align-items:     center;
                            justify-content: center;

                            &, &:hover, &:focus, &:active, &:visited {
                                text-decoration: none;
                            }

                            @include media-breakpoint-up(md) {
                                margin-right:     15px;
                                background-color: #fff;

                                svg {
                                    .share-item-color {
                                        fill: $white;
                                    }
                                }

                                .share-icon-box {
                                    .material-icons {
                                        color:       $rhenus-blue;
                                        font-size:   24px;
                                        line-height: 24px;
                                    }
                                }
                            }

                            @include media-breakpoint-down(md) {
                                width:         48px;
                                height:        48px;
                                border-radius: 24px;
                            }

                            @include media-breakpoint-between(md, lg) {
                                width:  32px;
                                height: 32px;

                                img {
                                    width:  24px;
                                    height: 24px;
                                }

                                i {
                                    font-size: 24px;
                                }
                            }

                            .share-icon-box {
                                display:         flex;
                                align-items:     center;
                                justify-content: center;
                            }
                        }
                    }

                    .blog__detail__share__title {
                        margin-bottom: 0;
                        margin-right:  16px;
                        display:       inline-flex;
                        align-items:   center;

                        @include media-breakpoint-only(md) {
                            margin-bottom: 8px !important;
                        }

                        &:before {
                            @extend .material-icons;
                            content:      "share";
                            font-size:    24px;
                            display:      inline-block;
                            line-height:  1.5;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }

    .blog-teaser-text {
        @include media-breakpoint-up(md) {
            margin-top: 180px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 120px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 150px;
        }

        .blog__detail__teaser {
            p {
                font-size:   20px;
                line-height: 36px;

                &:last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    font-size:   24px;
                    line-height: 40px;
                }
            }
        }
    }

    .blog-content-elements {
        margin-top: 30px;

        @include media-breakpoint-up(md) {
            margin-top: 140px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: -15px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 115px;
        }

        &_has-teaser-before {
            @include media-breakpoint-up(md) {
                margin-top: 80px;
            }
        }
    }

    .blog__detail__date {
        color:         $rhenus-blue;
        margin-bottom: 16px;

        @include media-breakpoint-down(md) {
            margin-bottom: 8px;
            margin-top:    24px;
        }
    }

    .blog__detail__tags {
        display:       flex;
        flex-wrap:     wrap;
        margin-bottom: 40px;

        .blog__detail__tag {
            background-color: $rhenus-blue;
            color:            rgba(255, 255, 255, .85);
            padding:          3px 8px 4px;
            margin-right:     5px;
            margin-bottom:    5px;
        }
    }

    .breadcrumb-container {
        margin-bottom: 100px;
    }

    .blog__stripe {
        position:      relative;
        margin-bottom: 80px;

        &:before,
        &:after {
            content:          "";
            display:          block;
            transform:        skewX(136deg);
            height:           180px;
            width:            75px;
            background-color: $rhenus-gray-dark;
            opacity:          0.1;
            position:         absolute;
            top:              -90px;
            right:            50%;

            @include media-breakpoint-down(md) {
                height: 130px;
                width:  52px;
                top:    -65px;
            }
        }

        &:before {
            @include media-breakpoint-only(xl) {
                margin-right: -470px;
            }

            @include media-breakpoint-only(lg) {
                margin-right: -380px;
            }

            @include media-breakpoint-down(md) {
                margin-right: -285px;
            }

            @include media-breakpoint-down(sm) {
                margin-right: -200px;
            }
        }

        &:after {
            @include media-breakpoint-only(xl) {
                margin-right: -365px;
            }

            @include media-breakpoint-only(lg) {
                margin-right: -275px;
            }

            @include media-breakpoint-down(md) {
                margin-right: -215px;
            }

            @include media-breakpoint-down(sm) {
                margin-right: -130px;
            }
        }

        .blog__headline {
            margin-bottom: 24px;
        }
    }

    .blog__source {
        margin-top:    80px;
        margin-bottom: 80px;

        .blog__source__heading {
            margin-bottom: 24px;
        }

        ul, ol {
            li {
                margin:      0;
                margin-left: 28px;
                line-height: 34px;
            }
        }
    }
}

.blog__list, .blog__detail, .blog__selectedlist {
    .magic-grid-news {
        .magic-grid-item {
            .image-teaser {
                height:     420px;
                transition: 300ms ease;
                @include media-breakpoint-down(md) {
                    height: 345px;
                }

                &:hover {
                    box-shadow: 0 0 20px 0 grey;
                }
            }

            &:nth-child(1), &:nth-child(6n) {
                .image-teaser {
                    height: 569px;
                    @include media-breakpoint-down(md) {
                        height: 463px;
                    }
                }
            }
        }

        &.less-four {
            .magic-grid-item {
                &:nth-child(1), &:nth-child(6n) {
                    .image-teaser {
                        height: 420px;
                        @include media-breakpoint-down(md) {
                            height: 345px;
                        }
                    }
                }
            }
        }
    }

    .blog-teaser-item {
        .additional-information {
            padding-bottom: 24px;

            span:nth-child(n+2):before {
                content:       "|";
                padding-left:  12px;
                padding-right: 16px;
            }
        }

        .image-teaser-hover-content {
            max-height: unset;

            p {
                margin-top: 0;
            }
        }

        .blog-teaser-item-content {
            width:  100%;
            height: 100%;

            .blog-teaser-item-link {
                min-height:     360px;
                height:         100%;
                width:          100%;
                display:        flex;
                align-items:    flex-end;
                padding-left:   24px;
                padding-right:  24px;
                padding-bottom: 24px;

                &:hover {
                    text-decoration: none;

                    .arrow-link {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .blog__detail__relatednews__heading {
        margin-bottom: 40px;
    }

    .blog__detail__relatednews {
        display:         flex;
        justify-content: center;
        flex-wrap:       wrap;
        margin-left:     -15px;
        margin-right:    -15px;
    }

    .image-teaser-container .image-teaser {
        padding: 0;

        .headline {
            text-transform: none;
            margin-bottom:  24px;
        }

        &:before {
            transition: 300ms ease;
        }

        &:hover {
            &:before {
                background:       none;
                background-color: $rhenus-blue;
                mix-blend-mode:   multiply;
            }
        }
    }

    .arrow-link {
        &:before {
            @extend .material-icons;
            content:     "chevron_right";
            font-size:   14px;
            display:     inline-block;
            line-height: 1.5;
        }
    }

    .blog-count {
        margin-bottom: 24px;
    }
}

.blog__selectedlist {
    display:         flex;
    justify-content: center;
    margin-left:     -15px;
    margin-right:    -15px;

    .image-teaser {
        padding: 0;
    }
}

.lpc-blog-list {
    font-family: "Open Sans", sans-serif;

    &__category-menu {
        display:        flex;
        flex-direction: column;
        font-size:      14px;
        padding:        32px 0;
        gap:            25px;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            gap:            5px;
            padding:        64px 0;
        }

        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }

        &-item {
            cursor: pointer;

            &.active {
                font-weight: bold;
            }
        }
    }

    &__items {
        display:       flex;
        gap:           15px;
        min-height:    300px;
        margin-bottom: 40px;

        &__no-results {
            font-style: italic;
            font-size:  18px;
        }
    }

    &__load-more {
        display:       flex;
        flex-flow:     column nowrap;
        align-items:   center;
        font-size:     22px;
        line-height:   30px;
        margin-bottom: 40px;

        &,
        &:hover,
        &:focus,
        &:visited {
            color:           #000;
            text-decoration: none;
        }

        .material-icons {
            font-size:     48px;
            border-radius: 24px;
            color:         #000;
        }
    }
}

.lpc-item-blog-teaser {
    min-height:    452px;
    overflow:      hidden;
    position:      relative;
    margin-bottom: 15px;
    width:         100%;

    @include media-breakpoint-up(md) {
        width: calc(100% / 3 - 15px);
    }

    &.big {
        min-height: 569px;
    }

    a {
        @include fill-parent();

        display:         flex;
        flex-direction:  column;
        justify-content: flex-end;
        padding:         0 25px;

        font-family:     "Open Sans", sans-serif;
        color:           #fff;
        text-decoration: none;

        &:before {
            content:    '';
            position:   absolute;
            top:        0;
            left:       0;
            width:      100%;
            height:     100%;
            background: transparent linear-gradient(0deg, #000 0%, #2B2B2B7C 38%) 0 0 no-repeat padding-box;
            z-index:    -1;
        }

        time {
            font-size:     17px;
            line-height:   23px;
            font-weight:   normal;

            margin-bottom: 2px;
        }

        .headline {
            font-size:      24px;
            line-height:    33px;
            font-weight:    normal;
            text-transform: none;

            margin-bottom:  0;
        }

        .lpc-item-blog-teaser__read-more p {
            font-size:     12px;
            line-height:   17px;
            font-weight:   normal;

            margin-top:    20px;
            margin-bottom: 30px;
            height:        0;
            opacity:       0;

            transition:    height 0.3s, opacity 0.3s;
        }

        img {
            @include fill-parent();
            width:            100%;
            height:           100%;
            object-fit:       cover;
            z-index:          -2;
            transform-origin: center center;
        }

        @keyframes slow-zoom {
            0% {
                transform: scale(1);
                filter:    blur(0);
            }

            5% {
                transform: scale(1.1);
                filter:    blur(6px);
            }

            100% {
                transform: scale(1.2);
                filter:    blur(6px);
            }
        }

        &:hover {
            .lpc-item-blog-teaser__read-more p {
                height:  17px;
                opacity: 1;
            }

            img {
                animation-name:     slow-zoom;
                animation-duration: 30s;
            }
        }
    }

    .lpc-item-blog-teaser_image {

    }
}

.lpc-blog-detail {
    font-family: "Open Sans", sans-serif;
    color:       #000;
    margin-top:  -48px;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    &__header {
        margin: 40px 0;

        @include media-breakpoint-up(md) {
            margin: 70px 0 60px;
        }

        &-content {
            height:          100%;
            display:         flex;
            flex-flow:       column nowrap;
            justify-content: center;
            margin-bottom:   64px;

            @include media-breakpoint-up(md) {
                padding-right: 30px;
                margin-bottom: 0;
            }

            time {
                margin-bottom: 18px;
                font-size:     18px;
                line-height:   24px;
                font-weight:   normal;
                color:         #000;
            }

            .headline {
                font-size:      45px;
                line-height:    61px;
                font-weight:    bold;
                color:          #000;
                margin-bottom:  20px;
                text-transform: none;
            }

            > p {
                font-size:   18px;
                line-height: 24px;
                color:       #000;
            }

            .lpc-blog-detail__share {
                display:     flex;
                flex-flow:   row wrap;
                align-items: center;
                margin-top:  30px;

                .material-icons {
                    font-size:    18px;
                    line-height:  20px;
                    color:        #000;
                    margin-right: 8px;
                }

                h2 {
                    font-size:      18px;
                    line-height:    20px;
                    font-weight:    normal;
                    color:          #000;
                    margin-bottom:  0;
                    text-transform: none;
                    margin-right:   18px;
                }

                &-links {
                    display:    inline-flex;
                    flex-wrap:  wrap;
                    gap:        11px;
                    margin-top: 4px;

                    .share-item {
                        border-radius:    20px;
                        width:            20px;
                        height:           20px;
                        display:          flex;
                        align-items:      center;
                        justify-content:  center;
                        background-color: #00fafa;

                        .share-icon-box {
                            display:         flex;
                            align-items:     center;
                            justify-content: center;

                            svg {
                                width:  14px;
                                height: 14px;

                                path, rect, mask {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__intro {
        margin-bottom: 80px;

        p {
            font-size:     16px;
            line-height:   35px;
            color:         #000;
            margin-bottom: 24px;

            @include media-breakpoint-up(md) {
                margin-bottom: 40px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .blog-content-elements {
        margin-top: 0;
    }

    .frame-type-rscwcontentelements_accordion {
        .accordion-item__title {
            color: #000;
        }
    }

    .hinweisbox {
        position:      relative;
        border:        3px solid #00FAFA;
        padding:       55px 20px 37px 20px;
        margin-bottom: 40px;

        .hinweisbox_icon {
            width:            100px;
            aspect-ratio:     1/1;

            position:         absolute;
            right:            38px;
            top:              -50px;

            border-radius:    70px;
            background-color: #fff;

            display:          flex;
            justify-content:  center;
            align-items:      center;

            .material-icons {
                font-size: 100px;
                color:     #00FAFA;
            }
        }

        .hinweisbox_headline {
            font-size:      20px;
            line-height:    33px;
            font-weight:    bold;
            color:          #000;
            text-transform: none;
            margin-bottom:  18px;
        }

        .hinweisbox_bodytext {
            ul {
                margin-bottom: 0;
            }

            li {
                font-size:    20px;
                line-height:  33px;
                color:        #000;
                margin:       0;
                padding-left: 20px;

                &:before {
                    content:    '»';
                    position:   absolute;
                    transform:  translateX(-100%) translateY(-7px);
                    width:      20px;
                    height:     unset;
                    background: none;
                    left:       auto;
                    top:        auto;
                }

                &:not(:last-of-type) {
                    margin-bottom: 37px;
                }
            }
        }

        @include media-breakpoint-up(sm) {
            padding: 55px 33px 37px 33px;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
            padding:       60px 100px 37px 50px;

            .hinweisbox_headline {
                font-size: 24px;
            }

            .hinweisbox_bodytext {
                li {
                    font-size: 24px;
                }
            }

            .hinweisbox_icon {
                width: 140px;
                top:   -70px;

                .material-icons {
                    font-size: 140px;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            padding: 70px 121px 37px 97px;
        }
    }

    .highlighted-text-container {
        background: transparent;
        text-align: left;
        min-height: unset;

        .container {
            padding-left: 0 !important;
        }

        .offset-xl-3 {
            margin-left: 0 !important;
            flex:        0 0 100% !important;
            max-width:   100% !important;
        }

        .highlighted-text-content-box {
            padding-top:    52px;
            padding-bottom: 46px;

            .headline {
                font-size:      32px;
                line-height:    35px;
                font-weight:    normal;
                color:          #fff;
                text-transform: none;
                margin-bottom:  16px;
            }

            p {
                font-size:     24px;
                line-height:   35px;
                color:         #fff;
                font-weight:   normal;
                margin-bottom: 0;
            }
        }

        .highlighted-text-container-slashes {
            display: none;
        }

        .highlighted-text-contact-person {
            position: absolute;
            top:      0;
            bottom:   0;
            left:     -100%;
            right:    -100%;
            width:    300%;
            z-index:  -1;
            overflow: hidden;
            display:  block;

            .img-crossfade {
                height: 100%;

                img {
                    height:     100%;
                    object-fit: cover;
                }
            }
        }

        .highlighted-text-cta {
            margin-left:    0;
            margin-top:     54px;
            border:         2px solid #FFFFFF;
            padding:        0 24.5px;
            text-transform: none;
            font-size:      22px;
            line-height:    48px;
            font-weight:    normal;
        }
    }

    .frame-type-textmedia p {
        font-family: "Open Sans", sans-serif;
        font-size:   16px;
        line-height: 35px;
        color:       #000;
    }

    h1,
    h2,
    h3,
    h4,
    .h1,
    .h2,
    .h3,
    .h4 {
        text-transform: none;
        font-family:    "Open Sans", sans-serif;
        color:          #000;
    }
}
