$bilgenfarbe: #ffcf21;
$rheintankfarbe: #e42313;
$dbrfarbe: #e42313;
.main-header {
    &.bilgenentoelung.navigation-desktop {
        background-color: #fff;

        .container {
            position: relative;
            .nav-ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: absolute;
                left: 0;
                padding: 0 15px;
                top: 28%;
                transform: translateY(-50%);
                margin-bottom: 0;
                z-index: 3;
                li {
                    padding: 8px 8px;
                    margin: 0;
                    margin-left: 16px;
                    color: $bilgenfarbe;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:before {
                        content: unset;
                    }
                    a {
                        color: $rhenus-gray-dark;
                    }
                }
            }
            .main-navigation {
                padding: 20px 0;
                display: flex;
                justify-content: end;
                width: 100%;
                position: relative;

                &:after {
                    content:'';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    background-color: $bilgenfarbe;
                    z-index: 1;
                }

                .logo-container {
                    z-index: 2;
                    transform: translateX(-16px);
                    .brand-logo {
                        .logo {
                            width: auto;
                            height: 36px;
                        }
                    }
                }
            }
        }
    }
    &.rheintank.navigation-desktop {
        background-color: #fff;

        .container {
            position: relative;
            .nav-ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: absolute;
                left: 0;
                padding: 0 15px;
                top: 28%;
                transform: translateY(-50%);
                margin-bottom: 0;
                z-index: 3;
                li {
                    padding: 8px 8px;
                    margin: 0;
                    margin-left: 16px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:before {
                        content: unset;
                    }
                    a {
                        color: $rhenus-gray-dark;
                        &.active {
                            font-weight: bold;
                        }
                    }
                }
                .submenulink-box {
                    position: absolute;
                    top: 35px;
                    width: 80vw;
                    display: none;

                    &.active {
                        display: unset;
                    }
                    .submenulink-list {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        padding-top: 0;
                        padding-bottom: 0;

                        .submenulink-link {

                        }
                    }
                }
            }
            .main-navigation {
                padding: 20px 0;
                display: flex;
                justify-content: end;
                width: 100%;
                position: relative;

                &:after {
                    content:'';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    background-color: $rheintankfarbe;
                    z-index: 1;
                }

                .logo-container {
                    z-index: 2;
                    transform: translateX(-16px);
                    .brand-logo {
                        .logo {
                            width: auto;
                            height: 36px;
                        }
                    }
                }
            }
        }
    }
    &.dbr.navigation-desktop {
        background-color: #fff;
        height: 80px;

        .container {
            position: relative;
            .nav-ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: absolute;
                left: 0;
                padding: 0 15px;
                top: 28%;
                transform: translateY(-50%);
                margin-bottom: 0;
                z-index: 3;
                li {
                    padding: 8px 8px;
                    margin: 0;
                    margin-left: 16px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:before {
                        content: unset;
                    }
                    a {
                        color: $rhenus-gray-dark;
                        &.active {
                            font-weight: bold;
                        }

                    }
                }
                .submenulink-box {
                    position: absolute;
                    top: 35px;
                    width: 80vw;
                    display: none;

                    &.active {
                        display: unset;
                    }
                    .submenulink-list {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }

            }
            .main-navigation {
                padding: 20px 0;
                display: flex;
                justify-content: end;
                width: 100%;
                position: relative;

                &:after {
                    content:'';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    background-color: $dbrfarbe;
                    z-index: 1;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .logo-container {
                    z-index: 2;
                    transform: translateX(-16px);
                    position: relative;
                    &:after {
                        position: absolute;
                        content:'';
                        width: calc(100% + 16px);
                        height: calc(100% + 16px);
                        background-color: #fff;
                        left: 0;
                        transform: translate(-8px, -8px);
                        z-index: -1;
                    }
                    .brand-logo {
                        z-index: 2;
                        .logo {
                            width: auto;
                            height: 36px;
                        }
                    }
                }
            }
        }
    }

    &.bilgenentoelung.navigation-mobile {
        .header-bar-mobile {
            background-color: #fff;
            .container {
                position: relative;

                .mobile-flyout-trigger {
                    &:before {
                        color: $bilgenfarbe;
                    }
                }
                .logo {
                    width: 90%;
                    right: 0;
                    left: unset;
                    display: flex;
                    justify-content: end;
                    top: 43%;
                    transform: translateY(-50%);
                    padding: 0 15px;
                    @include media-breakpoint-down(sm){
                        width: 85%;
                    }
                    @include media-breakpoint-down(xs){
                        width: 77%;
                    }
                    &:after {
                        content:'';
                        position: absolute;
                        height: 1px;
                        width: 100%;
                        background-color: $bilgenfarbe;
                        z-index: 1;
                        transform: translateY(-50%);
                        top: 50%;
                    }
                    a {
                        z-index: 2;
                    }
                    img {
                        z-index: 2;
                        width: auto;
                        height: 30px;
                    }
                }
            }
        }
    }
    &.rheintank.navigation-mobile {
        .header-bar-mobile {
            background-color: #fff;
            .container {
                position: relative;

                .mobile-flyout-trigger {
                    &:before {
                        color: $rheintankfarbe;
                    }
                }
                .logo {
                    width: 90%;
                    right: 0;
                    left: unset;
                    display: flex;
                    justify-content: end;
                    top: 43%;
                    transform: translateY(-50%);
                    padding: 0 15px;
                    @include media-breakpoint-down(sm){
                        width: 85%;
                    }
                    @include media-breakpoint-down(xs){
                        width: 77%;
                    }
                    &:after {
                        content:'';
                        position: absolute;
                        height: 1px;
                        width: 100%;
                        background-color: $rheintankfarbe;
                        z-index: 1;
                        transform: translateY(-50%);
                        top: 50%;
                    }
                    a {
                        z-index: 2;
                    }
                    img {
                        z-index: 2;
                        width: auto;
                        height: 30px;
                    }
                }
            }
        }
    }
    &.dbr.navigation-mobile {
        .header-bar-mobile {
            background-color: #fff;
            .container {
                position: relative;

                .mobile-flyout-trigger {
                    &:before {
                        color: $dbrfarbe;
                    }
                }
                .logo {
                    width: 90%;
                    right: 0;
                    left: unset;
                    display: flex;
                    justify-content: end;
                    top: 43%;
                    transform: translateY(-50%);
                    padding: 0 15px;
                    @include media-breakpoint-down(sm){
                        width: 85%;
                    }
                    @include media-breakpoint-down(xs){
                        width: 77%;
                    }
                    &:after {
                        content:'';
                        position: absolute;
                        height: 1px;
                        width: 100%;
                        background-color: $dbrfarbe;
                        z-index: 1;
                        transform: translateY(-50%);
                        top: 50%;
                    }
                    img {
                        position: relative;
                        z-index: 2;
                        width: auto;
                        height: 48px;
                        padding: 8px;
                        background-color: #fff;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(lg){
    .bilgenentoelung, .rheintank {
        .stage-content{
            padding-left: 36px;
            padding-top: 36px;
            padding-right: 15px;
            .h1 {
                margin-bottom: 0;
            }
            .mobile-divider {
                margin-bottom: 0;
            }
        }
    }
}
