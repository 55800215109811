.additional-information {
    color: $rhenus-gray-light;
    
    span {
        &:nth-child(n+2) {
            &:before {
                content: " | ";
            }
        }
    }
    
    .page-type-news_detail & {
        margin: 64px 0 24px;
    }
    
    .image-teaser & {
        color: $white;
    }
}

.container_press{
    .additional-information {
        .page-type-news_detail & {
            margin: 24px 0 24px;
        }
    }
}