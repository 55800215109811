.editorial__stage {
    position:         relative;
    height:           640px;
    margin-top:       -120px;
    &.bg-lightblue{
        background-color: $rhenus-blue-accordion-header;
    }
    &.bg-grey{
        background-color: $rhenus-section-grey;
    }
    @include media-breakpoint-down(lg){
        padding-top:      42.85714286%;
        height: 0;
    }
    @include media-breakpoint-down(md){
        margin-bottom: 100px;
        padding-top: 37%;
    }
    @include media-breakpoint-down(sm){
        padding-top: unset;
        height: unset;
    }
    .editorial__stage__container {
        position:    absolute;
        top:         0;
        left:        0;
        width:       100%;
        height:      100%;
        padding-top: 120px;
        @include media-breakpoint-down(md){
            padding-top: 48px;
        }
        @include media-breakpoint-down(sm){
            position: unset;
        }
        .editorial__stage__content{
            display:         flex;
            flex-direction:  column;
            justify-content: center;
            height:          calc(100% - 120px);
            @include media-breakpoint-down(lg){
                height:      calc(100% - 92px);
            }
            @include media-breakpoint-down(md){
                height:      calc(100% - 80px);
            }
            @include media-breakpoint-down(sm){
                height:      unset;
                .headline{
                    margin-top: 40px;
                }
                p{
                    margin-bottom: 0;
                }
            }
            .headline{
                margin-bottom: 40px;
            }
        }
        .editorial__stage__imagebox{
            @include media-breakpoint-down(lg){
                float: right;
            }
            @include media-breakpoint-down(sm){
                transform: translateY(88px);
                margin-top: 40px;
                width: 100%;
            }
            .editorial__stage__image{
                max-width: 100%;
                height:    auto;
                @include media-breakpoint-down(lg){
                    height: calc(42vw - 64px);
                    width:  auto;
                }
                @include media-breakpoint-down(md){
                    max-height: calc(42vw - 16px);
                    max-width:  100%;
                }
                @include media-breakpoint-down(sm){
                    height: auto;
                    width:  100%;
                    max-height: unset;
                }
            }
            figcaption{
                margin-top: 8px;
            }
        }
    }
}