.stage_slider {
  position: relative;
  height: 0;
  width: 100%;

  .stage {
    margin-top: 0;

    &.tns-slide-active img {
      @include media-breakpoint-up(xl) {
        //transform: scale(1.05) rotateZ(1deg);
        //transition: all 6s ease-in-out;
      }
    }

    .stage-content {
      @include media-breakpoint-only(xl) {
        bottom: 80px;
      }
    }
  }
}

.frame-type-rscwcontentelements_stage_slider .tns-outer {
  margin-top: -175px;
  @extend .stage;
  height: unset;

  #tns1-mw {
    margin-bottom: 60px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
  }

  .tns-nav {
    position: absolute;
    bottom: -60px;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;

    button {
      height: 45px;
      width: 45px;
      margin: 0;
      border: 0;
      box-shadow: none;
      padding: 0;

      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("$typo3conf/ext/rscw_provider/Resources/Public/Icons/IndicatorSvg/single_indicator_gray.svg");

      @include media-breakpoint-up(xl) {
        background-image: url("$typo3conf/ext/rscw_provider/Resources/Public/Icons/IndicatorSvg/single_indicator_white.svg");
      }

      &.tns-nav-active {
        background-image: url("$typo3conf/ext/rscw_provider/Resources/Public/Icons/IndicatorSvg/single_indicator_active.svg");
      }
    }

    @include media-breakpoint-up(xl) {
      bottom: -5px;
      margin-bottom: 0;
    }
  }

  figure{
    .background-image{
      @include media-breakpoint-up(xl) {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
        min-width: 100%;
        &.image-mobile{
            display: none;
        }
      }
    }
  }
}
