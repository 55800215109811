.page-body form.new-contact {
    label .mandatory {
        display: inline-block;
        margin-left: 5px;
    }

    a {
        white-space: nowrap;
    }

    .powermail_fieldwrap {
        margin-bottom: 32px;

        .mdc-text-field {
            border-radius: 10px;
            background-color: #fff !important;
            box-shadow: 1px 1px 8px rgba(0,0,0,0.2);
            border: 0 !important;

            &:not(.mdc-text-field--disabled) {
                .mdc-floating-label {
                    color: $rhenus-gray-light;
                    white-space: inherit;
                    position: absolute;
                    left: 15px;
                }
            }

            .mdc-text-field__input {
                background-color: none;
                color: $rhenus-gray-dark;
                border-bottom: 0;
                border-radius: 10px;

                &.parsley-error {
                    &:after {
                        content: '';
                        height: 20px;
                        width: 100%;
                    }
                }

                &:required {
                    + .required-dot {
                        display: none;
                    }
                }
            }
        }

        &.powermail_fieldwrap_business {
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                margin-bottom: 16px;
            }
        }

        &.powermail_fieldwrap_newsletter {
            margin-bottom: 8px;

            p {
                margin-bottom: 0;
            }
        }

        &.powermail_fieldwrap_consenttodataprotectionpolicy {
            margin-bottom: 16px;
        }

        &.powermail_fieldwrap_send {
            margin-bottom: 15px;
        }

        &.powermail_fieldwrap_type_html {
            font-size: 14px;
            line-height: 1.3;
            a {
                font-weight: 800;
                color: $rhenus-gray-dark;
            }
        }

        &.powermail_fieldwrap_type_submit {
            input {
                background-color: $rhenus-blue;
                opacity: 1;
                border-radius: 10px;
                border: 0;
                text-transform: unset;
                font-size: 18px;
                padding: 13px 44px 14px;
                box-shadow: 1px 1px 8px rgba(0,0,0,0.2);
                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 20px 0px;
                    color: rgb(255, 255, 255);
                }
            }
        }

        .mdc-text-field--textarea
        textarea.parsley-error {
            margin: 0;
        }
    }

    .rhenus-custom-select {
        label {
            left: 15px;
        }

        .bootstrap-select:not(.input-group-btn),
        .bootstrap-select[class*=col-] {
            border: 0;
            border-radius: 10px;
            box-shadow: 1px 1px 8px rgba(0,0,0,0.2);

            button {
                border-radius: 10px;
                background-color: #fff;
                color: $rhenus-gray-light !important;
            }

            .dropdown-menu {
                border: 0;
                top: 12px !important;
                box-shadow: 1px 1px 8px rgba(0,0,0,0.2);
                border-radius: 10px;

                ul:not(.unstyled-list) {
                    li a {
                        border-top: 0;
                    }
                }
            }
        }

        .dropdown-toggle.bs-placeholder .filter-option {
            line-height: 50px;
        }
        .powermail_label {
            color: $rhenus-gray-light;
            white-space: inherit;
        }

        &.select-required {
            .required-dot {
                display: none;
            }
            .bs-placeholder {
                .filter-option-inner-inner {
                    &::after {
                        content: ' *';
                    }
                }
            }
        }
    }

    .form-col-6-left {
        padding-right: 15px;
    }
    .form-col-6-right {
        padding-left: 15px;
    }

    .form-col-6-left,
    .form-col-6-right {
        display: inline-block;
        width: 50%;
        @include media-breakpoint-down(sm) {
            width: 100%;
            padding: 0;
         }
    }
}

.tx-powermail.formlayout_2023 {
    margin-top: 40px;

    @include media-breakpoint-up(md) {
        margin-top: -120px;
        background-image: url("$typo3conf/ext/rscw_datenmuehle/Resources/Build/Assets/Images/AdobeStock_629603523_HG_Verlauf.jpg");
        background-size: cover;
        background-position: center top;
        padding-top: 140px;
        padding-bottom: 92px;

        .container .row {
            min-height: 60vh;
        }
     }

     @include media-breakpoint-down(sm){
        background: none !important;
     }

    .form_container {
        margin-bottom: 24px;
        padding: 15px 15px 15px;

        @include media-breakpoint-up(md) {
            align-self: flex-end;
            border-radius: 20px;
            box-shadow: 1px 1px 15px rgba(0,0,0,0.2);
            background-color: rgba(255,255,255, 0.8);
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .powermail_fieldwrap_type_input {
            .powermail_field {
                position: relative;

                .form-popover {
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 10px;
                }
            }
        }
        .powermail_fieldwrap_type_check {
            .custom-radio-checkbox {
                p {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
        }
        .powermail_fieldwrap_type_radio {
            .powermail_label {
                &.required {
                    .required-dot {
                        display: none;
                    }
                    &::after {
                        content: ' *';
                    }
                }
                font-size: 19px;
            }
            .powermail_field {
                label {
                    margin-bottom: 8px;
                    padding-left: 24px;
                    min-height: 48px;

                    @include media-breakpoint-up(md) {
                        min-height: unset;
                    }

                    .checkmark {
                        top: 2px;
                    }
                }

                @include media-breakpoint-up(sm) {
                    display:         flex;
                    flex-direction:  row;
                    justify-content: space-between;
                }
                .parsley-required {
                    display: none;
                }
            }
        }
    }

    .text_container {
        color: $rhenus-blue;

        > div {
            @include media-breakpoint-up(md) {
                color:    #fff;
                position: sticky;
                top:      400px;
            }
        }

        h2,
        .h2 {
            @include media-breakpoint-up(md) {
                font-size: 60px;
                margin-bottom: 32px;
            }
            line-height: initial;
            text-transform: initial;
        }
    }
    .businesscase_container {
        align-self: flex-start;

        @include media-breakpoint-up(md) {
            border-radius: 20px;
            box-shadow: 1px 1px 15px rgba(0,0,0,0.2);
            padding: 30px;
            background-color: rgba(255,255,255, 0.8);
        }
        .businesscase-selector {
            margin: 10;
            color: $rhenus-blue;
            .stepp {
                margin-bottom: 8px;
                font-size: 17px;
            }
            .introquestion {
                margin-bottom: 40px;
                font-size: 24px;
            }
            .buttons {
                button {
                    border: none;
                    border-radius: 10px;
                    background-color: $rhenus-blue;
                    color: #fff;
                    height: 40px;
                    font-size: 18px;
                    min-width: 120px;
                    &.business {
                        margin-right: 24px;
                    }
                }
            }
        }
    }
}
