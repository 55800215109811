/**
    The navigation and content, despite being part of the same content element, are nested in different frames.
    For this reason, the navigation frame must not be affected by the margin-bottom of the content frame. This will
    instead be applied to the separate content frame.
*/
.frame-type-rscwcontentelements_tabcontent {
    margin-bottom: 0 !important;
}

/** Navigation */
.tabular-content {
    position: relative;

    .tabs {
        position:    relative;
        white-space: nowrap;

        &_sticky {
            position:   fixed;
            top:        48px;
            left:       0;
            right:      0;
            background: $white;
            z-index:    $zindex-fixed;

            .container {
                padding-left:  30px;
                padding-right: 30px;

                @include media-breakpoint-up(sm) {
                    padding-left:  15px;
                    padding-right: 15px;
                }

            }
        }

        &:not(.tabs_sticky) {
            .container {
                @include media-breakpoint-up(sm) {
                    padding-left:  0;
                    padding-right: 0;
                }
            }
        }


        #tns4-iw {
            margin: 0;
        }

        .tns-controls {
            button {
                display: none;
            }
        }

        .tabular-content-button {
            position: absolute;
            top:      32px;
            cursor:   pointer;
            color:    $rhenus-gray-light;
            width:    25px;

            &:hover {
                @include media-breakpoint-up(lg) {
                    color: $rhenus-blue-secondary;
                }
            }

            &.slider-prev {
                left: -10px;
            }

            &.slider-next {
                right: -3px;
            }

            &.slider-next,
            &.slider-prev {
                text-align: left;
                top:        13px;

                @include media-breakpoint-up(lg) {
                    top: 29px;
                }

                &.disabled {
                    display: none;
                }
            }

            .material-icons {
                font-size:        32px;
                background-color: rgba($white, .9);

                &::selection {
                    background: rgba($white, 0);
                }
            }
        }

        .nav-tabs {
            position:      relative;
            top:           0;
            left:          0;
            display:       inline-block;
            margin:        0;
            padding:       0;
            border-bottom: 0;
        }

        .nav-item {
            display:     inline-block;
            float:       none;
            margin:      0;
            white-space: normal;
        }

        .nav-link {
            font-weight:      700;
            font-size:        14px;
            padding:          0 1rem 1rem;
            text-transform:   uppercase;
            color:            $rhenus-gray-light;
            border:           0;
            border-bottom:    3px solid transparent;
            border-radius:    0;
            background-color: transparent;

            @include media-breakpoint-up(lg) {
                padding:   1rem 1rem;
                font-size: 1rem;
            }

            &.active, &:hover {
                color:         $rhenus-blue-secondary;
                border-bottom: 3px solid $rhenus-blue-secondary;
            }

            &:hover:not(.active) {
                color:         unset;
                border-bottom: unset;
            }
        }
    }
}

/** Content */
.tabular-content-bg {
    background-color: $rhenus-blue-accordion-header;
    border-top:       1px solid $rhenus-blue-secondary;

    .tab-pane {
        padding-top:    2rem;
        padding-bottom: 2rem;
    }
}


