.animation-background-gradient {
    &__background {
        animation:        animation-background-gradient-slide 18s ease-in-out infinite alternate;
        background-color: $rhenus-blue;
        background-image: linear-gradient(315deg, $rhenus-blue-dark 50%, $rhenus-blue 50%);
        bottom:           0;
        left:             -50%;
        opacity:          0.5;
        position:         absolute;
        right:            -50%;
        top:              0;
        z-index:          -1;

        &_2 {
            animation-direction: alternate-reverse;
            animation-duration:  24s;
        }

        &_3 {
            animation-duration: 30s;
        }
    }
}

@keyframes animation-background-gradient-slide {
    0% {
        transform: translateX(-25%);
    }
    100% {
        transform: translateX(25%);
    }
}
