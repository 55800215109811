.frame-type-textmedia {
    &.frame-layout-0 {
        width: 83.333%;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    figure.image {
        .img-fluid {
            width: 100%;
            max-width: none;
        }
    }

    p {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
        }
    }
}

.frame-type-textmedia .table {
    @extend .table-responsive;
    border: 0;

    p {
        margin-bottom: 0;
    }

    td, th {
        width: 25%;
        padding: 16px;
        border-top: 0;
        border-right: 20px solid transparent;
        border-bottom: 0;
        @include media-breakpoint-up(lg) {
            border-right: 30px solid transparent;
        }

        &:last-child {
            border-right: 0;
        }
    }

    > thead {
        // headline: row
        > tr > th {
            font-weight: bold;
            position: relative;
            padding-left: 16px;
            white-space: nowrap;
            text-transform: uppercase;
            color: $rhenus-gray-dark;

            &[scope="col"]::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                content: "";
                background: $rhenus-gray-dark;
            }
        }
    }

    > tbody > tr {
        &:nth-of-type(odd) {
            background: $rhenus-blue-lighter;
        }

        &:nth-of-type(even) {
            background: $white;
        }

        // headline: column
        > th {
            font-weight: bold;
            color: $rhenus-gray-dark;
        }
    }

    &.table-responsive-blue > tbody > tr {
        &:nth-of-type(even) {
            background: $rhenus-blue-lighter;
        }

        &:nth-of-type(odd) {
            background: $white;
        }
    }

    // highlight: row
    .frame-type-rscwcontentelements_stripe & > thead tr.table-highlight {
        background: $rhenus-blue-dark;

        th, td {
            color: $white !important;

            &::after {
                height: 0;
            }
        }
    }

    .frame-type-rscwcontentelements_stripe & > tbody tr.table-highlight {
        background: transparent;
        background-repeat: no-repeat;

        &:nth-of-type(even) {
            background: $rhenus-blue-dark;
        }

        &:nth-of-type(odd) {
            background: $rhenus-blue-secondary;
        }

        th, td {
            color: $white !important;

            &::after {
                height: 0;
            }
        }
    }

    // highlight: column/cell
    .frame-type-rscwcontentelements_stripe & td.table-highlight,
    .frame-type-rscwcontentelements_stripe & th.table-highlight {
        color: $white !important;
    }

    .frame-type-rscwcontentelements_stripe & > thead > tr > th.table-highlight {
        position: unset;
        background: $rhenus-blue-dark;

        &::after {
            height: 0;
        }
    }

    .frame-type-rscwcontentelements_stripe & > tbody > tr {
        > th.table-highlight, > td.table-highlight {
            width: calc(100% + 30px);
            border-right: 0 !important;
            background: $rhenus-blue-dark;
        }

        &:nth-of-type(odd) {
            > th.table-highlight, > td.table-highlight {
                width: calc(100% + 30px);
                border-right: 0 !important;
                background: $rhenus-blue-secondary;
            }
        }
    }

    // highlight: cutted-off corner for highlighted cell
    .frame-type-rscwcontentelements_stripe & > thead > tr.table-highlight,
    .frame-type-rscwcontentelements_stripe & > tbody > tr.table-highlight {
        &:nth-of-type(odd) {
            td, th {
                &.cut-off-corner {
                    background: linear-gradient(315deg, $rhenus-blue-lighter 18px, $rhenus-blue-secondary 18px);
                    background-color: $rhenus-blue-secondary;
                    background-repeat: no-repeat;
                }
            }
        }

        &:nth-of-type(even) {
            td, th {
                &.cut-off-corner {
                    background: linear-gradient(315deg, $white 18px, $rhenus-blue-dark 18px);
                    background-color: $rhenus-blue-dark;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .frame-type-rscwcontentelements_stripe & > thead > tr,
    .frame-type-rscwcontentelements_stripe & > tbody > tr {
        &:nth-of-type(odd) {
            td.table-highlight, th.table-highlight {
                &.cut-off-corner {
                    background: linear-gradient(315deg, $rhenus-blue-lighter 18px, $rhenus-blue-secondary 18px);
                    background-color: $rhenus-blue-secondary;
                    background-repeat: no-repeat;
                }
            }
        }

        &:nth-of-type(even) {
            td.table-highlight, th.table-highlight {
                &.cut-off-corner {
                    background: linear-gradient(315deg, $white 18px, $rhenus-blue-dark 18px);
                    background-color: $rhenus-blue-dark;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}
