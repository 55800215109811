@import "tx-solr-autosuggest";
@import "solr-news-filter";
@import "solr-pagination";
@import "solr-searchinfo";
@import "solr-searchbar";
@import "solr-search-result";
@import "solr-search-document";
@import "tx-solr-faceting";
@import "solr-options-single";
@import "tab-subfilter-link";

.tx_solr {
    background-color: $rhenus-gray-teaser-back;

    &.layout-split {
        background-color: $white;
        @include media-breakpoint-down(md) {
            margin-top: -72px;
        }
    }

    .stripe {
        //height: 375px;
        @include media-breakpoint-down(sm) {
            //height: 442px;
            padding-top:    36px !important;
            padding-bottom: 66px !important;
        }
    }

    .news-item-standard-teaser-container .row > div {
        margin-top: 32px;
    }

    .news-item-standard-teaser {
        .news-item-standard-teaser__content {
            h3.h3.headline {
                font-size:   16px;
                line-height: 24px;
            }

            @include media-breakpoint-up(xl) {
                min-height: unset;
            }
        }
    }

    #results-per-page {
        button[value="6"] {
            display: none;

            & + .divider {
                display: none
            }
        }
    }

    .results-highlight {
        background-color: transparent;
        font-weight:      bold;
    }
}

.tx-solr-loader {
    @extend .spinner-border;
    position:   fixed;
    z-index:    10000;
    top:        calc(50% - 12px);
    left:       calc(50% - 12px);
    width:      24px;
    height:     24px;
    color:      $rhenus-blue;
    background: unset;

    &::before,
    &::after {
        display: none;
    }
}

#tx-solr-search {
    padding-top: 40px;
}
