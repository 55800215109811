@import "../variables";

.default-teaser-container {
    position: relative;

    .row {
        display:   flex;
        flex-wrap: wrap;

        > [class*="col-"] {
            display:        flex;
            flex-direction: column;
        }

        &.default-teaser-slideshow {
            display: table;
            height:  100%;

            > [class*="col-"] {
                display: table-cell;
                height:  100%;

                @include media-breakpoint-down(xs) {
                    // css black magic. Don't touch it!
                    padding-right: 15px !important;
                }
            }
        }
    }

    .tns-controls {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }

        button {
            @include material-icon;

            font-size:        30px;
            position:         absolute;
            z-index:          2;
            top:              50%;
            width:            48px;
            height:           48px;
            margin-top:       -24px;
            text-align:       center;
            border:           none;
            background-color: rgba($white, 0.8);

            @include media-breakpoint-up(lg) {
                background-color: transparent;
            }

            @include media-breakpoint-down(sm) {
                top:        150px;
                margin-top: 0;
            }

            &:nth-child(1) {
                left: -60px;

                @include media-breakpoint-down(lg) {
                    left: 0;
                }
            }

            &:nth-child(2) {
                right: -60px;

                @include media-breakpoint-down(lg) {
                    right: 0;
                }
            }
        }
    }

    /**
        The navigation using "dots".
     */
    .tns-nav {
        display:         flex;
        align-items:     center;
        flex-flow:       row nowrap;
        justify-content: center;
        padding:         40px 0 0;
        column-gap:      16px;

        button {
            width:         10px;
            height:        10px;
            padding:       0;
            border:        0;
            border-radius: 6px;
            background:    $rhenus-gray;

            &.tns-nav-active {
                background: $rhenus-blue;
            }
        }
    }

    &.active-slideshow {
        position: relative;

        &:before,
        &:after {
            position:         absolute;
            z-index:          1;
            top:              0;
            bottom:           0;
            width:            200%;
            content:          "";
            background-color: $white;
        }

        &:before {
            left:        -200%;
            margin-left: -30px;
        }

        &:after {
            right:        -200%;
            margin-right: -30px;
        }

        .tns-ovh {
            overflow: visible;
        }

        .tns-controls {
            display: block;
        }
    }

    .tns-ovh {
        @include media-breakpoint-up(lg) {
            overflow: hidden;
            margin:   -30px;
            padding:  30px;
        }
    }

    &:not(.active-slideshow) {
        .tns-transparent {
            visibility: visible;
            opacity:    1;
        }

        .default-teaser-slideshow {
            @include media-breakpoint-up(lg) {
                transform: translate3d(0%, 0px, 0px) !important;
            }
        }
    }

    .tns-item {
        @include media-breakpoint-down(md) {
            padding: 0 0 0 15px !important;
        }
    }

    .tns-inner {
        @include media-breakpoint-up(lg) {
            margin-right: -30px !important;
        }
    }
}

.default-teaser-box {
    display:          block;
    width:            100%;
    height:           100%;
    border-right:     1px solid #f1f1f1;
    border-bottom:    1px solid #f1f1f1;
    border-left:      1px solid #f1f1f1;
    background-color: $white;

    @include media-breakpoint-down(md) {
        padding-bottom: 32px;
        margin-bottom: 24px;
    }

    .default-teaser-content {
        min-height:       76px;
        padding:          24px 24px 64px;
        background-color: #ffffff;
    }

    .headline {
        text-transform: uppercase;
        color:          $rhenus-gray-dark;
        margin-bottom: 0;
    }

    .subheadline {
        margin-bottom:  24px;
        text-transform: uppercase;
        color:          $rhenus-blue-dark;
    }

    p {
        margin: 24px 0 0;
        color:  $rhenus-gray-dark;
    }

    .link-chevron {
        position:   absolute;
        bottom:     32px;
        margin-top: 24px;

        @include media-breakpoint-down(md) {
            bottom: 48px;
        }
    }

    img {
        width:     100%;
        max-width: 100%;
        height:    auto;
    }

    .default-teaser-box--rhenus-slashes {
        position: relative;
        overflow: hidden;

        &::before {
            position:        absolute;
            z-index:         12;
            top:             0;
            right:           -2.5px;
            bottom:          -1px;
            left:            0;
            content:         "";
            opacity:         .33;
            background:      url("$typo3conf/ext/rscw_provider/Resources/Public/Icons/rhenus-slash-white.svg") bottom right no-repeat;
            background-size: 42.5%;
        }

        &::after {
            position:         absolute;
            z-index:          10;
            top:              0;
            right:            -2.5px;
            bottom:           -1px;
            left:             0;
            content:          "";
            opacity:          .25;
            background:       $white url("$typo3conf/ext/rscw_provider/Resources/Public/Icons/rhenus-slash-white--background.svg") no-repeat bottom right;
            background-size:  42.5%;
        }
    }

    &.default-teaser-big {
        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
        }

        .headline {
            font-size:      1.5rem;
            font-weight:    normal;
            line-height:    2rem;
            text-transform: none;
        }
    }
}

a.default-teaser-box {
    &:hover {
        cursor:          pointer;
        text-decoration: none;
        box-shadow:      0 2px 24px 0 rgba($black, 0.1);
    }
}

.default-teaser-container.mobile {
    display: none;

    @include media-breakpoint-down(xs) {
        display: block;
    }
}

.default-teaser-container.desktop {
    @include media-breakpoint-down(xs) {
        display: none;
    }
}
