/* BLOCK: Download Highlight Box */
.download-highlight-box {
    border:    2px solid $rhenus-gray;
    position:  relative;
    display:   flex;
    flex-flow: column nowrap;

    @include media-breakpoint-up(lg) {
        flex-flow: row nowrap;
    }

    &_two-downloads {
        @include media-breakpoint-up(lg) {
            flex-flow: column nowrap;
        }

        @include media-breakpoint-up(xl) {
            flex-flow: row nowrap;
        }

        .download-highlight-box__info-section {
            @include media-breakpoint-up(lg) {
                width: 100%;
            }

            @include media-breakpoint-up(xl) {
                width: 50%;
            }
        }

        .download-highlight-box__item-container {
            @include media-breakpoint-up(lg) {
                width: 100%;
            }

            @include media-breakpoint-up(xl) {
                width: 50%;
            }
        }
    }

    &__icon-tile {
        background-color: $rhenus-blue-secondary;
        width:            125px;
        height:           50px;
        position:         absolute;
        top:              20px;
        right:            -15px;

        &:after {
            content:       '';
            position:      absolute;
            top:           48px;
            right:         1px;
            width:         0;
            height:        0;
            border-left:   9.5px solid transparent;
            border-right:  9.5px solid transparent;
            border-bottom: 9.5px solid $rhenus-blue-dark;
            transform:     rotateZ(-45deg);
        }

        .material-icons {
            width:        50px;
            height:       50px;
            border-right: 1px solid $white;
            font-size:    24px;
            line-height:  1;
            color:        $white;
            text-align:   center;
            padding-top:  13px;
        }

        @include media-breakpoint-up(lg) {
            width:  145px;
            height: 60px;
            right:  -20px;

            &:after {
                top:           58px;
                border-left:   14px solid transparent;
                border-right:  14px solid transparent;
                border-bottom: 14px solid $rhenus-blue-dark;
            }

            .material-icons {
                width:       60px;
                height:      60px;
                padding-top: 18px;
            }
        }
    }

    &__subheadline {
        font-size:      16px;
        line-height:    24px;
        color:          $rhenus-blue-secondary;
        text-transform: uppercase;
        margin-bottom:  12px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
        }
    }

    &__headline {
        color:          $rhenus-gray-dark;
        text-transform: uppercase;
        margin-bottom:  24px;
        font-weight:    700;
        font-size:      20px;
        line-height:    28px;

        @include media-breakpoint-up(lg) {
            font-size:   24px;
            line-height: 32px;
        }
    }

    &__text {
        p {
            font-size:      16px;
            color:          $rhenus-gray-dark;
            letter-spacing: 0;
            line-height:    24px;
            margin-bottom:  12px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__info-section {
        order:   2;
        padding: 40px 15px;

        @include media-breakpoint-up(lg) {
            order:   unset;
            padding: 55px 40px 40px;
            width:   50%;
        }
    }

    &__item-container {
        display:          flex;
        flex-flow:        column nowrap;
        gap:              40px;
        background-color: $rhenus-gray-form-back;
        padding:          90px 15px 40px;
        margin:           0 1px 1px;
        justify-content:  center;
        align-items:      center;

        @include media-breakpoint-up(lg) {
            width:     50%;
            flex-flow: row nowrap;
            padding:   80px 40px 40px;
            gap:       0;

            .download-highlight-box_two-downloads & {
                justify-content: space-around;
                align-items:     flex-start;
            }
        }
    }
}

/* BLOCK: Download Highlight Box Item */
.download-highlight-box-item {
    display:         flex;
    flex-flow:       column nowrap;
    height:          100%;
    width:           100%;
    justify-content: flex-start;

    @include media-breakpoint-up(sm) {
        width: 60%;
    }

    @include media-breakpoint-up(lg) {
        width: 100%;

        .download-highlight-box_two-downloads & {
            width: 45%;
        }
    }

    &_no-headline {
        .download-highlight-box:not(.download-highlight-box_two-downloads) & {
            padding-top: 24px;
        }

        @include media-breakpoint-up(xl) {
            .download-highlight-box_two-downloads & {
                padding-top: 24px;
            }
        }
    }

    &__preview-image {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

        @include media-breakpoint-up(lg) {
            order:      1;
            width:      50%;
            align-self: center;

            .download-highlight-box_two-downloads & {
                width: 100%;
            }
        }

        @include media-breakpoint-up(xl) {
            width: 40%;
        }

        a {
            @include box-aspect-ratio(70/99);

            display:  block;
            overflow: hidden;
        }

        img {
            object-fit: cover;
            width:      100%;
            height:     auto;
        }
    }

    &__headline {
        margin:          0 0 24px;
        font-size:       20px;
        line-height:     28px;
        font-weight:     700;
        display:         flex;
        flex-flow:       column nowrap;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            margin:     16px 0;
            text-align: center;
        }

        .download-highlight-box_two-downloads & {
            @include media-breakpoint-only(lg) {
                height: 56px;
            }
        }
    }

    &__download-button {
        @include pulse-animation(2s, 'unset');

        align-self:      center;
        width:           100%;
        display:         flex;
        flex-flow:       row nowrap;
        align-items:     center;
        justify-content: center;
        gap:             8px;
        padding-left:    20px;
        padding-right:   20px;
        margin-top:      24px;

        @include media-breakpoint-up(lg) {
            order:      3;
            width:      auto;
            align-self: center;
        }
    }
}
