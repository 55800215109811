@import "../variables";

.staunezahl {
    position: relative;

    &::after {
        position:         absolute;
        top:              calc(100% - 2px);
        bottom:           0;
        left:             0;
        right:            0;
        background-color: $rhenus-gray;
        content:          '';
        z-index:          10;
    }
}

.staunezahl--topline {
    font-size:      16px;
    font-weight:    bold;
    line-height:    24px;
    margin-bottom:  28px;
    text-transform: uppercase;
    color:          $rhenus-gray-dark;
}

.staunezahl--subline {
    font-size:     16px;
    line-height:   40px;
    margin-bottom: 12px;
    color:         $rhenus-blue-secondary;
}

.staunezahl--number-box {
    font-size:   40px;
    font-weight: bold;
    line-height: 40px;
    color:       $rhenus-blue-secondary;
}

.staunezahl--progress-bar {
    position:         absolute;
    top:              calc(100% - 2px);
    bottom:           0;
    left:             0;
    background-color: $rhenus-blue-secondary;
    z-index:          20;
    max-width:        100%;
    transition:       width .2s ease;
}
