.facts__figures__headline {
    margin: 0 0 24px;

    @include media-breakpoint-up(lg) {
        margin: 40px 0;
    }
}

.facts__figures {
    .facts__figures__copytext {
        p, .introtext {
            font-size:     20px;
            line-height:   36px;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                font-size:     24px;
                line-height:   40px;
                margin-bottom: 24px;
            }
        }
    }

    .facts__figures__element1 {
        margin: 0 0 40px;

        .facts__figures__element1_mainheadline {
            font-size:      20px;
            line-height:    28px;
            font-weight:    700;
            text-transform: uppercase;
            margin:         40px 0 24px;

            @include media-breakpoint-up(lg) {
                font-size:   24px;
                line-height: 32px;
                margin:      80px 0 24px;
            }
        }

        .facts__figures__element1__linked {
            color:           $body-color;
            text-decoration: none;

            *:not(.arrow-link) {
                color: $body-color;
            }

            &:hover {
                * {
                    text-decoration: none;
                }

                .arrow-link {
                    text-decoration: underline;
                }
            }
        }

        .facts__figures__element1__content {
            margin-bottom: 24px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 40px;
            }

            &_is-last {
                margin-bottom: 0;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 40px;
                }
            }

            .facts__figures__text1 {
                margin-bottom: 0;
            }

            .facts__figures__imagebox {
                margin-bottom: 12px;
            }
        }
    }

    .facts__figures__element2 {
        margin: 40px 0 0;

        &_no-element1 {
            @include media-breakpoint-up(lg) {
                margin-top: 80px;
            }
        }

        .facts__figures__element2_mainheadline {
            font-size:      20px;
            line-height:    28px;
            font-weight:    700;
            margin-bottom:  24px;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                font-size:   24px;
                line-height: 32px;
            }
        }

        .facts__figures__element2_contentbox {
            border-bottom: 1px solid $rhenus-gray;
            display:       flex;
            height:        100%;
            padding:       24px 0;

            &_is-first {
                padding-top: 0;
            }

            &_is-last {
                border-bottom:  none;
                padding-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                border-bottom: none;
                padding:       0;
                margin:        0 0 24px;

                &_is-last {
                    margin-bottom: 40px;
                }
            }

            .facts__figurs__element2_icon {
                padding-right:   8px;
                height:          max-content;
                display:         flex;
                align-items:     center;
                justify-content: center;

                img {
                    transform: translateY(5px);
                }
            }

            .facts__figures__element2__content {
                height: max-content;

                .facts__figures__headline2 {
                    font-size:      16px;
                    line-height:    24px;
                    font-weight:    700;
                    margin-bottom:  12px;
                    text-transform: uppercase;
                }

                .facts__figures__text2 {
                    margin-bottom: 0;
                }

                .arrow-link {
                    &:before {
                        content: "chevron_right";
                    }
                }
            }
        }
    }

    .arrow-link {
        color: $rhenus-blue;

        &:before {
            @extend .material-icons;
            font-size:     16px;
            transform:     translateY(2px);
            display:       inline-block;
            line-height:   1.5;
            color:         $rhenus-blue;
            padding-right: 4px;
        }

        &.internal {
            &:before {
                content: "chevron_right";
            }
        }

        &.external {
            &:before {
                content:       "launch";
                padding-right: 7px;
            }
        }

        &.download {
            &:before {
                content:       "get_app";
                padding-right: 7px;
            }
        }
    }
}
