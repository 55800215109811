$news-item-fokus-teaser-border-color: #f1f1f1;

.news-item-fokus-teaser {
    display:          block;
    width:            100%;
    height:           100%;
    border:           1px solid $news-item-fokus-teaser-border-color;
    background-color: $white;

    &:hover {
        cursor:          pointer;
        text-decoration: none;
        box-shadow:      0 2px 24px 0 rgba($black, 0.1);
    }

    /** ----- ELEMENTS -----*/
    &__image {
        width:             100%;
        background-repeat: no-repeat;
        background-size:   cover;

        @include media-breakpoint-down(md) {
            @include box-aspect-ratio(16/9);
        }

        @include media-breakpoint-up(lg) {
            display: inline-block;
            float:   left;
            width:   64%;
            height:  470px;
        }
    }

    &__content {
        position: relative;
        padding:  35px 30px;

        @include media-breakpoint-up(lg) {
            height: 100%;
        }

        .media-type-flag {
            line-height: 30px;
            position:    absolute;
            top:         -15px;
            height:      30px;
            padding:     0 15px;
            color:       $white;
            background:  $rhenus-blue;
        }

        .additional-information {
            line-height:    1;
            padding-top:    20px;
            padding-bottom: 16px;
            color:          $rhenus-blue;
        }

        .headline {
            margin-bottom: 0;
            color:         $rhenus-gray-dark;
        }

        p {
            color: $rhenus-gray-dark;
        }
    }

    /** ----- MODIFIER -----*/
    &_primary {
        .news-item-fokus-teaser__content {
            padding: 15px 30px 35px;

            p {
                display: none;
            }
        }

        button,
        span.button-ghost {
            display: none;

            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom:   30px;
                left:     30px;
                display:  block;
            }
        }

        &:hover, &:active, &:focus {
            button {
                color:            $white;
                background-color: $rhenus-blue-secondary;
                box-shadow:       0 2px 20px 0 rgba($black, 0.2);
            }
        }

        @include media-breakpoint-up(lg) {
            .news-item-fokus-teaser__content {
                display: inline-block;
                width:   36%;
                padding: 20px 30px 0;

                p {
                    display:       block;
                    margin-bottom: 24px;
                }

                .headline {
                    margin-bottom: 16px;
                }

                .media-type-flag {
                    position: relative;
                    top:      unset;
                    display:  inline-block;
                }

                .news-item-fokus-teaser__text-box {
                    position: absolute;
                    right:    30px;
                    bottom:   76px;
                    left:     30px;
                }
            }
        }
    }

    &_secondary {
        .teaser-arrangement-style-short & {
            .news-item-fokus-teaser__content {
                padding: 15px 30px 35px;
            }

            @include media-breakpoint-up(lg) {
                .news-item-fokus-teaser__image {
                    display: inline-block;
                    float:   left;
                    width:   36%;
                    height:  100%;
                }

                .news-item-fokus-teaser__content {
                    display: inline-block;
                    width:   64%;
                    padding: 20px 30px 35px;

                    .media-type-flag {
                        position: relative;
                        top:      unset;
                        display:  inline-block;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                .news-item-fokus-teaser__image {
                    width: 360.88px;
                    height: 100%;
                    min-height: 201px;
                }
            }
        }

        .teaser-arrangement-style-long & {
            .news-item-fokus-teaser__image {
                display: block;
                width:   100%;
                @include box-aspect-ratio(16/9);
            }

            .news-item-fokus-teaser__content {
                padding: 0 30px 35px;

                .media-type-flag {
                    position: relative;
                    display:  inline-block;
                }

                .additional-information {
                    margin:  11px 0 16px;
                    padding: 0;
                }
            }
        }
    }
}
