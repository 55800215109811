.pagebrowser{
    text-align: center;
    &>nav{
        display: inline-block;
    }
}

.pagination{
    .page-item{
        i{
            color: $rhenus-gray-light;
        }
        .page-link{
            outline: none !important;
            border-radius: 0px;
            &.prev,&.next{
                border-color: #fff;
            }
            i{
                color: $rhenus-gray;
                font-size: 1.5rem;
                position: relative;
                top: -1px;
            }
            &:hover,&:focus{
                outline: none !important;
            }
            &.next,&.prev{
                padding-bottom: 0px;
                &:hover,&:focus{
                    background-color: #fff;
                }
            }
            &.next{
                margin-left: 1px;
            }
        }
        a.page-link{
            i{
                color: $rhenus-gray-dark;
            }
        }
        @include media-breakpoint-down(sm){
            &.more,&.less{
                display: none;
            }
        }
    }
}