@import "../variables";

@mixin pulse-animation($duration: 2s, $border-radius: 100px) {
    animation:     pulse-animation $duration infinite;
    border-radius: $border-radius;

    @keyframes pulse-animation {
        0% {
            box-shadow: 0 0 0 0 rgba($rhenus-blue, 0.7);
        }

        70% {
            box-shadow: 0 0 0 10px rgba($rhenus-blue, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba($rhenus-blue, 0);
        }
    }
}
