.frame-type-rscwcontentelements_roadfreightmap {
    background: #00418B !important;
    background: linear-gradient(90deg, #0073C0 0%, #00418B 100%) !important;

    header {
        position: relative;
        z-index: 1;

        * {
            color: $white;
            text-align: center;
        }

        p {
            font-size: 1.125rem;
        }
    }

    .container {
        position: relative;
        padding-top: 80px;

        &:before {
            content: '';
            position: absolute;
            inset: 0 -70px;
            background-image: url("$typo3conf/ext/rscw_provider/Resources/Public/Images/europe-map.png");
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.road-freight-improvement {
    .row {
        > div {
            padding-block: 50px;

            &:first-child {
                padding-bottom: 0;
            }
        }
    }

    .improvement-message_wrapper {
        display: flex;
        flex-direction: column;

        .improvement-message,
        .buttons {
            position: relative;
            z-index: 1;
        }

        .improvement-message {
            color: $white;
            order: 1;

            p {
                margin: 0;
                font-size: 1.5rem;
                line-height: 2.125rem;
                font-weight: 600;

                .improvement-value {
                    background-color: $secondary;
                    padding-inline: 7px;
                    white-space: nowrap;
                }

                a {
                    color: $secondary;
                }
            }
        }

        .buttons {
            margin-top: 0;
            margin-bottom: 50px;

            a {
                width: 100%;
                text-align: center;

                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }

        .image {
            display: none;
            margin-block: -50px;
            transform: translateX(-50%);
        }

        @include media-breakpoint-up(lg) {
            .improvement-message {
                order: unset;
            }

            .buttons {
                margin-top: 20px;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-top: 60px;
            padding-right: 60px;

            .image {
                display: block;
            }
        }
    }

    .country-slider_wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-bottom: 10px;
        flex-direction: row;

        .slider-wrapper {
            width: calc(50% - 10px);
        }

        &:before {
            position: absolute;
            top: 44px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            width: calc((100% - 40px) / 3 + 20px);
            background-color: $secondary;
            display: none;
        }

        @include media-breakpoint-only(md) {
            flex-direction: column;

            &:before {
                display: block;
            }

            .slider-wrapper {
                width: 100%;
            }
        }

        @include media-breakpoint-up(xl) {
            flex-direction: column;

            &:before {
                display: block;
            }

            .slider-wrapper {
                width: 100%;
            }
        }
    }

    .country-slider-navigation {
        position: relative;
        color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        p {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.875rem;
            text-transform: uppercase;
            margin: 0;
        }

        .compare-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            display: none;
            transform: translate(-50%, -50%);
            cursor: pointer;

            svg {
                width: 20px;

                path {
                    transition: fill 0.3s ease-out;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: $primary;
                    }
                }
            }
        }

        .tns-controls {
            display: flex;
            gap: 10px;

            .prev,
            .next {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 13px;

                    path {
                        transition: fill 0.3s ease-out;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: $secondary;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-only(md) {
            .compare-icon {
                display: block;
            }

            &.to-countries-navigation {
                margin-top: 5px;
            }

            .tns-controls {
                gap: 2px;

                .prev,
                .next {
                    width: 49px;
                    height: 49px;
                    background-color: $primary;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .tns-controls {
                gap: 2px;

                .prev,
                .next {
                    width: 49px;
                    height: 49px;
                    background-color: $primary;
                }
            }

            .compare-icon {
                display: block;
            }

            &.to-countries-navigation {
                margin-top: 5px;
            }
        }
    }

    .country-slider {
        position: relative;
        width: 100%;
        overflow-x: hidden;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .tns-item {
            .rfm_country {
                background-color: $white;
                box-sizing: border-box;
                transition: background-color 0.3s ease-out;
                height: 100%;
                display: flex;
                flex-direction: column;

                &-image {
                    padding: 10px 10px 0 10px;


                    svg {
                        use {
                            fill: #0073C0;
                            width: 100%;
                            transition: fill 0.3s ease-out;
                            -khtml-user-select: none;
                            -o-user-select: none;
                            -moz-user-select: none;
                            -webkit-user-select: none;
                            user-select: none;
                        }
                    }
                }

                &-title {
                    padding: 10px;
                    background-color: #F5F5F5;
                    transition: background-color 0.3s ease-out;

                    p {
                        transition: color 0.3s ease-out;
                        margin-bottom: 0;
                        margin-top: 0;
                        font-size: 1rem;
                        line-height: 1.25rem;
                        text-align: center;
                    }
                }
            }

            @include media-breakpoint-up(sm) {
                .rfm_country {
                    &-title {
                        p {
                            font-size: 1.125rem;
                            line-height: 1.375rem;
                        }
                    }
                }
            }

            @include media-breakpoint-only(md) {

                &.tns-slide-center {
                    .rfm_country {
                        background-color: $primary;

                        &-image {
                            svg {
                                use {
                                    fill: $white;
                                }
                            }
                        }

                        &-title {
                            background-color: $primary;

                            p {
                                color: $white;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                &.tns-slide-center {
                    .rfm_country {
                        background-color: $primary;

                        &-image {
                            svg {
                                use {
                                    fill: $white;
                                }
                            }
                        }

                        &-title {
                            background-color: $primary;

                            p {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
