.reference-slider-container {
    @extend .news-slider-container;
}

.reference-slider-navigation {
    @extend .news-slider-navigation;
}

.reference-slider-current-slide {
    @extend .news-slider-current-slide;

    .reference-slider-current {
        @extend .news-slider-current;
    }
}

.reference-slider-progress {
    @extend .news-slider-progress;
}

.stripe {
    .reference-slider-container {
        padding-bottom: 0;

        .headline {
            margin-bottom: 20px;
        }
    }
}

.reference-slider-container {
    .reference-slider {
        display: flex;
    }

    .reference-slider-button {
        padding:      0;
        width:        48px;
        height:       48px;
        border:       2px solid $rhenus-blue;
        text-align:   center;
        margin-right: 2px;

        i.material-icons {
            font-size:   2rem;
            line-height: 2.8rem;
        }
    }

    .tns-controls {
        display: none;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        button {
            font-size:        30px;
            position:         absolute;
            z-index:          2;
            top:              50%;
            width:            48px;
            height:           48px;
            margin-top:       -24px;
            text-align:       center;
            border:           none;
            @include material-icon;
            background-color: rgba($white, 0.8);

            @include media-breakpoint-down(md) {
                top:        93px;
                margin-top: 0;
            }

            @include media-breakpoint-down(sm) {
                top:        150px;
                margin-top: 0;
            }

            &:nth-child(1) {
                left: -60px;
                @include media-breakpoint-down(lg) {
                    left: 0;
                }
            }

            &:nth-child(2) {
                right: -60px;
                @include media-breakpoint-down(lg) {
                    right: 0;
                }
            }
        }
    }

    .tns-outer {
        position: relative;
    }

    .tns-inner {
        margin-right: -15px !important;
        margin-left:  -15px !important;
    }

    .reference-slide {
        position: relative;
        padding:  0 15px;

        .front {
            display:          flex;
            flex-flow:        column nowrap;
            justify-content:  space-between;
            height:           100%;
            border:           1px solid #eeeeee;
            background-color: #ffffff;

            .image-wrapper {
                width: 100%;
            }

            .slide-body {
                position:   relative;
                display:    flex;
                flex-wrap:  wrap;
                width:      100%;
                min-height: 180px;
                padding:    25px;
                @include media-breakpoint-down(md) {
                    min-height: 230px;
                }
                @include media-breakpoint-down(sm) {
                    min-height: 140px;
                }

                a {
                    display:    block;
                    align-self: flex-end;
                }

                p, h3 {
                    width: 100%;
                }
            }

        }

        .back {
            position:         absolute;
            top:              100%;
            left:             15px;
            width:            calc(100% - 30px);
            height:           100%;
            padding:          40px 25px;
            transition:       top .3s ease-in-out;
            background-color: $rhenus-gray-teaser-back;

            .slide-body {
                position: relative;

                h3 {
                    margin-bottom: 18px;
                }

                a.teaser-close {
                    position: absolute;
                    top:      -32px;
                    right:    -17px;

                    span {
                        font-size: 24px;
                        color:     #333333;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                overflow-y: auto;
            }
        }

        &.open {
            .back {
                top: 0;
            }
        }

        &.no-text {
            .front {
                .image-wrapper {
                    display:     flex;
                    align-items: center;
                    flex-wrap:   wrap;
                    margin-top:  unset;

                    .img-crossfade {
                        width: 100%;
                    }
                }

                .slide-body {
                    position:   absolute;
                    bottom:     0;
                    display:    block;
                    width:      calc(100% - 30px);
                    min-height: auto;

                    p, h3 {
                        width: 100%;
                    }
                }
            }
        }
    }

    .reference-slider-navigation {
        margin-top: 25px;
    }
}

.stripe-container.reference-teaser {
    @extend .space-after-small;
    padding-top: 200px;

    .stripe-content {
        margin-top: -280px;

        h2 {
            @extend .space-after-extra-small;
        }
    }

    @include media-breakpoint-down(md) {
        .stripe-content {
            margin-top: -255px;
        }
    }
}
