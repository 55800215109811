/**
    Styling for the Page/DataProtectionNotice partial

    The visibility of the partial is controlled by special classes, which are wrapped around the block
    .data-protection-notice at a higher level. If these classes are present, then the partial will be visible.
        - video: .no_youtube_cookie
        - googlemap: .googlemaps_no-cookie

    When adding a new class, please add it to the list above and, like previously done, define these before the
    definition of the block .data-protection-notice.
 */
 .no_youtube_cookie,
 .iframe-with-data-protection_no-cookie {
     .data-protection-notice {
         display: flex;
     }

     &:hover .data-protection-notice {
         display: flex !important;
     }
 }

 .googlemaps_no-cookie {
     max-height: 500px;

     @include media-breakpoint-up(lg) {
         max-height: unset;
     }

     .data-protection-notice {
         display:          flex;
         align-items:      flex-start;
         background-color: rgba($white, 0.85);

         @include media-breakpoint-up(lg) {
             align-items: center;
             max-height:  unset;
         }

         &__content {
             padding: 40px 20px;

             @include media-breakpoint-up(lg) {
                 padding: 40px;
             }
         }
     }
 }

 .data-protection-notice {
     @include fill-parent();

     z-index:          10;
     display:          none;
     flex-flow:        row nowrap;
     justify-content:  center;
     align-items:      center;
     text-align:       left;
     background-color: rgba($white, 0.6);

     @include media-breakpoint-up(lg) {
         background-color: unset;
     }

     &_small {
         flex-flow:       column nowrap;
         justify-content: center;
         display:         none;

         &:hover {
             @include media-breakpoint-up(lg) {
                 display:          flex;
                 background-color: rgba($white, 0.6);
             }
         }

         .data-protection-notice__content {
             padding:          10px;
             background-color: unset;
             box-shadow:       unset;
         }

         .data-protection-notice__headline {
             font-size:     12px;
             line-height:   12px;
             margin-bottom: 2.5px;
         }

         .data-protection-notice__description {
             font-size:     12px;
             line-height:   12px;
             margin-bottom: 1.5px;
         }

         .data-protection-notice__button {
             font-size:   12px;
             width:       calc(50% - 5px);
             margin-left: 10px;
             padding:     5px;
             min-height:  unset;

             &_allow {
                 margin-right: 5px;
                 margin-left:  0;
             }

             &_settings {
                 margin-top:  5px;
                 margin-left: 5px;
             }
         }
     }

     &_smaller {
         .data-protection-notice__description {
             display: none;
         }
     }

     &__content {
         width:   100%;
         padding: 20px;

         @include media-breakpoint-up(lg) {
             padding:          40px;
             max-width:        580px;
             background-color: rgba($white, 0.9);
             box-shadow:       0 2px 24px 0 rgba(0, 0, 0, 0.10);
         }
     }

     &__description {
         margin-bottom: 40px;
     }

     &__button {
         width: 100%;

         @include media-breakpoint-up(lg) {
             width: calc(50% - 10px); // 10px because of the margin-left: 20px
             // applied to the settings-button
         }

         &_allow {
             margin-right: 20px;

             @include media-breakpoint-up(lg) {
                 margin-right: 0;
             }
         }

         &_settings {
             margin-top: 20px;

             @include media-breakpoint-up(lg) {
                 margin-top:  0;
                 margin-left: 20px;
             }
         }
     }
 }

