@import "../../../variables";

/**
    Note:   The landbridge map-element and especially its containing svg-element
            has been moved to its own partial '_landbridge-map.scss'. This was
            done in order to keep this file clean, as the rules for inner-elements
            of the svg got really big and really really repetitive.
*/
@import './landbridge-map';

$fullWidthBreakpoint: 1550px;

.landbridge {
    background-color: $rhenus-blue;

    @include media-breakpoint-down(lg) {
        &[data-landbridge-form-step="finished"] {
            padding-bottom: 40px;
        }
    }

    @include media-breakpoint-up(xl) {
        @include box-aspect-ratio(16/9);

        overflow: hidden;
    }

    @media screen and (min-width: $fullWidthBreakpoint) {
        @include box-aspect-ratio(21/9);
    }
}

.landbridge {
    &[data-landbridge-form-step="connectionType"] {
        .landbridge-form-direction-selection,
        .landbridge-form-from-country-selection,
        .landbridge-form-from-city-selection,
        .landbridge-form-to-country-selection,
        .landbridge-form-to-city-selection {
            display: none !important;
        }
    }

    &[data-landbridge-form-step="direction"] {
        .landbridge-form-from-country-selection,
        .landbridge-form-from-city-selection,
        .landbridge-form-to-country-selection,
        .landbridge-form-to-city-selection {
            display: none !important;
        }
    }

    &[data-landbridge-form-step="fromCountry"] {
        .landbridge-form-from-city-selection,
        .landbridge-form-to-country-selection,
        .landbridge-form-to-city-selection,
        .landbridge-contact-modal {
            display: none !important;
        }
    }

    &[data-landbridge-form-step="fromCity"] {
        .landbridge-form-to-country-selection,
        .landbridge-form-to-city-selection,
        .landbridge-contact-modal {
            display: none !important;
        }
    }

    &[data-landbridge-form-step="toCountry"] {
        .landbridge-form-to-city-selection,
        .landbridge-contact-modal {
            display: none !important;
        }
    }

    &[data-landbridge-form-step="toCity"] {
        .landbridge-contact-modal {
            display: none !important;
        }
    }
}

.landbridge-form {
    padding:          40px 0;
    background-color: $rhenus-blue;

    .container {
        margin-top: 80px;

        @media screen and (min-width: $fullWidthBreakpoint) {
            max-width:     495px;
            margin-right:  0;
            padding-right: 95px;
        }
    }

    .form-group.row {
        margin: 24px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-top:    40px;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            margin: 40px 0;
        }

        .col-form-label {
            font-size:      16px;
            font-weight:    700;
            line-height:    24px;
            margin-bottom:  12px;
            padding:        0 15px;
            letter-spacing: 0;
            text-transform: uppercase;
            color:          $white;

            @include media-breakpoint-up(xl) {
                margin-bottom: 24px;
            }
        }
    }

    .landbridge-form-direction,
    .landbridge-form-connection-type {
        width:       100%;
        text-align:  center;
        display:     flex;
        flex-flow:   row nowrap;
        align-items: center;
        line-height: 1;
        gap:         5px;

        @media screen and (min-width: $fullWidthBreakpoint) {
            width: auto;
        }

        .material-icons {
            font-size:   22px;
            cursor:      pointer;
            line-height: unset;

            @media screen and (min-width: $fullWidthBreakpoint) {
                position:     relative;
                top:          6px;
                margin-right: 19px;
                margin-left:  19px;
                transform:    rotateZ(0deg);
            }

            &.deactivated {
                cursor: default;
            }

            &#landbridge-form-connection-type-toggle-icon {
                cursor:  default;
                opacity: 0;
            }
        }

        a {
            display:     inline-block;
            width:       100%;
            padding:     15px 0;
            transition:  box-shadow .2s ease-in-out;
            border:      1px solid $white;
            line-height: unset;
            min-height:  unset;

            @media screen and (min-width: $fullWidthBreakpoint) {
                display: inline;
            }

            &.active, &:hover, &:focus, &:active {
                color:      $rhenus-blue-secondary;
                background: $rhenus-white-alt;
                box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.50);
            }

            &.active {
                cursor: default;
            }
        }
    }

    .dropdown {
        /**
            Note:
                The colour of the "selected item" label will be
                $rhenus-blue-dark. But, if this label is still
                the default value, e.g. "Select Country", or the
                dropdown menu is open, the labels colour will be
                $rhenus-content-alert-background-alt.
         */
        &.show,
        &[data-landbridge-form-selected-value="-1"] {
            .dropdown-toggle {
                color: $rhenus-content-alert-background-alt;
            }
        }

        .dropdown-toggle {
            padding-top:    12px;
            padding-bottom: 12px;
            color:          $rhenus-blue-dark;
            border:         0;

            &:active, &:focus, &:hover {
                background: $white;
                box-shadow: none !important;
            }

            .selected-value {
                color: $rhenus-blue;
            }

            .material-icons {
                font-size:   24px;
                font-weight: 400;
                line-height: 24px;
                position:    absolute;
                right:       15px;
            }

            &::after {
                display: none;
            }
        }
    }

    .dropdown-menu {
        position:    absolute;
        top:         0;
        left:        0;
        margin-top:  0;
        cursor:      pointer;
        transform:   translate3d(0, 50px, 0);
        border:      0;
        will-change: transform;

        &::before {
            position:   absolute;
            top:        0;
            right:      4px;
            left:       4px;
            height:     1px;
            content:    "";
            background: $rhenus-content-alert-background-alt;

            @include media-breakpoint-up(lg) {
                right: 0;
                left:  0;
            }
        }

        &[x-placement="top-start"] {
            &::before {
                top:    unset;
                bottom: 0;
            }
        }

        .dropdown-menu__item {
            @extend .btn;
            @extend .btn-light;
            @extend .rounded-0;
            @extend .d-inline-flex;
            @extend .align-items-center;

            width:          calc(100% - 8px);
            height:         100%;
            margin-left:    4px;
            padding-top:    12px;
            padding-bottom: 12px;
            color:          $rhenus-gray-dark;
            border:         0;
            border-top:     1px solid #d2d8db;
            border-bottom:  0 solid #d2d8db;
            background:     $white;

            @include media-breakpoint-up(lg) {
                width:       100%;
                margin-left: 0;
            }

            &:first-child {
                border-top-width: 0;
            }

            &:hover, &:active, &:focus {
                color:      $rhenus-blue;
                background: inherit;
                box-shadow: none;
            }
        }

        .dropdown-menu__item_hidden {
            display: none !important;
        }

        &[x-placement="top-start"] .dropdown-menu__item {
            border-top-width:    0;
            border-bottom-width: 1px;

            &:last-child {
                border-bottom-width: 0;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        position:   absolute;
        top:        0;
        bottom:     0;
        left:       0;
        overflow-y: auto;
        width:      30%;

        .container {
            width: 100%;
        }
    }

    .reset-form-action {
        font-size:  16px;
        position:   relative;
        width:      100%;
        height:     24px;
        color:      #979797;
        transition: all .33s ease-out;
        transform:  translateX(-175px);
        opacity:    0;

        &:hover {
            cursor:          not-allowed;
            text-decoration: none;
            color:           #979797;
        }

        &.reset-form-action_active {
            color:     $white;
            transform: unset;
            opacity:   1;

            &:hover {
                cursor:          pointer;
                text-decoration: underline;
            }
        }

        i.material-icons {
            font-size:    22px;
            float:        left;
            margin-right: 5px;
        }
    }
}

.landbridge-contact-modal {
    display:          none;
    margin:           0 15px;
    padding:          24px 15px 40px;
    background-color: $white;

    &.show {
        display: block
    }

    @include media-breakpoint-up(xl) {
        position:   absolute;
        bottom:     0;
        left:       30%;
        width:      100%;
        max-width:  635px;
        padding:    64px 40px 40px;
        margin:     0 30px;
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.10);
    }
}

.landbridge-contact-modal-item {
    padding-top: 16px;

    &.landbridge-contact-modal-item_type {
        font-weight:    400;
        text-transform: uppercase;
        color:          $rhenus-blue-secondary;
    }

    &.landbridge-contact-modal-item_from-to {
        margin-left: -3px;

        @include media-breakpoint-down(lg) {
            display:     flex;
            flex-flow:   row nowrap;
            align-items: center;
        }

        div.h3 {
            margin-left: 30px;

            @include media-breakpoint-down(lg) {
                font-size:     16px;
                line-height:   24px;
                margin-bottom: 0;
            }
        }

        .material-icons {
            font-size:   24px;
            line-height: 32px;
            float:       left;
        }
    }

    &.landbridge-contact-modal-item_schedule-information {
        font-weight: bold;
        color:       #404146;

        > div:first-child {
            margin-bottom: 9px;
        }

        .material-icons {
            position: relative;
            top:      2px;
            width:    50px;
        }

        @include media-breakpoint-up(xl) {
            & > div {
                display:      inline-block;
                margin-right: 2rem;

                &:first-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.landbridge-contact-modal-item_description {
        padding-top: 24px;
        color:       #404146;
    }

    &.landbridge-contact-modal-item_submit-btn {
        @include media-breakpoint-down(md) {
            .button-ghost {
                padding:    15px 0;
                width:      100%;
                text-align: center;
            }
        }
    }
}

.landbridge-contact-modal-action {
    display: none;

    @include media-breakpoint-up(xl) {
        position: absolute;
        top:      15px;
        right:    28px;
        left:     28px;
        display:  block;
    }

    a {
        position:   absolute;
        top:        0;
        right:      0;
        left:       0;
        display:    block;
        height:     32px;
        text-align: right;

        &, &:hover, &:active, &:focus {
            text-decoration: none;
        }
    }

    span {
        font-weight: 400;
        position:    relative;
        top:         -10px;
        left:        -15px;
        display:     none;
        color:       $rhenus-blue-secondary;

        .landbridge-contact-modal.minimized &.landbridge-contact-modal-action__open,
        .landbridge-contact-modal:not(.minimized) &.landbridge-contact-modal-action__close {
            display: inline-block;
        }
    }

    i.material-icons {
        font-size:   32px;
        line-height: 32px;
        width:       32px;
        height:      32px;
        color:       $rhenus-blue-secondary;

        .landbridge-contact-modal.minimized & {
            @include pulse-animation(2s);
        }
    }
}
