@import "../variables";

.breadcrumb-container {
    margin: 0 0 40px;
    color:  $rhenus-blue-dark;

    @include media-breakpoint-down(lg) {
        display: none;
    }

    a {
        color: $rhenus-gray-light;
    }

    span {
        font-size:      25px;
        margin-right:   -3px;
        vertical-align: -6px;
    }

    .home-link {
        font-size:      24px;
        margin-right:   5px;
        vertical-align: -5px;
    }
}
.bilgenentoelung{
    .breadcrumb-container{
        color: #ffcc00;
    }
}

.breadcrumb-container-mobile {
    display: none;
    padding: 0 0 40px;

    @include media-breakpoint-down(lg) {
        display: block;
    }

    .page-type-news_detail_new & {
        padding: 40px 0 0;
    }

    .material-icons {
        font-size:    18px;
        margin-right: 12px;
        transform:    translateY(3px);
    }
}
