@import 'cookieconsent/src/styles/animation.css';
@import 'cookieconsent/src/styles/base.css';
@import 'cookieconsent/src/styles/layout.css';
@import 'cookieconsent/src/styles/media.css';
@import 'cookieconsent/src/styles/themes/classic.css';
@import 'cookieconsent/src/styles/themes/edgeless.css';

.cookieconsent {
    > .cc-window {
        background: $gray-200;
    }
}
