.career-intro{
    background-color: $rhenus-blue-light;
    padding: 5rem 0px;
    z-index: 2;
    position: relative;
    .career-intro-text{
        background-size: cover;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        margin-bottom: 5rem;

        .h1{
            font-size: 120px;
            line-height: 1;
            display: inline;

            @include media-breakpoint-down(md){
                font-size: 60px;
            }
        }
    }
    .career-intro-text-background-image-color{
        background-color: rgba(0,70,155,.8);
        background-clip: text;
        -webkit-background-clip: text;
        background-clip: text;
    }

    .career-intro-numbers{
        margin-bottom: 5rem;
        font-size: 44px;
        span{
            color: $rhenus-blue;
            font-size: 52px;
            font-weight: bold;
        }
    }

    .powermail_fieldwrap .mdc-text-field {
        max-width: 760px;
        @include media-breakpoint-up(lg){
            margin-bottom: 10px;
        }
    }


    form[name="tx_rscw_concludis"]{
        input[type="submit"]{
            min-height: 56px;
            position: relative;
            width: 100%;
        }

        i.material-icons{
            position: absolute;
            top: 16px;
            left: 8px;
            background: 0 0;
            font-size: 24px;
            transition: all 0.15s ease-in-out;
        }

        #career-search-string{
            padding-left: 36px;
            &+label:not(.mdc-floating-label--float-above){
                padding: 20px;
            }
            &+label.mdc-floating-label--float-above{
                &+i.material-icons{
                    top: 24px;
                }
            }
        }

        @include media-breakpoint-down(lg){
            .powermail_fieldwrap .mdc-text-field{
                max-width: 100%;
            }
        }
    }
}

// DESK-6622
#c27461 h1 {
    @extend h2;
}
