.country_switch {
    position: fixed;
    top: 120px;
    width: 100%;
    height: auto;
    background-color: #85959DF2;
    z-index: 201;
    color: #fff;
    padding: 18px 0;
    transition: top .4s ease-out;

    &.hidden {
        display: none;
    }

    &.small-navi {
        top: 66px;
    }

    .country_switch_buttons {
        display: flex;
        flex-wrap: wrap;
        .button{
            line-height: 1.2;
            padding: 12px 20px 10px;
            &:first-child {
                margin-right: 8px;
            }
        }
        .rhenus-worldwide {
            width: max-content;
        }
    }
    .country_switch_text {
        display: flex;
        flex-flow: row;
        i {
            font-size: 20px;
            margin-right: 12px;
            transform: translateY(2px);
        }
        p {
            margin-bottom: 0;
        }
        #country-switch-country-title {
            font-weight: bold;
        }
        @include media-breakpoint-down(lg) {
            padding-right: 28px;
        }
    }

    .close-countryswitch {
        position: absolute;
        right: -24px;
        top: 0;
        margin-right: 15px;
        cursor: pointer;

        i {
            font-size: 24px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .country_switch {
        top: 48px;
        .country_switch_buttons {
            .button {
                padding: 12px 24px 8px;
            }
        }

        &.small-navi {
            top: 48px;
        }
    }
}
@include media-breakpoint-down(md) {
    .country_switch {
        .country_switch_buttons {
            flex-direction: column;
            align-items: center;
            margin-top: 24px;
            .button {
                &:first-child {
                    margin-right: 0;
                    margin-bottom: 12px;
                }
            }
        }

        &.small-navi {
            top: 48px;
        }
    }
}
@include media-breakpoint-down(sm) {
    .country_switch {
        .country_switch_buttons {
            .button {
                display: block;
                width: 100%;
                text-align: center;
            }
            .rhenus-worldwide, .country_switch-button {
            width: 100%;
            }
        }

        &.small-navi {
            top: 48px;
        }
    }
}
@include media-breakpoint-only(lg) {
    .country_switch {
        .country_switch_buttons {
            .button {
                padding: 10px 12px 8px;
                min-height: 44px;
            }
        }
    }
}
@include media-breakpoint-only(md) {
    .country_switch {
        .country_switch_buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: row;

            .country_switch-button {
                margin-right: 16px;
            }
        }
    }
}
