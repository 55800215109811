@import "../variables";

$sidebarCtaContainerBreakpoint:                   (
                                                      "tablet": sm,
                                                      "desktop": lg
                                                  );

$sidebarCtaContainerDistanceToBootstrapContainer: 40px;

.sidebar-cta-container {
    &:not(.close-share-popover-container) {
        position:       fixed;
        z-index:        300;
        right:          0;
        bottom:         0;
        left:           0;
        display:        flex;
        flex-direction: row;

        @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "tablet")) {
            justify-content: flex-end;
        }

        @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
            right:          0;
            left:           unset;
            bottom:         calc(50% - 81px); // 162px is the height of the sidebar with 2 button
            flex-direction: column;
            z-index:        310;
            opacity:        0;
            transition:     opacity .2s ease-out;
        }
    }

    &__spacer {
        display: none;
    }

    /**
        Uncomment this, and the cta-buttons with icon will be displayed horizontally on mobile & tablet

    &[data-sidebar-cta-container-item-count="1"],
    &[data-sidebar-cta-container-item-count="2"] {
        @include media-breakpoint-down(md) {
            .cta-button {
                flex-direction: row;
                gap:            10px;

                &_road-freight-tool {
                    span {
                        font-size:   11px;
                        line-height: 12px;
                    }

                    .road_freight_icon {
                        width:  24px;
                        height: 24px;
                    }
                }

                &_lead-cta {
                    font-size:   11px;
                    line-height: 12px;

                    i.material-icons {
                        font-size:   24px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    **/

    .cta-button {
        font-size:       9px;
        line-height:     12px;
        display:         flex;
        align-items:     center;
        flex-basis:      0;
        flex-direction:  column;
        flex-grow:       1;
        justify-content: center;
        height:          50px;
        transition:      box-shadow 0.2s ease-out, width 0.2s ease-out, height 0.2s ease-out;
        text-align:      center;
        border-top:      1px solid $white;
        gap:             4px;

        &:hover, &:focus, &:active {
            text-decoration: none
        }

        &:not(:last-child) {
            border-right: 1px solid $white;
        }

        &:first-of-type {
            @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "tablet")) {
                border-left: 1px solid $white;
            }

            @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
                border-left: none;
            }
        }

        @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "tablet")) {
            flex-basis: unset;
            flex-grow:  unset;
            padding:    0 15px;
        }

        @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
            flex-direction: column;
            width:          80px;
            height:         80px;
            margin-bottom:  2px;
            padding:        15px 5px;
            border-right:   none;
            box-shadow:     0 1px 2px rgba($black, .12), 0 1px 2px rgba($black, .24);
            transition:     box-shadow 0.3s cubic-bezier(.25, .8, .25, 1);
            border-top:     unset;
            font-size:      11px;
            gap:            unset;

            &:not(:last-child) {
                border-right: none;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                box-shadow: 0 0 10px rgba($black, .25), 0 10px 10px rgba($black, .22);
            }
        }

        &_lead-cta {
            color:            $white;
            background-color: $rhenus-blue;
        }

        &_road-freight-tool {
            color:            $rhenus-gray-dark;
            background-color: $rhenus-blue-light;

            .road_freight_icon {
                width:  20px;
                height: 20px;
                filter: contrast(0.5);

                @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
                    width:     unset;
                    height:    unset;
                    max-width: 24px;
                }
            }
        }

        i.material-icons {
            font-size:   20px;
            line-height: 20px;

            @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
                font-size:   24px;
                line-height: 24px;
            }
        }

        span {
            @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "tablet")) {
                white-space: nowrap;
            }

            @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
                padding:     7px 0 0;
                white-space: unset;
            }
        }

        svg {
            width:         20px;
            height:        20px;
            fill:          #fff;
            margin:        0;
            margin-bottom: 0;
            margin-top:    0;

            @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
                width:  24px;
                height: 24px;
            }

            .st0 {
                fill: #fff;
            }
        }
    }
}

.bilgenentoelung {
    .sidebar-cta-container {
        .cta-button_lead-cta {
            color:            $rhenus-gray-dark;
            background-color: #ffcc00;
        }
    }
}

.rheintank {
    .sidebar-cta-container {
        .cta-button_lead-cta {
            color:            #fff;
            background-color: #003366;
        }
    }
}
