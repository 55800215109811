.download-list{
    .filters{
        .custom-radio-checkbox.checkbox .checkmark{
            background-color: transparent;
        }
        .custom-radio-checkbox.checkbox input:checked~.checkmark {
            background-color: #00469B;
        }
        .form-check{
            padding-left: 0px;
        }
    }

    .download{
        a{
            display: block;
            width: 100%;    
            i{
                font-size: 1.5rem;
                float: left;
                margin-right: 5px;
            }
            .title{
                line-height: 1.2;
            }
        }
        .preview{
            a{
                &:hover{
                    opacity: 0.5;
                }
            }
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    .downloads-table{
        tbody,tr,td{
            display: block;
            width: 100%;
            padding: 0px;
        }
        tbody{
            &.teaser{
                @include media-breakpoint-up(md) { 
                    margin-left: -15px;
                    margin-right: -15px;
                    display: flex;
                    flex-wrap: wrap;
                    tr{
                        width: 50%;
                        float: left;
                        padding: 0px 15px;
                        td,.download{
                            height: 100%;
                        }
                        .download{
                            display: flex;
                            flex-wrap: wrap;
                            .download-footer{
                                width: 100%;
                                align-self: flex-end;
                            }
                        }
                    }
                }
            }
        }
        tr{
            margin-bottom: 1rem;
            background-color: transparent;
        }
    }
    .dataTables_filter{
        display: none;
    }
    .results{
        text-transform: uppercase;
        .count{
            font-weight: 700;;
        }
    }
}