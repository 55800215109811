.tx-solr-autocomplete, .last-searches-block, .last-search-suggestions-container {
  .tx-solr-autosuggest {
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin-top: 1px;
    padding-bottom: 0;
    color: $rhenus-blue;
    box-shadow: $box-shadow;
    border: none;

    &.custom-size-suggest {
      width: 1110px;
    }

    @include media-breakpoint-down(sm) {
      max-width: calc(100% - 60px) !important;
      max-height: calc(100vh - 96px) !important;
      border: none;
      overflow-y: scroll;
      margin-top: -71px;
      .solr-mobile-search-button {
        position: sticky;
        bottom: 0;
      }
    }
  }

  .autocomplete-group {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 10px 20px;
    text-transform: uppercase;
    color: $rhenus-gray-dark;
    background-color: $rhenus-blue-accordion-header;
  }

  .autocomplete-suggestion {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    cursor: pointer;
    color: $rhenus-blue;
    white-space: normal;

    &:before {
      @include material-icon;
      font-size: 24px;
      margin-right: 0.5rem;
      content:    "search";
    }

    &.last-search-suggestion {
      &:before {
        content: "schedule";
      }
    }

    strong {
      color: $rhenus-blue;
      line-height: 1.5rem;
      position: relative;
      bottom: 1px;
    }

    em {
      font-style: normal;
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      &,
      a {
        text-decoration: none;
        background-color: #E6F1F8;
      }
    }
  }
}

.solr-searchbar-container{
  z-index: 5;
  top: -50px;
  position: relative;
  display: none;
  .tx_solr{
    background-color: transparent;
    .solr-searchbar{
      background-color: transparent;
      .solr-searchbar__search-div{
        width: 90%;
        input {
          margin-right: 0.5rem;
        }
      }
    }
    .navi-flyout-close {
      font-size: 24px;
      position:  absolute;
      z-index:   3;
      top:       7px;
      right:     -30px;
      cursor:    pointer;
      color: #fff;
    }
  }
}
.navigation-open-search:not(.navigation-open-without-search){
  .solr-searchbar-container{
    display: block;
  }
}

.tx-solr-search-form-mobile {
  #solr-searchbar-input-mobile {
    font-weight:   normal;
    min-height:    3rem;
    margin-right:  0;
    padding-left:  0;
    border:        none;
    border-bottom: 1px solid $rhenus-gray;
    border-radius: 0;
    box-shadow:    none;
  }

  i {
    font-size:      24px;
    margin-right:   8px;
    vertical-align: -9px;
    color:          $rhenus-gray-dark;
    border-color:   #d2d8db;
  }

  .cancel-search-btn {
    padding:          0;
    border:           0;
    border-bottom:    1px solid $rhenus-gray;
    background-color: #ffffff;
  }
}
