.main-footer {
    background-color: $rhenus-blue;
    color:            $white;
    min-height:       180px;
    display:          flex;
    align-items:      center;

    &.footer-grey {
        background-color: $rhenus-gray-form-back;

        a, .ot-sdk-show-settings {
            color: $rhenus-gray-light;
        }
    }

    @include media-breakpoint-down(sm) {
        & > .container {
            padding-left:  30px;
            padding-right: 30px;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 40px 0;
    }

    .container {
        position: relative;
    }

    a, .ot-sdk-show-settings {
        color:   $white;
        display: inline-block;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    #ot-sdk-btn, #ot-sdk-btn-mobile {
        &.ot-sdk-show-settings {
            border:      none !important;
            color:       white !important;
            font-size:   1rem !important;
            line-height: 1.5rem !important;
            padding:     0 !important;
            margin-top:  24px;
            margin-right: 32px;

            &:hover {
                background-color: transparent !important;
                text-decoration:  underline;
            }
        }
    }

    &.footer-grey {
        background-color: $rhenus-gray-form-back;
        color:            $rhenus-gray-light !important;

        #ot-sdk-btn.ot-sdk-show-settings {
            color:       $rhenus-gray-light !important;
            margin-left: 32px;
            margin-top:  0;
        }

        a, .ot-sdk-show-settings {
            @include media-breakpoint-down(md) {
                display: inline;
            }
        }
    }

    .footer-right {
        text-align: right;
        position:   static;

        @include media-breakpoint-down(md) {
            text-align: left;
        }
    }

    .footer-social-link-container {
        position: absolute;
        top:      -45px;
        right:    15px;

        @include media-breakpoint-down(md) {
            position: relative;
            right:    auto;
            bottom:   auto;
            top:      0;
        }
    }


    .main-footer-navi + .meta-navi {
        .footer-social-link-container {
            top: 0;
        }
    }


    .social-link {
        height:              40px;
        width:               40px;
        display:             inline-block;
        background-position: center center;
        background-repeat:   no-repeat;
        background-size:     contain;
        margin:              -8px 0 0 24px;

        @include media-breakpoint-down(md) {
            margin: 32px 24px 0 0;
        }
    }

    .social-youtube {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-youtube.svg');
    }

    .social-linkedin {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-linkedin.svg');
    }

    .social-facebook {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-facebook.svg');
    }

    .social-twitter {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-twitter.svg');
    }

    .social-xing {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-xing.svg');
    }

    .social-kununu {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-kununu.svg');
    }

    .social-spotify {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-spotify.svg');
    }

    .social-apple_podcast {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-apple_podcast.svg');
    }

    .social-instagram {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-instagram.svg');
    }

    .social-wechat {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/icon-wechat.svg');
    }

    .social-qrcode {
        background-image: url('$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/qr-code-icon.svg');
    }

    .footer-copyright {
        margin-top: 24px;
    }

    .main-footer-navi {
        .footer-left {
            @include media-breakpoint-up(lg) {
                min-height: 48px;
            }
        }

        a {
            margin-right:   40px;
            text-transform: uppercase;
            margin-bottom:  24px;

            @include media-breakpoint-only(lg) {
                margin-right: 20px;
            }
        }
    }

    .meta-navi {
        border-top: 1px solid rgba(255, 255, 255, 0.5);

        .footer-left {
            a {
                margin-top:   24px;
                margin-right: 32px;
            }
        }
    }

    &_career-footer-disclaimer-shown {
        @include media-breakpoint-down(lg) {
            padding: 40px 0;
        }

        .career-footer-disclaimer {
            padding: 0 0 24px;
        }

        .main-footer-navi + .meta-navi {
            .footer-social-link-container {
                @include media-breakpoint-up(lg) {
                    top: 10px;
                }
            }
        }
    }
}

.bilgenentoelung, .rheintank {
    .main-footer {
        background-color: #6e6e6e;
    }
}
