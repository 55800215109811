$rhenus-blue:                         #0099a1;
$rhenus-orange:                       #94d7de;
$rhenus-blue-dark:                    #00747a;
$rhenus-blue-light:                   #cee7e7;
$rhenus-blue-lighter:                 #e9f1f7;

$rhenus-gray:                         #cfcfd1;
$rhenus-gray-dark:                    #4d4d4d;
$rhenus-very-dark-gray:               #404146;
$rhenus-gray-light:                   #727272;
$rhenus-gray-teaser-back:             #f9f9f9;
$rhenus-gray-form-back:               #f5f5f5;
$rhenus-orange-primary:               #e99005;
$rhenus-blue-secondary:               #31aeb5;
$rhenus-blue-secondary-80:            rgba($rhenus-blue-secondary,.8);
$rhenus-blue-secondary-dark:          #006b70;
$rhenus-blue-focus-border:            #63c7c7;
$rhenus-blue-accordion-header:        #e1e9f3;
$rhenus-white-alt:                    #fefefe;
$rhenus-section-grey:                 #e7e7e8;

$rhenus-form-error-red:               #b00020;
$rhenus-form-error-background:        #ffe6e6;
$rhenus-content-alert-background:     #84959d;
$rhenus-content-alert-background-alt: #85959d;
$rhenus-content-alert-background-alt-01: rgba(133, 149, 157, .1);
$primary:                             $rhenus-blue;
$secondary:                           $rhenus-orange;
$body-color:                          $rhenus-gray-dark;

$text-line-height:                    $font-size-base * 1.5;

@mixin h1-font-attributes() {
    @include media-breakpoint-up(md) {
        font-size:   $font-size-base * 2.5;
        line-height: $font-size-base * 3;
    }
    @include media-breakpoint-down(sm) {
        font-size:   $font-size-base * 2;
        line-height: $font-size-base * 2.5;
    }
}

@mixin h2-font-attributes() {
    @include media-breakpoint-up(md) {
        font-size:   $font-size-base * 2;
        line-height: $font-size-base * 2.5;
    }
    @include media-breakpoint-down(sm) {
        font-size:   $font-size-base * 1.5;
        line-height: $font-size-base * 2;
    }
}

@mixin h3-font-attributes() {
    font-size:   $font-size-base * 1.5;
    line-height: $font-size-base * 2;
}

@mixin h4-font-attributes() {
    font-size:   $font-size-base;
    line-height: $font-size-base * 1.5;
}

@mixin h5-font-attributes() {
    font-size:   $font-size-base;
    line-height: $font-size-base * 1.5;
}

@mixin h6-font-attributes() {
    font-size:   $font-size-base;
    line-height: $font-size-base * 1.5;
}
