.tabbed-contentbox-container {
    position:   relative;
    min-height: 710px;

    @include media-breakpoint-down(sm) {
        min-height: 0;
    }

    .tabbed-contentbox {
        width:            33%;
        background-color: $white;

        @include media-breakpoint-down(lg) {
            width: 40%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .tabbed-contentbox-intro {
        height:        260px;
        padding-right: 24px;

        @include media-breakpoint-down(sm) {
            height:        auto;
            padding-right: 0;
        }

        @include media-breakpoint-only(md) {
            padding-right: 32px
        }

        .headline {
            @include media-breakpoint-down(md) {
                font-size:   28px;
                line-height: 36px;
            }

            font-weight:    bold;
            margin-top:     0;
            margin-bottom:  24px;
            text-transform: uppercase;

            @include media-breakpoint-only(md) {
                margin-bottom: 15px;
                font-size:     2rem;
                line-height:   2.5rem;
            }

        }

        p {
            margin-top:    0;
            margin-bottom: 40px;

            @include media-breakpoint-up(md) {
                margin-bottom: 64px;
            }
        }
    }

    .tabbed-contentbox {
        .accordion-box {
            .accordion-content, .accordion-contentbox {
                a:not(.button-ghost) {
                    color: #fff;
                }
            }
        }
    }

    .tabbed-contentbox-accordion {
        figure {
            position:            absolute;
            width:               67%;
            height:              calc(100% - 1px);
            top:                 0;
            right:               0;
            bottom:              0;
            background-position: center top;
            background-repeat:   no-repeat;
            background-size:     cover;
            display:             block;
            overflow:            hidden;

            img {
                height:     100%;
                min-height: 100%;
                width:      auto;
                min-width:  100%;
                position:   absolute;
                right:      0;
                display:    none;
                object-fit: cover;

                &.image-desktop-big {
                    @include media-breakpoint-up(xl) {
                        display: block
                    }
                }

                &.image-desktop {
                    @include media-breakpoint-only(lg) {
                        display: block
                    }
                }

                &.image-tablet {
                    @include media-breakpoint-only(md) {
                        display: block
                    }
                }

                &.image-mobile {
                    @include media-breakpoint-down(sm) {
                        display: block
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                width: 60%;
            }

            @include media-breakpoint-down(sm) {
                width:   100%;
                z-index: 1;
            }
        }

        .accordion-box-trigger {
            color:            $white;
            font-weight:      bold;
            height:           60px;
            display:          flex;
            flex-wrap:        wrap;
            align-items:      center;
            padding:          0 16px;
            cursor:           pointer;
            background-color: $rhenus-blue;
            border:           none;

            @include media-breakpoint-only(md) {
                padding:   0 14px;
                font-size: 14px;
            }

            @include media-breakpoint-down(sm) {
                padding:  0 20px;
                position: relative;
                z-index:  1;

                &:after {
                    content:    "expand_less";
                    @include material-icon;
                    display:    inline-block;
                    position:   absolute;
                    font-size:  32px;
                    top:        50%;
                    margin-top: -15px;
                    right:      16px;
                    transition: 300ms ease;
                }
            }

            strong {
                font-weight: bold;
                padding:     18px 0 20px;
                line-height: 1rem;
            }

            .svg {
                height:       27px;
                line-height:  27px;
                width:        30px;
                margin-right: 16px;

                * {
                    fill: $white;
                }

                @include media-breakpoint-only(md) {
                    height:       20px;
                    margin-right: 10px;
                }
            }

            .rhenus-icon-container {
                img {
                    margin-top: -40px;
                }
            }
        }

        .accordion-box {
            @include media-breakpoint-down(sm) {
                position: relative;
            }

            &:first-child:before {
                position:    relative;
                z-index:     10;
                left:        0;
                display:     block;
                width:       100%;
                padding-top: 1px;
                content:     "";
                background:  $rhenus-gray;
            }

            &:after {
                content:          "";
                display:          block;
                left:             0;
                width:            100%;
                height:           1px;
                background-color: $rhenus-gray;
            }

            .collapsed {
                .accordion-box-trigger {
                    color:            $rhenus-blue;
                    background-color: $white;

                    &:after {
                        transform: rotate(180deg);
                    }

                    .svg {
                        * {
                            fill: $rhenus-blue;
                        }
                    }

                    .rhenus-icon-container {
                        img {
                            margin-top: 0px;
                        }
                    }
                }

                figure,
                .accordion-contentbox {
                    display: none;
                }
            }

            .img-crossfade {
                position: absolute;
                top:      0;
                left:     0;
                bottom:   0;
                right:    0;
            }
        }

        .accordion-contentbox {
            position:   absolute;
            display:    block;
            width:      500px;
            top:        260px;
            left:       33%;
            bottom:     0;
            background: $rhenus-blue;
            background: linear-gradient(315deg, transparent 40px, rgba($rhenus-blue, 0.8) 40px);
            padding:    40px;
            box-sizing: border-box;
            color:      $white;

            @include media-breakpoint-down(lg) {
                left:    40%;
                padding: 25px;
            }

            @include media-breakpoint-only(md) {
                width: auto;
                right: 0;
            }

            @include media-breakpoint-down(sm) {
                width:      100%;
                position:   relative;
                top:        0;
                left:       0;
                padding:    32px 20px;
                z-index:    2;
                background: rgba($rhenus-blue, 0.8);
            }

            .h3 {
                margin-bottom: 16px;
            }

            p {
                margin-bottom: 0;
            }

            ul {
                margin: 16px 0 16px;
            }

            .button-ghost {
                margin-top: 16px;

                @include media-breakpoint-up(md) {
                    position: absolute;
                    left:     25px;
                    bottom:   25px;
                }

                @include media-breakpoint-up(xl) {
                    left:   40px;
                    bottom: 40px;
                }
            }
        }
    }

    .tabbed-contentbox-desktop {
        figure {
            position:            absolute;
            width:               67%;
            height:              100%;
            top:                 0;
            right:               0;
            bottom:              0;
            background-position: center top;
            background-repeat:   no-repeat;
            background-size:     cover;
            display:             none;
            overflow:            hidden;

            img {
                height:     100%;
                min-height: 100%;
                width:      auto;
                min-width:  100%;
                position:   absolute;
                right:      0;
                display:    none;

                &.image-desktop-big {
                    @include media-breakpoint-up(xl) {
                        display: block
                    }
                }

                &.image-desktop {
                    @include media-breakpoint-only(lg) {
                        display: block
                    }
                }

                &.image-tablet {
                    @include media-breakpoint-only(md) {
                        display: block
                    }
                }

                &.image-mobile {
                    @include media-breakpoint-down(sm) {
                        display: block
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                width: 60%;
            }

            @include media-breakpoint-down(sm) {
                width:   100%;
                z-index: 1;
            }
        }

        .accordion-box-trigger {
            color:       $rhenus-blue;
            font-weight: bold;
            height:      60px;
            display:     flex;
            flex-wrap:   wrap;
            align-items: center;
            padding:     0 16px;
            cursor:      pointer;

            @include media-breakpoint-only(md) {
                padding:   0 14px;
                font-size: 14px;
            }

            @include media-breakpoint-down(sm) {
                padding:  0 20px;
                position: relative;
                z-index:  1;

                &:after {
                    content:    "expand_more";
                    @include material-icon;
                    display:    inline-block;
                    position:   absolute;
                    font-size:  32px;
                    top:        50%;
                    margin-top: -15px;
                    right:      16px;
                }
            }

            strong {
                font-weight: bold;
                padding:     18px 0 20px;
                line-height: 1rem;
            }

            .svg {
                height:       27px;
                line-height:  27px;
                width:        30px;
                margin-right: 16px;

                * {
                    fill: $rhenus-blue;
                }

                @include media-breakpoint-only(md) {
                    height:       20px;
                    margin-right: 10px;
                }
            }

        }

        .accordion-box {
            @include media-breakpoint-down(sm) {
                position: relative;
            }

            &:after {
                content:          "";
                display:          block;
                left:             0;
                width:            98%;
                height:           1px;
                background-color: $rhenus-gray;
            }

            &.active {
                .accordion-box-trigger {
                    color:            $white;
                    background-color: $rhenus-blue;
                    border:           none;

                    @include media-breakpoint-down(sm) {
                        &:after {
                            content: "expand_less";
                        }
                    }

                    .svg {
                        * {
                            fill: $white;
                        }
                    }

                    .rhenus-icon-container {
                        img {
                            margin-top: -40px;
                        }
                    }
                }

                figure,
                .accordion-content {
                    display: block;
                }
            }

            .img-crossfade {
                position: absolute;
                top:      0;
                left:     0;
                bottom:   0;
                right:    0;
            }
        }

        .accordion-content {
            position:   absolute;
            display:    none;
            width:      500px;
            top:        260px;
            left:       33%;
            bottom:     0;
            background: $rhenus-blue;
            background: linear-gradient(315deg, transparent 40px, rgba($rhenus-blue, 0.8) 40px);
            padding:    40px;
            box-sizing: border-box;
            color:      $white;

            .h3 {
                text-transform: uppercase;
                margin-bottom:  16px;
            }

            @include media-breakpoint-down(lg) {
                left:    40%;
                padding: 25px;
            }

            @include media-breakpoint-only(md) {
                width: auto;
                right: 0;
            }

            @include media-breakpoint-down(sm) {
                width:      100%;
                position:   relative;
                top:        0;
                left:       0;
                padding:    32px 20px;
                z-index:    2;
                background: rgba($rhenus-blue, 0.8);
            }

            ul {
                margin: 24px 0 16px;
            }

            .button-ghost {
                margin-top: 16px;
                position:   absolute;
                left:       20px;
                bottom:     20px;

                @include media-breakpoint-up(md) {
                    left:   25px;
                    bottom: 25px;
                }

                @include media-breakpoint-up(xl) {
                    left:   40px;
                    bottom: 40px;
                }
            }
        }
    }
}
