.rscw_rhenus_site_lister_wrapper {
  #dropdown {
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    height: 67px;
    bottom: 0;

    h2, h3, h4 {
      color: #00469B;
    }

    h2 {
      font-size: 40px;
      @media (max-width: 595px) {
        font-size: 28px;
      }
    }

    #dropdownSingle {
      h2, h3 {
        padding: 8px;
      }

      h2 {
        padding-top: 40px;
        @media (max-width: 595px) {
          padding-top: 8px;
          padding-left: 0;
          margin-bottom: 0;
        }
      }

      @media (max-width: 595px) {
        overflow-y: scroll;
      }

      @media (max-width: 595px) {
        h3 {
          padding-left: 0;
        }
      }
    }


    h3 {
      font-size: 28px;
      @media (max-width: 595px) {
        font-size: 20px;
      }
    }

    h4 {
      padding-left: 0;
    }
  }

  .animation-class {
    height: 480px;
    animation: dropup 1s forwards;
  }

  #dropdown-label-wrapper {
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);
    right: 0;
    z-index: 10;
    top: 23px;
    pointer-events: all;

    &:after {
      background-color: #fff;
      height: 30px;
      content: "";
      position: absolute;
      bottom: -30px;
      left: -20px;
      right: 0;
      @media (max-width: 595px) {
        left: -90%;
        height: 20px;
        bottom: -20px;
      }
    }
  }

  #dropdownMulti, #dropdownSingle {
    height: calc(25vw + 227px);
    top: 67px;
    background-color: white;
    padding: 10px;
    width: 100%;
    left: 0;
    pointer-events: all;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);

    @media (max-width: 1199px) {
      height: calc(100vw/3 + 227px);
    }

    @media (max-width: 767px) {
      height: calc(50vw + 227px);
    }

    @media (max-width: 595px) {
      height: 200px;
      overflow-y: scroll;
    }
  }

  @keyframes dropup {
    from {
      bottom: 0;
      height: 67px;
    }
    to {
      bottom: 0;
      height: calc(25vw + 294px);
    }
  }

  @media (max-width: 1199px) {
    @keyframes dropup {
      from {
        bottom: 0;
        height: 67px;
      }
      to {
        bottom: 0;
        height: calc(100vw/3 + 294px);
      }
    }
  }

  @media (max-width: 767px) {
    @keyframes dropup {
      from {
        bottom: 0;
        height: 67px;
      }
      to {
        bottom: 0;
        height: calc(50vw + 294px);
      }
    }
  }

  @media (max-width: 595px) {
    @keyframes dropup {
      from {
        bottom: 0;
        height: 67px;
      }
      to {
        bottom: 0;
        height: 267px;
      }
    }
  }


  #dropdown-label {
    background-color: #00469B;
    color: white;
    padding: 10px;
    cursor: pointer;
    display: inline-block;
  }

  #dropdown-close {
    background-color: white;
    padding: 10px;
    height: 44px;
    width: 44px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
  }

  #cancel, .removeSvgInBackendButton {
    background: #00469B;
    color: white;
    position: absolute;
    font-size: 40px;
    top: 210px;
    left: -140px;
    display: none;
    padding: 0 15px;
    @media (max-width: 1199px) {
      left: 10px;
      top: 10px;
    }
  }

  #radios {
    ul {
      list-style-type: none;

      li {
        padding: 10px 0;
        @media (max-width: 595px) {
          padding: 0;
        }

        input {
          margin-right: 8px;
        }
      }
    }
  }

  .radios {
    ul {
      padding: 10px !important;

      li {
        height: 32px;
        overflow: hidden;
      }
    }
  }

  .checkbox-wrapper {
    bottom: 30px;
    left: 140px;
    @media (max-width: 1199px) {
      left: 0;
    }
    @media (max-width: 595px) {
      background-color: white;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;

      .checkbox-dropleft {
        height: 70px;
        width: 26px;
        position: absolute;
        top: calc(50% - 35px);
        right: -25px;
        background: white;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;

        .checkbox-icon {
          top: calc(50% - 12px);
          position: absolute;
          font-size: 24px;
        }
      }

      .radios {
        width: 0;
        overflow: hidden;
      }

      .checkbox-show {
        // animation: checkbox-animation-show 1s forwards;
        width: 100%;
      }

      @keyframes checkbox-animation-show {
        from {
          width: 0
        }
        to {
          width: 100%
        }
      }

      .checkbox-hide {
        // animation: checkbox-animation-hide 1s forwards;
        width: 0;
      }

      @keyframes checkbox-animation-hide {
        from {
          width: 100%
        }
        to {
          width: 0
        }
      }
    }
  }

  .st0 {
    opacity: 0.3;
    fill: #CCE0ED;
    stroke: #FAFAFA;
    stroke-width: 0.95;
    enable-background: new;
  }

  .st1 {
    opacity: 0.3;
    fill: #00469B;
    stroke: #FAFAFA;
    stroke-width: 0.95;
    enable-background: new;
  }

  .st1:hover {
    fill: #FDBD03;
    opacity: 1;
    cursor: pointer;
  }

  .st2 {
    fill: #00469B;
    stroke: #FAFAFA;
    stroke-width: 0.95;
  }

  .pathFill {
    fill: #F4B224;
    cursor: pointer;
  }

  .pathCircleFill {
    fill: transparent;
    cursor: pointer;
  }

  .activePathFill {
    fill: #FFFFFF;
    cursor: pointer;
  }

  .button {
    background-color: #00469B;
    color: white;
    border: none;
    padding: 5px 12px 0;
    z-index: 50;

    .material-icons {
      font-size: 25px;
    }
  }

  .checkbox-span {
    position: relative;
    bottom: 3px;
  }

  .cancel-button {
    font-size: 52px;
  }

  #button-previous {
    left: 0;
  }

  #button-next {
    right: 1px;
  }

  #dropdownMultiNavArrows {
    height: 30px;
    margin-bottom: 16px;
  }

  #dropdownMultiContent {
    .button {
      padding: 4px 8px;
    }
  }

}
@media (max-width: 595px) {
  .rscw_rhenus_site_lister {
    width: 200%;
  }

  .rscw_rhenus_site_lister_wrapper {
    overflow-x: scroll;
  }
}