.form-popover {
    height: 20px;
    width: 20px;
    background-color: $rhenus-blue-dark;
    color: white;
    border: none;
    border-radius: 50%;
    outline: none!important;
    margin-left: 10px;
    vertical-align: top;
    line-height: 10px;
}

.popover {
    border: 2px solid #80a2cb;
    box-shadow: none;
    border-radius: 0;

    .popover-header {
        background-color: #e6f1f8;
        position: relative;
        border-bottom: 0;
        padding-bottom: 0;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 1.4rem;
    }

    .popover-body {
        background-color: #e6f1f8;
        position: relative;
        padding-bottom: 10px;
    }

    &.bs-popover-right {
        margin-left: 1rem;
        .arrow {
            &:before {
                border-right-color: #80a2cb;
                left: -2px;
                top: -8px;
                border-width: 1rem 1rem 1rem 0;
            }
            &:after {
                border-right-color: #e6f1f8;
            }
        }
    }

    &.bs-popover-left {
        margin-right: 1rem;
        .arrow {
            &:before {
                border-left-color: #80a2cb;
                right: -2px;
                top: -8px;
                border-width: 1rem 0 1rem 1rem;
            }
            &:after {
                border-left-color: #e6f1f8;
            }
        }
    }

    &.share-popover{
        border: 0px;
        margin: 0 !important;
        .popover-body{
            padding: 0px;
            background-color: #fff;
            ul{
                li{
                    border-bottom: 1px solid $rhenus-section-grey;
                    margin: 0px;
                    padding: 0px;
                    &:last-child{
                        border-bottom: 0px;
                    }
                    img{
                        max-width: 50px;
                        height: auto;
                    }
                    .icon-badge{
                        background-color: $rhenus-blue;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        margin-right: 1rem;
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                    a{
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 20px;
                        padding: 14px 16px;
                        min-width: 180px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    }
                }
            }
        }
        top: 80px !important;
        left: -76px !important;
        &.breaking-slider-on-page{
            left: -132px !important;
        }

        &.share-header-popover{
            top: 60px !important;
            left: 129px !important;
            &.bs-popover-bottom{
                top: -60px !important;
            }
        }

        &.share-footer-popover{
            top: 0px !important;
            &.breaking-slider-on-page{
                left: -44px !important;
            }
        }
    }
}

.close-share-popover{
    position: fixed;
    z-index: 302;
    // color: #FFF;
    // background-color: $rhenus-orange;
    // box-shadow:     0 2px 20px 0 rgba($black, 0.2);
}

.rhenus-custom-select {
    .form-popover {
        position: absolute;
        top: 0;
        right: -30px;
    }
}

.powermail_fieldwrap_type_radio,
.powermail_fieldwrap_type_check {
    .form-popover {
        margin-left: 0;
    }

    .powermail_label ~ .form-popover {
        margin-left: 10px;
    }
}

.powermail_fieldwrap_type_input {
    .form-popover {
        @include media-breakpoint-down(sm) {
            position: absolute;
            right: 0;
        }
    }
}

.powermail_fieldwrap_type_select,
.powermail_fieldwrap_type_country {
    .form-popover {
        @include media-breakpoint-down(sm) {
            position: absolute;
            right: -15px;
            z-index: 2;
        }
    }
}

.powermail_fieldwrap_type_textarea {
    .form-popover {
        @include media-breakpoint-down(sm) {
            position: absolute;
            right: 0;
        }
    }
}
