.solr-search-result-count-per-page {
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    margin:          32px 0 32px;

    @include media-breakpoint-up(md) {
        margin: 0 0 45px;
    }

    h3 {
        font-weight:   400;
        line-height:   1;
        margin-bottom: 0;

        .result-count {
            font-weight: bold;
        }
    }

    #results-per-page {
        font-size: $font-size-base;
        display:   none;

        @include media-breakpoint-up(md) {
            display:     flex;
            align-items: center;
        }

        .per-page-btn {
            font-size: $font-size-base;
            margin:    0;
            padding:   0 5px;

            &.btn-bold {
                font-weight:    bold;
                padding-bottom: 2px;
            }
        }
    }
}

.solr-search-result-no-results {
    margin-top: 40px;

    .page-body & ul {
        li {
            margin-top:    10px;
            margin-bottom: 10px;
        }
    }
}

.solr-search-result-no-results__searched-for-message {
    @extend .h3;
    font-weight:    normal;
    margin-bottom:  45px;
    text-transform: initial;

    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
    }
}

.solr-search-result-no-results__suggestions {
    font-weight:    normal;
    margin-bottom:  15px;
    text-transform: initial;

    @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
    }
}

.solr-search-result-no-results__suggestions-image {
    margin-top: 60px;

    @include media-breakpoint-up(lg) {
        margin-top: unset;
    }
}
