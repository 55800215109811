.table,
.contenttable {
    width: 100%;

    tr:nth-of-type(even) {
        background-color: #ECECED;
    }

    td {
        border: 0;
        height: 48px;
        vertical-align: middle;
        padding-left: 18px;
        padding-right: 18px;
    }

    tr.table-head {
        td {
            border-top: 0;
            border-bottom: 2px solid $rhenus-gray-dark;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    td.table-cell-label {
        border-top: 0;
        border-bottom: 2px solid $rhenus-gray-dark;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.stripe {
    .table,
    .contenttable {
        tr:nth-of-type(even) {
            background-color: $white;
        }

        tr.row-highlighted {
            color: $white;
            background-color: #014698;

            &:nth-of-type(even) {
                background-color: #002C7C;
            }
        }

        td.col-highlighted {
            background-color: #014698;
            color: $white;
            border-bottom: 0;
        }

        tr:nth-of-type(even) {
            td.col-highlighted {
                color: $white;
                background-color: #002C7C;
            }
        }

        td.col-highlighted {
    
        }
    }
}