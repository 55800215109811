$pulse-shadow-0: 0 0 0 0 rgba(250,187,0, 0.8);
$pulse-shadow-70: 0 0 0 8px rgba(250,187,0, 0);
$pulse-shadow-70-intense: 0 0 0 12px rgba(250,187,0, 0);
$pulse-shadow-100: 0 0 0 0 rgba(250,187,0, 0);
@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: $pulse-shadow-0;
    }
    70% {
        -webkit-box-shadow: $pulse-shadow-70;
    }
    100% {
        -webkit-box-shadow: $pulse-shadow-100;
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: $pulse-shadow-0;
        box-shadow: $pulse-shadow-0;
    }
    70% {
        -moz-box-shadow: $pulse-shadow-70;
        box-shadow: $pulse-shadow-70;
    }
    100% {
        -moz-box-shadow: $pulse-shadow-100;
        box-shadow: $pulse-shadow-100;
    }
}
@-webkit-keyframes pulse-intense {
    0% {
        -webkit-box-shadow: $pulse-shadow-0;
    }
    70% {
        -webkit-box-shadow: $pulse-shadow-70-intense;
    }
    100% {
        -webkit-box-shadow: $pulse-shadow-100;
    }
}
@keyframes pulse-intense {
    0% {
        -moz-box-shadow: $pulse-shadow-0;
        box-shadow: $pulse-shadow-0;
    }
    70% {
        -moz-box-shadow: $pulse-shadow-70-intense;
        box-shadow: $pulse-shadow-70-intense;
    }
    100% {
        -moz-box-shadow: $pulse-shadow-100;
        box-shadow: $pulse-shadow-100;
    }
}

.interactive-map{

    .interactive-image{
        img{
            width: 100%;
            height: auto;
        }
    }

    .hotspot-container{
        position: relative;
        background-color: #fff;
    }

    .hotspots{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .hotspot{
            position: absolute;
            border-radius: 50%;
            background-color: #fff;
            width: 2rem;
            height: 2rem;
            margin-left: -1rem;
            margin-top: -1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0 0 0 rgba(250,187,0, 0.8);
            animation: pulse 2s infinite;
            transition: transform 0.3s ease-in-out;

            i{
                color: $rhenus-blue;
                transition: transform 0.3s ease-in-out;
                font-size: 1.5rem;
            }
            img {
                object-fit: cover;
                border-radius: 50%;
                transition: transform 0.3s ease-in-out;
            }

            &.open,&:hover{
                background-color: $rhenus-blue;
                transform: scale(1.2);
                animation: pulse-intense 2s infinite;
                i{
                    color: #fff;
                }
            }
            &.open{
                i{
                    transform: rotate(45deg);
                }
            }
        }
    }

    .hotspot-content{
        background-color: #fff;
        .close-hotspot-content{
            margin-top: 2.5rem;
            cursor: pointer;
            font-size: 2rem;
            color: $rhenus-blue;
            &:hover{
                color: $rhenus-blue-dark;
            }
        }
    }

    .hotspots-mobile-container {
        background-color: $white;

        .accordion-container {

            .accordion-element {
                padding: 24px 30px 0;

                .accordion-header {
                    display:          flex;
                    flex-direction:   row;
                    flex-wrap:        nowrap;
                    justify-content:  flex-start;
                    min-height:       unset;
                    margin-bottom:    0;
                    padding:          0;
                    background-image: unset;

                    .accordion-indicator {
                        position: relative;
                        top:      2px;
                        margin:   0;

                        &::before {
                            font-size:   20px;
                            margin-left: -5px;
                            content:     "chevron_right";
                            color:       $rhenus-blue;
                        }
                    }

                    .accordion-header-text {
                        font-size:      18px;
                        line-height:    24px;
                        margin-left:    8px;
                        text-transform: none;
                        color:          $rhenus-blue;

                        &, & > div {display: inline;}

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                &:first-child {
                    padding-top: 30px;
                }

                &:last-child {
                    padding-bottom: 30px;
                }
            }
        }
    }
}

.hotspot-modal {
    position: relative;
    padding:  0;

    .hotspot-modal-content {
        padding-top: 16px;

        @include media-breakpoint-up(lg) {
            padding-top: 60px;
        }

        .ce-textpic {
            flex-wrap: wrap-reverse;

            @include media-breakpoint-up(lg) {
                flex-wrap: wrap;
            }
        }
    }

    .mfp-close-container {
        position:         sticky;
        z-index:          2;
        top:              0;
        right:            0;
        left:             0;
        overflow:         hidden;
        width:            100%;
        height:           48px;
        background-color: rgba($rhenus-blue, 0.8);

        @include media-breakpoint-up(lg) {
            position:         fixed;
            height:           60px;
            background-color: unset;
        }

        .mfp-close {
            font-size: 32px;
            position:  relative;
            top:       0;
            right:     -16px;
            float:     right;
            width:     48px;
            height:    48px;
            opacity:   1;
            color:     $white;

            @include media-breakpoint-up(lg) {
                top:     6px;
                right:   -6px;
                opacity: .65;
                color:   $black;
            }
        }
    }
}

.mfp-container {
    position: fixed;
    top:      0;
    right:    0;
    bottom:   0;
    left:     0;
    z-index: 20;
    overflow-y: scroll;
}

body.mfp-noscroll {
    overflow: hidden;
}
