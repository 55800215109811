@import "../variables";

.stage {
    position:    relative;
    width:       100%;
    height:      0;
    margin-top:  -120px;
    padding-top: 42.8571%; /* 21:9 */

    header {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        height:  auto;
        padding: 0;
    }

    .container {
        position: relative;
    }

    figure {
        position:      relative;
        top:           0;
        right:         0;
        bottom:        0;
        left:          0;
        display:       block;
        margin-bottom: 0;

        @include media-breakpoint-up(xl) {
            position:      absolute;
            margin-bottom: unset;
        }

        img {
            @include media-breakpoint-down(lg) {
                height:     450px;
                object-fit: cover;
            }

            @include media-breakpoint-down(md) {
                height:     315px;
            }
        }

        .image-desktop {
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        .image-mobile {
            display: none;
            @include media-breakpoint-down(lg) {
                display: block;
            }
        }
    }

    .breadcrumb-container-mobile {
        padding-top:    40px;
        padding-bottom: 0;
    }

    .background-image {
        display:   block;
        width:     100%;
        max-width: 100%;

        &.placeholder {
            &.placeholder-desktop {
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            &.placeholder-mobile {
                display: none;
                @include media-breakpoint-down(lg) {
                    display: block;
                }
            }
        }
    }

    .stage-content {
        position:            absolute;
        bottom:              80px;
        left:                15px;
        width:               58.333%;
        padding:             70px 40px 40px;
        background:          linear-gradient(135deg, transparent 50px, rgba($white, 0.8) 50px) no-repeat top left;

        @include media-breakpoint-down(lg) {
            position: relative;
            bottom:   auto;
            left:     0;
            width:    100%;
            padding:  32px 0;
        }
        .headline{
            &.prevent-line-break{
                @include media-breakpoint-up(lg) {
                    hyphens: none;
                }
            }
        }
    }

    .mobile-divider {
        display: none;

        @include media-breakpoint-down(sm) {
            display:       block;
            margin-bottom: 24px;
        }
    }

    .headline {
        margin-bottom:  24px;
        text-transform: uppercase;
        color:          $rhenus-gray-dark;
    }

    .stage-text {
        margin-bottom: 24px;
    }

    .button-secondary {
        margin-right: 40px;
    }
}

.rheintank{
    .stage{
        .stage-content{
            background: linear-gradient(135deg, transparent 50px, rgba(0, 51, 102, .7) 50px) no-repeat top left;
            .headline{
                color: #fff;
            }
        }
    }
}
.bilgenentoelung{
    .stage{
        .stage-content{
            background: linear-gradient(135deg, transparent 50px, rgba(255, 204, 0, .7) 50px) no-repeat top left;
            .headline{
                color: $rhenus-gray-dark;
            }
        }
    }
}