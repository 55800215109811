.no-padding{
    padding: 0px !important;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
 a.ui-button:active,
 .ui-button:active,
 .ui-button.ui-state-active:hover{
    border-color: $rhenus-blue-secondary !important;
    background-color: $rhenus-blue-secondary !important;
}

.ui-widget.ui-widget-content{
    border: none !important;
    z-index: 999 !important;
}

.ui-menu .ui-menu-item-wrapper{
    padding-left: 0px !important;
}

#search-form-text{
    position: relative;
    ul:not(.unstyled-list),ul.styled {
        padding: 0;
        list-style: none;
        li {
            position: relative;
            margin-left: 28px;
            margin-bottom: 16px;
            &:before {
                content: "";
                height: 14px;
                width: 22px;
                left: -28px;
                top: 7px;
                position: absolute;
                display: inline-block;
                background: url('$typo3conf/ext/rscw_provider/Resources/Public/Icons/rhenus-slash.svg') top left no-repeat transparent;
                background-size: contain;
            }
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
