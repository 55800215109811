.powermail_fieldwrap_type_file{
    max-width: 350px;
    position: relative;

    .parsley-errors-list:not(.unstyled-list){
        bottom: -80px;

        .parsley-powermailfilesize {
            color: $rhenus-form-error-red;
        }
    }

    input[type=file]{
        display: none;
    }

    .current-file-list{
        margin-bottom: 2.5rem !important;

        li{
            border-bottom: 1px solid $rhenus-gray-dark;
            overflow: hidden;
            position: relative;
            margin-bottom: 16px !important;

            .file-name{
                font-weight: bold;
            }

            .file-size{
                display: block;
            }

            .remove-list{
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: $rhenus-blue;
                width: 20px;
                height: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                color: #fff;
                i{
                    font-size: 20px;
                }
            }
        }
    }
}
