.open-rhenus-world-wide {
    display: block;
}

#rhenus-worldwide-popup {
    margin-top: 40px;

    .country-title {
        margin-bottom: 40px;
    }

    h2, h3 {
        text-transform: uppercase;
    }

    h3 {
        margin-bottom: 28px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 24px;
        }
    }

    .country-switch {
        &.white-popup {
            padding: 40px 30px;
        }

        .container {
            position: relative;
        }

        .mfp-close {
            color: black;
            top:   0;
            right: 0;
        }

        .country-title {
            text-transform: uppercase;
        }

        .stripe-container {
            margin-left:  0;
            margin-right: 0;

            @include media-breakpoint-up(md) {
                margin-left:  -70px;
                margin-right: -70px;
            }
        }
    }

    ul.country-list {
        display:       flex;
        flex-wrap:     wrap;
        gap:           8px;
        width:         100%;
        padding-left:  0;
        margin-bottom: 0;

        @include media-breakpoint-up(xl) {
            gap: 0;
        }

        li {
            width:       100%;
            margin-top:  0;
            margin-left: 0;
            display:     flex;
            align-items: center;

            @include media-breakpoint-up(md) {
                width: calc(50% - 8px);
            }

            @include media-breakpoint-up(xl) {
                width: 25%;
            }

            a {
                width:       100%;
                line-height: 48px;

                @include media-breakpoint-up(lg) {
                    line-height: 40px;
                }

                &:before {
                    content:         "";
                    background:      url("$typo3conf/ext/rscw_provider/Resources/Public/Icons/MaterialIconsOutlined/rhenus-blue/location_on-datenmuehle.svg") top left no-repeat transparent;
                    background-size: contain;
                    font-size:       18px;
                    display:         inline-block;
                    width:           24px;
                    height:          18px;
                    position:        unset;
                    padding-right:   4px;
                    transform:       translateY(3px);
                    color:           $rhenus-blue;
                }

                &:hover {
                    &:before {
                        @extend .material-icons;
                        content:    "location_on";
                        background: none;
                        font-size:  18px;

                    }
                }
            }
        }
    }
}

.country-switch-stripe {
    padding:      40px 0;
    margin-left:  -30px;
    margin-right: -30px;

    @include media-breakpoint-up(md) {
        margin-left:  40px;
        margin-right: 40px;
    }

    .country-switch-anker {
        position:    relative;
        line-height: 48px;
        display:     flex;
        flex-flow:   row nowrap;
        align-items: baseline;
        gap:         8px;
        width:       100%;

        @include media-breakpoint-up(xl) {
            display:      inline-flex;
            flex-flow:    row wrap;
            margin-right: 1.5rem;
            width:        unset;

            &:last-child {
                margin-right: 0;
            }
        }

        &:before {
            width:             14px;
            height:            14px;
            content:           "";
            background-image:  url("$typo3conf/ext/rscw_provider/Resources/Public/Images/Backend/country-list-double-chevron-datenmuehle.svg");
            background-repeat: no-repeat;
            transform:         translateY(1px);
        }
    }

    .country-switch-stripe-left {
        padding-left:  30px;
        padding-right: 30px;

        p:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .country-switch-stripe-right {
        p {
            margin-bottom: 0;
        }

        .button-ghost {
            margin-top: 40px;
        }

        /**
            Offsetting the negative margin from .stripe-container
         */
        @at-root .stripe-container & {
            @include media-breakpoint-up(md) {
                padding-right: 60px;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-top: 40px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }
}
