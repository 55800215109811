.custom-radio-checkbox {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    padding-left: 32px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &:hover {
        color: $rhenus-blue;
    }

    &.checkbox {
        .checkmark {
            position: absolute;
            top: 4px;
            left: 0;
            height: 18px;
            width: 18px;
            border-radius: 2px;
            background-color: #fff;
            border: 2px solid $rhenus-gray-dark;
        }

        &:hover input ~ .checkmark {
            background-color: #e6f1f8;
            color: $white;
            border: 2px solid $rhenus-blue;
        }

        input:checked ~ .checkmark {
            background-color: $rhenus-blue;
            &:after {
                content: "check";
                @include material-icon;
                font-size: 17px;
                position: absolute;
                top: -2px;
                left: -1px;
                color: $white;
            }
        }
    }

    &.radio-button {
        .checkmark {
            position: absolute;
            top: 4px;
            left: 0;
            height: 18px;
            width: 18px;
            border-radius: 2px;
            background-color: #fff;
            border: 2px solid $rhenus-gray-dark;
            border-radius: 50%;
        }

        &:hover input ~ .checkmark {
            background-color: #e6f1f8;
            color: $white;
        }

        input:checked ~ .checkmark {
            background-color: $white;
            &:after {
                content: "";
                background-color: $rhenus-blue;
                position: absolute;
                top: 3px;
                left: 3px;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                color: $white;
            }
        }
    }
}

.page-body {
    .checkbox-error-container {
        .parsley-errors-list:not(.unstyled-list) {
            position: relative;
        }
    }
    .powermail_fieldwrap_type_radio,.powermail_fieldwrap_type_check{
        label.required .required-dot{
            display: block;
            border-radius: 50%;
            position: relative;
            float: right;
            top: 5px;
            right: -5px;
        }

        .parsley-error .custom-radio-checkbox {
            color: $rhenus-form-error-red;
        }
    }
}
