$magnific__assets-path: 'typo3conf/ext/rscw_provider/node_modules/magnific-popup/dist/';

@import 'magnific-popup/dist/magnific-popup.css';

.white-popup{
  position: relative;
  background: #FFF;
  padding: 30px;
  width: auto;
  margin: 0px auto;
}

.mfp-container{
    padding: 0px !important;
}

.mfp-zoom-in {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

