.page-body {

  .rhenus-custom-select {
    display: block;
    position: relative;
    max-width: 540px;
    height: 52px;

    label {
      position: absolute;
      z-index: 1;
      left: 17px;
      font-size: 12px;
      top: 7px;
      color: $rhenus-gray;
      pointer-events: none;
    }

    .bootstrap-select:not(.input-group-btn),
    .bootstrap-select[class*=col-] {
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid #c2cace;
      &:hover {
        border-color: #80a2cd;
      }
    }

    .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
      width: 100%;
      height: 100%;
    }

    .bootstrap-select>.dropdown-toggle {
      display: block;
      height: 100%;
      background-color: $white;
      border: 0;
      box-shadow: none;
      color: $rhenus-gray-dark;
      padding: 4px 0 0 15px;
      position: relative;

      &.bs-placeholder {
        padding-top: 2px;
        color: #cbd2d5;
        z-index: 2;
      }

      &:after {
        position: absolute;
        content: "keyboard_arrow_down";
        @include material-icon;
        color: $rhenus-gray-dark;
        top: 14px;
        right: 13px;
        font-size: 24px;
        text-indent: 0;
        border: 0;
      }
    }

    .bootstrap-select.show {
      border-color: #80a2cd;
    }

    .bootstrap-select.show>.dropdown-toggle {
      &:after {
        content: "keyboard_arrow_up";
      }
    }

    .bootstrap-select .dropdown-toggle .filter-option {
      line-height: 58px;
    }

    .bootstrap-select .dropdown-toggle.bs-placeholder .filter-option {
      line-height: 44px;
    }

    .bootstrap-select .dropdown-toggle:focus, .bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
      outline: none!important;
    }

    .bootstrap-select .dropdown-menu {
      border-radius: 0;
      left: -1px!important;
      right: -1px;
      border-color: #80a2cd;
      box-shadow: none;
      padding: 0;

      ul:not(.unstyled-list) li {
        margin: 0;
        border: none;

        &:before {
          display: none;
        }

        &:first-child {
          border-top: none;
        }

        a {
          border-top: 1px solid #d2d8db;
          height: 53px;
          line-height: 48px;
          padding-left: 15px;

          &:hover {
            background-color: #e6f1f8;
          }

          &.active {
            background-color: #e6f1f8;
            color: $rhenus-gray-dark;
          }
        }
      }
    }

    .bootstrap-select > .dropdown-menu {
      margin: -2px 0;
    }

  } 

}

.rhenus-custom-select {
  .bs-searchbox {
    position: relative;
    padding: 0;
    height: 48px;

    &:before {
      content: "search";
      @include material-icon;
      position: absolute;
      display: inline-block;
      top: 17px;
      left: 13px;
      font-size: 18px;  
    }
  
  
    .form-control {
      border-radius: 0;
      background-color: #f5f5f5;
      padding-left: 35px;
      height: 100%;
    }
  }
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  top: 18px !important;
}