.contact-teaser {
    margin-top: 0;
    padding:    40px 0;

    @include media-breakpoint-up(lg) {
        padding: 75px 0 55px;
    }

    /** Modifier */
    &, &_default {
        background-color: $rhenus-blue-light;
    }
}

.contact-teaser__content {
    a, span {
        position:      relative;
        display:       block;
        margin-bottom: 20px;
        padding-left:  28px;

        img {
            position: absolute;
            top:      2px;
            left:     0;
            width:    20px;
            height:   20px;
        }
    }

    .contact-teaser__address-row {
        line-height:   2;
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
            margin-bottom: unset;
        }

        img {
            top: 5px;
        }
    }
}

.contact-teaser__headline {
    &_position,
    &_contact-name {
        @extend .h2;
    }

    &_company {
        @extend .h1;

        @include media-breakpoint-down(md) {
            font-size:   24px;
            line-height: 38.4px;
        }
    }

    &_position {
        @include media-breakpoint-down(md) {
            font-size:   16px;
            line-height: 24px;
        }
    }

    margin-bottom: 24px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
    }
}
