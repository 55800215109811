$author-image-size: 150px;

.rhenus-quote {
    color: $rhenus-blue;
}

/** Elements */
.rhenus-quote__quote {
    font-size:     20px;
    line-height:   28px;
    font-weight:   700;
    margin-bottom: 24px;
    padding-top:   50px; /* room for the quote marks */
    position:      relative;
    z-index:       10;

    .rhenus-quote_quote-font-weight-light & {
        font-weight: 300;
    }

    .rhenus-quote_quote-font-weight-normal & {
        font-weight: 400;
    }

    &::before {
        content:           '';
        display:           block;
        width:             200px;
        height:            100px;
        background-image:  url('$typo3conf/ext/rscw_provider/Resources/Public/Icons/rhenus-slash-blue-lighter.svg');
        background-repeat: no-repeat;
        background-size:   200px 100px;
        position:          absolute;
        top:               0;
        left:              -30px;
        z-index:           -1;
    }
}

.rhenus-quote__author {
    .rhenus-quote__author-image {
        width:         $author-image-size;
        height:        $author-image-size;
        border-radius: $author-image-size / 2;
        overflow:      hidden;
        transform:     translateX(-15px);

        @include media-breakpoint-up(md) {
            transform: translateX(0);
        }
    }

    .rhenus-quote__author-info {
        font-weight: 700;

        span {
            display:     block;
            font-weight: 400;
        }
    }

    .rhenus-quote__author-additional-info {
        padding-top: 24px;

        @include media-breakpoint-up(md) {
            padding-top: 12px;
            line-height: 20px;
        }
    }

    .rhenus-quote_has-author-image & {
        position: relative;

        .rhenus-quote__author-infobox {
            @include media-breakpoint-up(md) {
                position:        absolute;
                top:             0;
                left:            $author-image-size;
                display:         flex;
                flex-flow:       column nowrap;
                min-height:      150px;
                justify-content: center;
                max-width:       calc(100% - #{$author-image-size} - 15px);
            }

            .rhenus-quote__author-info {
                @include media-breakpoint-down(sm) {
                    position:        absolute;
                    top:             0;
                    left:            150px;
                    display:         flex;
                    flex-flow:       column nowrap;
                    justify-content: center;
                    height:          $author-image-size;
                }

                @include media-breakpoint-up(md) {
                    padding-left: 15px;
                }
            }

            .rhenus-quote__author-additional-info {
                @include media-breakpoint-up(md) {
                    padding-left: 15px;
                }
            }
        }
    }
}
