@import "../variables";

.use-default-lang-banner {
    position:   fixed;
    z-index:    $zindex-fixed;
    right:      30px;
    bottom:     30px;
    display:    none;
    width:      420px;
    max-width:  calc(100% - 60px);
    padding:    40px 30px;
    background: rgba($rhenus-blue, .95);

    &_show {
        display:            flex;
        flex-direction:     column;
        animation:          backInRight;
        animation-duration: 2s;
        gap:                40px;
    }

    &_hidding {
        animation:          backOutRight;
        animation-duration: 2s;
    }
}

.use-default-lang-banner--message {
    p {
        margin-bottom: 0;
        color:         $white;
    }
}

.use-default-lang-banner--buttons {
    display:         flex;
    flex-direction:  column;
    gap:             24px;

    @include media-breakpoint-up(xl) {
        flex-direction: row;
    }

    > button {
        flex-grow: 1;
    }
}
