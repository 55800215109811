.tab-subfilter-link {
    display:       inline-block;
    margin-top:    20px;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
        margin-top: unset;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
    }

    i.material-icons {
        font-size:   24px;
        line-height: 24px;

        position:    relative;
        top:         6px;
        left:        -6px;
    }

    span {
        position: relative;
        left:     -12px;
    }
}
