$waterLevelsHeight: 324px;

.water-levels {
    width:    100%;
    height:   324px;
    border:   1px solid $rhenus-blue;
    padding:  20px;
    position: relative;

    &_theme-dark {
        background-color: $rhenus-blue;
    }

    &__headline {
        color:          $rhenus-blue;
        font-size:      24px;
        text-transform: uppercase;

        .water-levels_theme-dark & {
            color: #fff;
        }
    }

    &__sub-headline {
        color:     $rhenus-blue;
        font-size: 16px;

        .water-levels_theme-dark & {
            color: #fff;
        }
    }

    &__scale {
        position: absolute;
        top:      100px;
        left:     20px;
        bottom:   80px;
        right:    20px;
    }

    &__scale-value {
        width:            100%;
        height:           1px;
        background-color: $rhenus-gray;
        color:            $rhenus-gray;
        font-size:        10px;

        &_mid {
            /** 180px because of top + bottom of .water-levels_scale */
            transform: translateY(#{($waterLevelsHeight - 180px) / 2});
        }

        &_min {
            /** 180px because of top + bottom of .water-levels_scale */
            transform: translateY(#{($waterLevelsHeight - 180px)});
        }

        .water-levels_theme-dark & {
            border-top: 1px solid #fff;
        }
    }

    &__city-wrapper {
        position:        absolute;
        top:             calc(100% - 80px);
        bottom:          0;
        left:            40px;
        right:           40px;

        display:         flex;
        flex-flow:       row nowrap;
        justify-content: space-around;
        gap:             5px;

        @include media-breakpoint-up(sm) {
            gap: 10px;
        }

        @include media-breakpoint-up(lg) {
            gap:   15px;
        }
    }
}

.water-levels-city {
    position:   relative;
    width:      100%;
    text-align: center;

    &_hidden {
        display: none;
    }

    &__name {
        position:        absolute;
        top:             10px;
        left:            0;
        right:           0;
        display:         flex;
        flex-flow:       column wrap;
        justify-content: center;

        color:           $rhenus-blue;
        font-size:       12px;
        line-height:     12px;
        height:          24px;
        text-align:      center;
        hyphens:         auto;

        .water-levels_theme-dark & {
            color: #fff;
        }
    }

    &__icon {
        position: absolute;
        top:      40px;
        left:     0;
        right:    0;

        svg {
            width:  16px;
            height: 16px;

            path {
                fill: $rhenus-blue;

                .water-levels_theme-dark & {
                    fill: #fff;
                }
            }
        }
    }

    &__bar {
        position:         absolute;
        bottom:           75px;
        background-color: $rhenus-blue-light;
        width:            100%;
    }

    &__bar-waves {
        position: absolute;
        top:      -12px;

        width:    100%;
        height:   8.5px;

        svg path {
            fill: $rhenus-blue-light;
        }
    }

    &__value-wrapper {
        position:        absolute;
        top:             -46px;
        left:            17px;
        right:           17px;
        display:         flex;
        flex-flow:       column nowrap;
        align-items:     center;
        justify-content: center;
        transform:       translateY(-10px);
    }

    &__current-value {
        color:            $rhenus-blue;
        font-size:        16px;
        line-height:      1.1;
        background-color: rgba(#fff, 0.9);

        .water-levels_theme-dark & {
            color:            #fff;
            background-color: rgba($rhenus-blue, 0.9);
        }
    }

    &__trend {
        color:            $rhenus-gray;
        font-size:        16px;
        line-height:      1.1;
        background-color: rgba(#fff, 0.9);
        transition:       opacity 0.3s ease-in-out;

        &_negative {
            color: $rhenus-orange;
        }

        .water-levels_theme-dark & {
            background-color: rgba($rhenus-blue, 0.9);
        }

    }
}
