.image-slider-container {
    position: relative;
    padding-bottom: 50px;

    .slick-slider {
        margin-bottom: 0;
    }

    img {
        height: auto; 
        max-width: 100%;
        width: 100%;
    }

    .headline {
        margin-bottom: 24px;
        text-transform: uppercase;
    }

    .subheadline {
        display: block;
        margin-bottom: 18px;
        font-size: $font-size-base;
        color: $rhenus-blue;
        font-weight: normal;
        text-transform: uppercase;
    }

    .slider-text {
        margin-bottom: 32px;
    }

    .row > div,
    .image-slider-left {
        position: static;
    }

    .image-slider-right {
        @include media-breakpoint-down(md) {
            margin-top: 32px;
        }
    }

    .headline-container-mobile {
        display: none;
        @include media-breakpoint-down(md) {
            display: block; 
        }
    }

    .headline-container-desktop {
        @include media-breakpoint-down(md) { 
            display: none; 
        }
    }
}

.image-slider-navigation {
    position: relative;
    margin-top: -28px;

    .slider-button { 
        height: 49px;
        width: 49px;
        text-align: center;
        padding: 0;

        i {
            font-size: 2rem; 
            line-height: 2.8rem;
        }

        &.slider-prev {
            margin-right: 1px;

        }
    }
}

.image-slider-current-slide {
    margin-top: 16px;
    margin-bottom: 8px;

    span {
        display: none;
    }

    span:nth-of-type(1) {
        display: inline-block;
    }

    .image-slider-current {
        padding-right: 6px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: bottom;
        position: relative;

        &:after {
            content: "";
            display: inline-block;
            height: 50%;
            top: 7px;
            width: 1px;
            background-color: $rhenus-gray-dark;
            position: absolute;
            right: 0; 
        }
    }
}

.image-slider-progress {
    height: 2px;
    position: absolute;
    left: 0;
    right: 50%;
    background-color: $rhenus-gray;

    @include media-breakpoint-only(xs) {

    }

    @include media-breakpoint-only(sm) {
        margin-right: 156px;
    }

    @include media-breakpoint-only(md) {
        margin-right: 246px;
    }

    @include media-breakpoint-only(lg) {
        margin-right: 366px;
    }

    @include media-breakpoint-only(xl) {
        margin-right: 456px;
    }

    .slider-progress-bar {
        height: 100%;
        background-color: $rhenus-blue;
        width: 0;
    }
}


.stripe {
    .image-slider-container {
        padding-bottom: 0;

        .headline {
            margin-bottom: 20px;
        }
    }
}