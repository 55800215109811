@import "../variables";
@import "sidebar-cta-container";

.back-to-top-btn {
    position:         fixed;
    z-index:          299;
    right:            15px;
    bottom:           -48px;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    width:            48px;
    height:           48px;
    transition:       all .5s ease-in-out;
    text-align:       center;
    background-color: $white;
    box-shadow:       $box-shadow;

    &:hover, &:active, &:focus {
        text-decoration: none;
    }

    &#back-to-top-btn-mobile {
        @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
            display: none;
        }
    }

    &#back-to-top-btn-desktop {
        display: none;

        @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
            display: flex;
        }
    }

    &_has-sidebar {
        bottom: 0;

        @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
            right:        unset;
            bottom:       -48px;
            width:        60px;
            height:       40px;
            margin-right: -20px;
        }
    }

    &_show {
        bottom: 10px;

        &.back-to-top-btn_has-sidebar {
            bottom: 55px;

            @include media-breakpoint-up(map-get($sidebarCtaContainerBreakpoint, "desktop")) {
                bottom: 0;
            }
        }
    }

    i.material-icons {
        font-size: 28px;
        color:     $black;
    }
}
