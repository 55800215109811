.rhenus-event-item {
    $img-width:         100%;
    $img-width-desktop: 350px;
    
    & {
        width:         100%;
        padding:       60px 0;
        border-bottom: 2px solid $rhenus-gray;
        
        @include media-breakpoint-up(lg) {
            padding: 30px 0 60px;
        }
    }
    
    .material-icons {
        font-size:    24px;
        position:     relative;
        top:          6px;
        margin-right: 0;
        
        &_neg-margin-right {
            margin-right: -5px;
        }
    }
    
    &__image-box {
        position: relative;
        
        img {width: $img-width; height: auto}
        
        @include media-breakpoint-up(lg) {
            display:    inline-block;
            float:      left;
            width:      $img-width-desktop + 15;
            height:     100%;
            min-height: 233.33px;
            margin:     30px 14px 30px 0;
            
            img {width: $img-width-desktop; height: auto}
        }
    }
    
    &__top-event-bar {
        font-size:        14px;
        font-weight:      700;
        line-height:      35px;
        position:         absolute;
        bottom:           -35px;
        left:             0;
        padding:          0 20px;
        text-align:       center;
        color:            $white;
        background-color: $rhenus-blue;
        
        @include media-breakpoint-up(lg) {
            z-index: -1;
            top:     -2px;
            right:   -160px;
            bottom:  calc(100% - 35px);
            left:    $img-width-desktop;
        }
    }
    
    &__top-event-line {
        position:   absolute;
        right:      0;
        bottom:     -2px;
        left:       0;
        height:     2px;
        content:    "";
        background: $rhenus-blue;
        
        @include media-breakpoint-up(lg) {
            top: -2px;
        }
    }
    
    &__top-event-spacer {
        height: 30px;
        
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    
    &__headline {
        margin: 30px 0;
        
        @include media-breakpoint-up(lg) {
            padding-left: 30px;
            
            &_top-event {
                padding-left: $img-width-desktop + 30 + 160;
            }
        }
    }
    
    &__infoline {
        margin: 30px 0;
    }
    
    &__teaser-text {
        margin: 30px 0;
        
        @include media-breakpoint-up(lg) {
            margin: 30px 0 30px ($img-width-desktop + 30);
        }
    }
    
    &__add-to-calender {
        margin: 0 0 5px;
        color:  $rhenus-blue;
        
        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }
    
    &__go-to-detail-page {
        margin: 0;
        color:  $rhenus-blue;
        
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
    }
}