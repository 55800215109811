.frame-space-after-global-space-after-none {
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.frame-space-after-global-space-after-extra-small {
    margin-bottom: 40px;
}

.frame-space-after-global-space-after-small {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
    }
}

.frame-space-after-global-space-after-medium {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 96px;
    }
}

.frame-space-after-global-space-after-large {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 104px;
    }
}

/** Shorthands for frame-space-after-global-space-after-{extra-small|small|medium|large} */
.space-after-extra-small { @extend .frame-space-after-global-space-after-extra-small }
.space-after-small { @extend .frame-space-after-global-space-after-small }
.space-after-medium { @extend .frame-space-after-global-space-after-medium }
.space-after-large { @extend .frame-space-after-global-space-after-large }

/** Special Cases */
.frame-type-rscwcontentelements {
    &_stage,
    &_big_stage {
        margin-bottom: 0;
    }

    .news-single &_company_information_stripe {
        margin-top: 0;
    }
}

/** Helper */
.main-spacer {
    padding-top: 40px;
}
