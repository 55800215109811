.powermail_fieldwrap {
    .mdc-text-field {
        width: 100%;
        max-width: 540px;
        border-radius: 0;
        overflow: visible;
        position: relative;

        &::before,
        &::after {
            display: none;
        }

        // bottom border line
        .mdc-line-ripple {
            height: 1px;

            &.mdc-line-ripple--active {
                background-color: $rhenus-gray-dark;
            }
        }

        // label
        &:not(.mdc-text-field--disabled) {
            .mdc-floating-label {
                color: $rhenus-gray;
                white-space: inherit;
            }
        }

        // Hover
        &:hover {
            background-color: #e6f1f8;

            .mdc-text-field__input {
                color: #00469b;
            }
        }

        // Focus
        &.mdc-text-field--focused {
            background-color: #e6f1f8;
            border: 1px solid #00469b;

            .mdc-text-field__input {
                color: #00469b;
            }

            .mdc-line-ripple--active {
                background-color: #00469b;
            }
        }

        // Correct
        &.mdc-text-field--correct {
            background-color: #e1fae1;
            border: 1px solid #5cb85c;

            .mdc-text-field__input {
                color: $rhenus-gray;
            }

            .mdc-line-ripple--active {
                background-color: #5cb85c;
            }
        }

        // Warning
        &.mdc-text-field--warning {
            background-color: #fff3cf;
            border: 1px solid #fabb00;

            .mdc-text-field__input {
                color: $rhenus-gray;
            }

            .mdc-line-ripple--active {
                background-color: #fabb00;
            }
        }

        // Error
        &.mdc-text-field--invalid {
            background-color: #ffe6e6;

            .mdc-text-field__input {
                color: $rhenus-gray-dark;
            }
        }

        .parsley-errors-list:not(.unstyled-list) {
            position: absolute;
            bottom: -100%;
            font-size: 12px;
            li {
                padding: 0;
                margin-left: 0;
                &:before {
                    display: none;
                }
            }
        }
    }
}

// Captcha Image
.powermail_captchaimage {
    vertical-align: top;
}

.mdc-text-field:hover::before {
    display: none;
}
