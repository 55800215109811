.custom-select-multiple .bootstrap-select.show-tick{
     .dropdown-menu{
         ul.inner{
             li{
                 a{
                     span.check-mark{
                        display: inline-block;
                        position: relative;
                        right: auto;
                        top: 0px !important;
                        left: 5px;
                        background-color: $white;
                        border: 2px solid $rhenus-gray-dark;
                        height: 18px;
                        width: 18px;
                        &:after{
                            content: none;
                        }
                     }
                     .text{
                        margin-right: auto;
                        margin-left: 20px;
                    }
                    &.selected{
                        span.check-mark{
                            background-color: $rhenus-blue;
                            &:after{
                                content: "check";
                                @include material-icon;
                                font-size: 17px;
                                position: absolute;
                                top: -2px;
                                left: -1px;
                                color: $white;
                                width: auto;
                                height: auto;
                                border-style: unset;
                                border-width: unset;
                                transform: none;
                                -webkit-transform: none;
                                -ms-transform: none;
                                -o-transform: none;
                            }
                        }
                    }
                 }
             }
         }
     }
 }
