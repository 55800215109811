.mfp-content{
    .mfp-close{
        position: absolute;
        top: -45px;
        right: 30px;
        color: #fff;
        font-size: 40px;
        font-family: auto;
    }
    #tx-solr-faceting{
      width: 100%;
    }
}

/* Popup Animation */
/* overlay at start */
.mfp-fade.mfp-bg {
    transform: translateX(100vw);
    transition: all 0.15s ease-out;
  }
  /* overlay animate in */
  .mfp-fade.mfp-bg.mfp-ready {
    transform: translateX(0);
  }
  /* overlay animate out */
  .mfp-fade.mfp-bg.mfp-removing {
    transform: translateX(100vw);
  }

  /* content at start */
  .mfp-fade.mfp-wrap .mfp-content {
    transform: translateX(100vw);
    transition: all 0.15s ease-out;
  }
  /* content animate it */
  .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    transform: translateX(0);
  }
  /* content animate out */
  .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    transform: translateX(100vw);
  }

  #mobile-filter-form{
    width: 100%;
  }
