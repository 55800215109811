.callmeback-trigger {
    > label {
        display: none;
    }  

    .checkbox {
        cursor: pointer;
        max-width: 540px;
    }
  
    .custom-radio-checkbox.checkbox {
        height: 65px;
        width: 100%;
        max-width: 540px;
        background: $rhenus-blue;
        background: linear-gradient(315deg, transparent 17px, #e1e9f3 17px);
        position: relative;
        padding-left: 130px;
        padding-top: 19px;
        text-transform: uppercase;

        &:before {
            position: absolute;
            display: inline-block;
            left: 14px;
            top: 14px;
            content: "phone";
            @include material-icon;
            font-size: 36px;
            color: $rhenus-blue;
        }

        &:after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            background-color: $white;
            position: absolute;
            left: 66px;
            top: 0;
        }

        .checkmark {
            position: absolute;
            left: 80px;
            top: 15px;
            border: 0;
            background-color: transparent;
            color: $rhenus-gray-dark;
            &:before {
                content: "add";
                @include material-icon;
                display: block;
                font-size: 34px;
            } 
        }

        &:hover {
            .checkmark {
                border: 0;
                background-color: transparent;
                color: $rhenus-blue;
            }
        }
    }

    &.active {
        .custom-radio-checkbox.checkbox {
            background: linear-gradient(315deg, transparent 17px, $rhenus-blue 17px);
            color: $white;
            
            &:before {
                color: $white;
            }

            .checkmark {
                border: 0;
                background-color: transparent;
                color: $white;
                &:before {
                    content: "remove";
                } 

                &:after {
                    display: none;
                }
            }
        }
    }
} 

.callmeback-trigger + .powermail_fieldwrap {
    display: none;
}

.callmeback-trigger.active + .powermail_fieldwrap {
    display: block;

    &.hide-callback {
        display: none;
    }
}

.hide-callback {
    display: none;
}