// frame-layout-4 -> zweispaltig
.frame-layout-4{
    .ce-bodytext{
        column-count: 2;
    }
    @include media-breakpoint-up(sm) {    
        .ce-bodytext{
            column-gap: 30px;
        }
    }
    @include media-breakpoint-down(sm) {
        .ce-bodytext{
            column-count: 1;
        }
    }
}

// frame-layout-5 -> dreispaltig
.frame-layout-5{ 
    .ce-bodytext{
        column-count: 3;
    }
    @include media-breakpoint-up(sm) {     
        .ce-bodytext{
            column-gap: 30px; 
        }
    }
    @include media-breakpoint-down(sm) { 
        .ce-bodytext{
            column-count: 1;
        }
    }
} 