@import "../variables";

.highlighted-text-container {
    position:    relative;
    display:     flex;
    align-items: center;
    min-height:  240px;
    margin-top:  90px;
    text-align:  center;
    color:       $white;
    background:  $rhenus-blue;
    background:  linear-gradient(90deg, rgba($rhenus-blue, 1) 0%, rgba($rhenus-blue-secondary, 1) 100%);

    @include media-breakpoint-up(md) {
        min-height: 335px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 70px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 0;
    }

    &-slashes {
        position: absolute;
        width:    100%;
        height:   100%;
    }

    &-before,
    &-after {
        position:         absolute;
        top:              -90px;
        right:            50%;
        display:          block;
        width:            75px;
        height:           180px;
        content:          "";
        transform:        skewX(136deg);
        opacity:          0.2;
        background-color: $rhenus-gray-dark;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        @include media-breakpoint-down(md) {
            top:    -65px;
            width:  52px;
            height: 130px;
        }
    }

    &-before {
        @include media-breakpoint-only(xl) {
            margin-right: -470px;
        }

        @include media-breakpoint-only(lg) {
            margin-right: -380px;
        }

        @include media-breakpoint-down(md) {
            margin-right: -285px;
        }

        @include media-breakpoint-down(sm) {
            margin-right: -160px;
        }

        @include media-breakpoint-down(xs) {
            margin-right: -95px;
        }
    }

    &-after {
        @include media-breakpoint-only(xl) {
            margin-right: -365px;
        }

        @include media-breakpoint-only(lg) {
            margin-right: -275px;
        }

        @include media-breakpoint-down(md) {
            margin-right: -215px;
        }

        @include media-breakpoint-down(sm) {
            margin-right: -90px;
        }

        @include media-breakpoint-down(xs) {
            margin-right: -25px;
        }
    }

    &.merge-bottom-element {
        height:         450px;
        margin-bottom:  -80px;
        padding-bottom: 80px;

        .highlighted-text-container-slashes {
            .highlighted-text-container-after, .highlighted-text-container-before {
                top: -40px;
            }
        }

        .frame-space-after-global-space-after-none & {
            margin-bottom: -40px;

            @include media-breakpoint-up(lg) {
                margin-bottom: -40px;
            }
        }

        .frame-space-after-global-space-after-extra-small & {
            margin-bottom: -80px;
        }

        .frame-space-after-global-space-after-small & {
            margin-bottom: -80px;

            @include media-breakpoint-up(lg) {
                margin-bottom: -120px;
            }
        }

        .frame-space-after-global-space-after-medium & {
            margin-bottom: -80px;

            @include media-breakpoint-up(lg) {
                margin-bottom: -136px;
            }
        }

        .frame-space-after-global-space-after-large & {
            margin-bottom: -80px;

            @include media-breakpoint-up(lg) {
                margin-bottom: -144px;
            }
        }
    }

    &.merge-top-element {
        margin-top: -25px;
    }

    .container {
        padding:  90px 30px 30px;
        position: relative;

        @include media-breakpoint-down(sm) {
            padding: 30px;
        }

        @include media-breakpoint-up(lg) {
            padding: 126px 15px 66px;
        }

        &.highlighted-text-content-box {
            z-index: 5;
        }
    }

    .headline {
        margin-bottom: 24px;

        svg {
            display:        inline-block;
            width:          auto;
            height:         65px;
            margin-right:   5px;
            vertical-align: -18px;

            @include media-breakpoint-down(md) {
                height:         45px;
                vertical-align: -11px;
            }

            * {
                fill: $white;
            }

            &.replaced-svg:not(.rhenus-icon) {
                position:     relative;
                top:          -20px;
                height:       auto;
                margin-right: 20px;
                transform:    scale(2.5);
            }
        }

        .rhenus-icon-container {
            display:        inline-block;
            overflow:       hidden;
            width:          90px;
            height:         86px;
            vertical-align: -27px;

            @include media-breakpoint-down(md) {
                width:          70px;
                height:         56px;
                vertical-align: -16px;
            }

            img {
                width:      100%;
                height:     auto;
                margin-top: -86px;

                @include media-breakpoint-down(md) {
                    margin-top: -75px;
                }
            }
        }
    }

    .highlighted-text-cta {
        display:    block;
        margin-top: 20px;

        @include media-breakpoint-up(md) {
            display: inline-block;
            margin:  20px 20px 0;
        }
    }

    .highlighted-text-contact-person {
        position: absolute;
        bottom:   0;
        left:     0;
        right:    calc(75% - 15px);
        overflow: hidden;
        display:  none;

        @include media-breakpoint-up(xl) {
            display: block;
        }

        img {
            object-fit: contain;
        }
    }
}

.bilgenentoelung .highlighted-text-container {
    color:      $rhenus-gray-dark;
    background: #ffe169;
    background: linear-gradient(90deg, #ffe169, #ffcc00 100%);

    .highlighted-text-cta a {
        color: $rhenus-gray-dark;
    }
}

.rheintank .highlighted-text-container {
    background: linear-gradient(90deg, #003366, #376ca2 100%);
}
