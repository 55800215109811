.solr-pagination {
    display:         flex;
    justify-content: center;
    margin:          40px 0 64px;
    
    .page-link {
        &.prev, &.next {
            width:      38px;
            height:     38px;
            padding:    7px;
            background: $white;
            
            i.material-icons {
                top: unset;
            }
        }
        
        &.prev {
            margin-right: 1px;
        }
    }
}