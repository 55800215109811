.accordion-item {
    display:    flex;
    flex-flow:  column nowrap;
    border-top: 1px solid $rhenus-gray-light;

    &:last-child {
        border-bottom: 1px solid $rhenus-gray-light;
    }
}

.accordion-item__header {
    color:  $rhenus-blue;
    cursor: pointer;

    > div[role="button"] {
        display:     flex;
        flex-flow:   row nowrap;
        align-items: center;
        gap:         16px;
        padding:     16px 0;

        @include media-breakpoint-up(lg) {
            &:hover {
                &[aria-expanded='false'] {
                    @include pulse-animation(2s, 0);
                }
            }
        }

        .accordion-item__icon {
            flex-grow: 0;

            img {
                height: 24px !important;
                width:  32px !important;
            }
        }

        .accordion-item__title {
            font-size:   16px;
            line-height: 20px;
            font-weight: bold;
            flex-grow:   1;
        }

        .accordion-item__arrow {
            flex-grow:  0;
            transition: transform .3s ease-in-out;
            color:      $rhenus-gray-dark;
        }

        &[aria-expanded='true'] {
            .accordion-item__arrow {
                transform: rotate(180deg);
            }
        }
    }
}

.accordion-item__body {
    padding: 0 0 16px;

    .accordion-item__sub-title {
        font-size:     16px;
        line-height:   20px;
        font-weight:   bold;
        margin-bottom: 16px;
    }

    .accordion-item__content {
        p:last-child {
            margin-bottom: 0;
        }

        .accordion-item_has-tab-content & {
            margin-bottom: 16px;
        }
    }

    .accordion-item__tab-content {
        .frame-type-textmedia {
            margin-bottom: 0 !important;
            width:         unset !important;

            p:last-child {
                margin-bottom: 0;
            }
        }
    }
}
