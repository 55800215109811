.ce-textpic {
    gap: 24px;

    @include media-breakpoint-up(lg) {
        &.ce-right,
        &.ce-left {
            gap: 0;
        }
    }

    &.ce-intext {
        @include media-breakpoint-down(md) {
            display:   flex;
            flex-flow: column nowrap;
            gap:       24px;
        }
    }

    .gallery-col {
        display:   flex;
        flex-flow: column nowrap;
        gap:       24px;

        .video.col,
        .image.col {
            @at-root .float-left {
                padding-left:  0;
                padding-right: 0;

                @include media-breakpoint-up(lg) {
                    padding-left:  0;
                    padding-right: 32px;
                }
            }

            @at-root .float-right {
                padding-left:  0;
                padding-right: 0;

                @include media-breakpoint-up(lg) {
                    padding-left:  32px;
                    padding-right: 0;
                }
            }
        }
    }

    & > .ce-gallery {
        .ce-row {
            margin: 0px;

            & > .ce-column {
                margin:  0px;
                padding: 5px;
                width:   100%;

                & > figure {
                    width: 100%;
                }

                img {
                    width:  100%;
                    height: auto;
                }
            }
        }
    }

    & > .ce-gallery[data-ce-columns="2"] {
        .ce-row {
            & > .ce-column {
                width: 50%;
            }
        }
    }

    & > .ce-gallery[data-ce-columns="3"] {
        .ce-row {
            & > .ce-column {
                @media (min-width: 992px) {
                    width: 33.33333%;
                }
            }
        }
    }

    & > .ce-gallery[data-ce-columns="4"] {
        .ce-row {
            & > .ce-column {
                width: 50%;
                @media (min-width: 992px) {
                    width: 25%;
                }
            }
        }
    }

    & > .ce-gallery[data-ce-columns="5"] {
        .ce-row {
            & > .ce-column {
                width: 50%;
                @media (min-width: 992px) {
                    width: 20%;
                }
            }
        }
    }

    & > .ce-gallery[data-ce-columns="6"] {
        .ce-row {
            & > .ce-column {
                width: 50%;
                @media (min-width: 768px) {
                    width: 33.3333%;
                }
                @media (min-width: 992px) {
                    width: 16.6666%;
                }
            }
        }
    }
}

.ce-textpic {
    figure.image.col {
        a,
        img {
            display: block;
        }
    }
}
