a {
    color: $rhenus-blue;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        color: $rhenus-blue;
    }

    &:active {
        color: $rhenus-blue-dark;
    }

    &:disabled {
        color: $rhenus-gray-dark;
    }

    &.color-light {
        color: $white;

        &:hover,
        &:focus,
        &:active {
            color: $white;
        }
    }
}

.link-chevron {
    display: inline-block;
    position: relative;
    margin-left: 20px;

    &:before {
        @include material-icon;
        content: "chevron_right";
        position: absolute;
        left: -26px;
        font-size: 24px;
    }
}

.button-primary,
.button-secondary,
.button-ghost,
.button-white {
    display: inline-block;
    min-height: 48px;
    line-height: 1rem;
    padding: 13px 40px 14px;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 0;
    cursor: pointer;
    &:before {
        content: "";
        display: none;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
    @include media-breakpoint-down(md){
        font-size: 18px;
        padding-left: 16px;
        padding-right: 16px;
    }
    &.w-mc{
        width: max-content;
    }
}

.button-primary {
    background-color: $rhenus-orange;
    border: 2px solid $rhenus-orange;
    color: $white;

    &:hover {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
        color: $white;
    }

    &:active {
        color:#fff;
        border-color: $rhenus-orange;
        background-color: $rhenus-orange;
    }

    &:focus{
        border-color: $rhenus-blue-focus-border;
        border: 2px solid #9b9fa0;
        color: $white;
    }

    &:disabled {
        background-color: $rhenus-gray !important;
        color: $rhenus-gray-dark;
        cursor: default;
    }
}

.button-secondary {
    background-color: $rhenus-blue-secondary;
    border: 2px solid $rhenus-blue-secondary;
    color: $white;

    &:hover {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
        color: $white;
    }

    &:active {
        background-color: $rhenus-blue-secondary-dark;
        color: $white;
    }

    &:focus{
        border-color: $rhenus-blue-focus-border;
        border: 2px solid #8b9fc0;
        color: $white;
    }

    &:disabled {
        background-color: $rhenus-gray;
        color: $rhenus-gray-dark;
        cursor: default;
    }
}

.button-ghost {
    background-color: transparent;
    color: $rhenus-blue-secondary;
    border: 2px solid $rhenus-blue-secondary;

    &:hover {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
        background-color: $rhenus-blue-secondary;
        color: $white;
    }

    &:active {
        background-color: $rhenus-blue-secondary-dark;
        color: $white;
    }

    &:focus{
        border-color: $rhenus-blue-focus-border;
    }

    &:disabled {
        background-color: transparent;
        border: 2px solid $rhenus-gray;
        color: $rhenus-gray-dark;
        cursor: default;
    }

    &.light {
        background-color: transparent;
        color: $white;
        border: 2px solid $white;

        &:hover {
            background-color: $white;
            color: $rhenus-blue-secondary;
        }

        &:active {
            box-shadow: 0 2px 20px 0 rgba(0,0,0,0.2);
            background-color: $white;
            color: $rhenus-blue-secondary;
        }

        &:focus {
            background-color: $white;
            color: $rhenus-blue-secondary;;
            border: 2px solid $rhenus-blue-focus-border;
        }
    }
}
