.solr-searchbar {
    .last-searches-block {
        position: relative;
        width: calc(90% - 163px);
        height: auto;
        margin-top: 1px;
        background: #fff;
        box-shadow: $box-shadow;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .solr-searchbar__page-headline {
        margin-bottom: 72px;
        line-height: 3rem;
        @include media-breakpoint-down(sm){
            margin-bottom: 106px;
        }
    }

    .solr-searchbar__search-headline {
        margin-bottom: 23px;

        .tune-icon {
            display: none;
        }
    }

    .solr-searchbar__search-div {
        width: 90%;
        flex-direction: row;

        .solr-searchbar__search-input-wrapper {
            flex: 1;
            margin-right: 5px;
            position: relative;

            .form-control {
                border-radius: 0;
                border: none;
                height: 41px;
                border-bottom: 1px solid black;
                box-shadow: none;

                @include media-breakpoint-down(sm){
                    height: 48px;
                    +#cancel-search-btn{
                        padding-top: 11px;
                    }
                }
            }
        }
    }

    .solr-searchbar__search-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        border: none;
        min-width: 160px;
        height: 40px;
        border-bottom: 1px solid $rhenus-blue;

        .search-icon {
            position: relative;
            font-size: 22px;
            margin-right: 6px;
            background-color: transparent;
        }

        .search-btn-text {
            font-size: 1rem;
            position: relative;
            top: 1px;
            text-transform: uppercase;
        }

        @include media-breakpoint-down(sm){
            height: 48px;
            .search-icon {
                margin-right: 12px;
            }
        }

    }

    @include media-breakpoint-down(sm) {
        .solr-searchbar__search-headline {
            margin-bottom: 35px;

            .tune-icon {
                display: inline;
                margin-right: 11px;
                font-size: 22px;
                position: relative;
                top: 3px;
            }
        }

        .solr-searchbar__search-div {
            flex-direction: column;
            width: 100%;

            .solr-searchbar__search-input-wrapper {
                flex-direction: row;

                #solr-searchbar-input {
                    width: 100%;
                }
            }

            .solr-searchbar__search-btn {
                width: 100%;
                margin-top: 23px;
            }
        }
    }
}

#cancel-search-btn,
#cancel-search-btn-nav,
#cancel-search-btn-nav-mobile {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    box-shadow: none;
    padding-top: 8px;

    &.hide {
        display: none;
    }

    .cancel-icon {
        font-size: 20px;
        color: $rhenus-gray-light;
    }
}

