.stripe-container {
    &.top-offset {
        margin-top: 120px;

        .container {
            transform: translateY(-160px);

            .frame-type-rscwcontentelements_text_teaser {
                margin-bottom: -160px;
            }
        }
    }

    .stripe {
        padding: 40px 0 !important;

        @include media-breakpoint-up(lg) {
            padding: 80px 0;
        }

        &.stripe-bg-blue {
            background-color: $rhenus-blue-accordion-header;

            .default-teaser-container.active-slideshow {
                &:before,
                &:after {
                    background-color: $rhenus-blue-accordion-header;
                }
            }
        }

        &.stripe-bg-grey {
            background-color: $rhenus-section-grey;

            .default-teaser-container.active-slideshow {
                &:before,
                &:after {
                    background-color: $rhenus-section-grey;
                }
            }
        }
    }

    .tx_solr {
        padding-left:  0;
        padding-right: 0;
    }
}
