.loading-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loading-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.loading-spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.loading-spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.loading-spinner div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// Markup Spinner:
// <div class="lds-ring"><div></div><div></div><div></div><div></div></div>

/* loading spinner used in the solr and newsroom filter popup */
.spinner-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 99999;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}