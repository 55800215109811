@import "../variables";

@font-face {
    font-family: "Material Icons";
    font-style:  normal;
    font-weight: 400;
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/MaterialIcons-Regular.eot"); /* For IE6-8 */
    src:         local("Material Icons"),
                 local("MaterialIcons-Regular"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/MaterialIcons-Regular.woff2") format("woff2"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/MaterialIcons-Regular.woff") format("woff"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/MaterialIcons-Regular.ttf") format("truetype");
    font-display: swap;
}

@mixin material-icon() {
    font-family:             "Material Icons";
    font-weight:             normal;
    font-style:              normal;
    display:                 inline-block;
    line-height:             1;
    text-transform:          none;
    letter-spacing:          normal;
    word-wrap:               normal;
    white-space:             nowrap;
    direction:               ltr;
    -webkit-font-smoothing:  antialiased;
    text-rendering:          optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings:   "liga";
}

.material-icons {
    font-family:             "Material Icons";
    font-size:               16px;
    font-weight:             normal;
    font-style:              normal;
    line-height:             1;
    display:                 inline-block;
    white-space:             nowrap;
    letter-spacing:          normal;
    text-transform:          none;
    word-wrap:               normal;
    direction:               ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing:  antialiased;
    /* Support for Safari and Chrome. */
    text-rendering:          optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings:   "liga";
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 18, 2019 */
/*
@font-face {
    font-family: "TradeGothicNextLT";
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicNextLTW05-Rg.eot");
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicNextLTW05-Rg.eot?#iefix") format("embedded-opentype"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicNextLTW05-Rg.woff2") format("woff2"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicNextLTW05-Rg.woff") format("woff");
    font-weight: normal;
    font-style:  normal;

}

@font-face {
    font-family: "TradeGothicNextLT";
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicNextLTW05-Bold.eot");
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicNextLTW05-Bold.eot?#iefix") format("embedded-opentype"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicNextLTW05-Bold.woff2") format("woff2"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicNextLTW05-Bold.woff") format("woff");
    font-weight: bold;
    font-style:  normal;

}
*/

// TradeGothicLTW05-Light
@font-face {
    font-family: "TradeGothicLTW05";
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicLTW05-Light.woff2") format("woff2"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicLTW05-Light.woff") format("woff");
    font-weight: 300;
    font-style:  normal;
    font-display: swap;
}

// TradeGothicLTW05-Roman
@font-face {
    font-family: "TradeGothicLTW05";
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicLTW05-Roman.woff2") format("woff2"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicLTW05-Roman.woff") format("woff");
    font-weight: normal;
    font-style:  normal;
    font-display: swap;
}

// TradeGothicLTW05-BoldNo.2
@font-face {
    font-family: "TradeGothicLTW05";
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicLTW05-BoldNo.2.woff2") format("woff2"),
                 url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicLTW05-BoldNo.2.woff") format("woff");
    font-weight: bold;
    font-style:  normal;
    font-display: swap;
}

// TradeGothicLTW05-CondNo.18
/*
@font-face {
    font-family: "TradeGothicLTW05";
    src:         url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicLTW05-CondNo.18.woff2") format("woff2"), url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/TradeGothicLTW05-CondNo.18.woff") format("woff");
}
*/

/* cyrillic-ext */
@font-face {
    font-family:  "PT Sans";
    font-style:   italic;
    font-weight:  300;
    font-display: swap;
    src:          url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/PTSans-Italic.ttf") format("woff2");
    font-display: swap;
}

@font-face {
    font-family:  "PT Sans";
    font-style:   italic;
    font-weight:  bold;
    font-display: swap;
    src:          url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/PTSans-BoldItalic.ttf") format("woff2");
    font-display: swap;
}

@font-face {
    font-family:  "PT Sans";
    font-style:   normal;
    font-weight:  normal;
    font-display: swap;
    src:          url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/PTSans-Regular.ttf") format("woff2");
    font-display: swap;
}

@font-face {
    font-family:  "PT Sans";
    font-style:   normal;
    font-weight:  bold;
    font-display: swap;
    src:          url("$typo3conf/ext/rscw_provider/Resources/Public/Fonts/PTSans-Bold.ttf") format("woff2");
    font-display: swap;
}
