.page-body,.popover[role=tooltip] {
    ul:not(.unstyled-list),
    ol:not(.unstyled-list) {
        li {
            margin: 16px 0;
        }
    }

    ul:not(.unstyled-list) {
        padding: 0;
        list-style: none;
        li {
            position: relative;
            margin-left: 28px;
            &:before {
                content: "";
                height: 14px;
                width: 22px;
                left: -28px;
                top: 7px;
                position: absolute;
                display: inline-block;
                background: url('$typo3conf/ext/rscw_datenmuehle/Resources/Public/Images/Icon/rhenus-slash.svg') top left no-repeat transparent;
                background-size: contain;
            }
            &:first-child {
                margin-top: 0;
            }
        }
    }

    ol:not(.unstyled-list) {
        margin-left: -15px;
        li {
            padding-left: 8px;
            &:before {
                content: "";
                display: inline-block;
                min-height: 16px;
            }
        }
    }

    ul.unstyled-list,
    ol.unstyled-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 8px 0;
        }
    }

    ul.country-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li{
            width: 25%;
            margin-top: 0;
            margin-bottom: 16px;
            margin-left: 0;
            display: flex;
            align-items: center;
            &:before{
                @extend .material-icons;
                content: "location_on";
                font-size: 18px;
                display: inline-block;
                line-height: 1.5;
                background: none;
                height: unset;
                width: unset;
                position: unset;
                padding-right: 4px;
            }
        }
    }
}

ul.two-columns{
    column-count: 2;
    @include media-breakpoint-down(sm) {
       column-count: 1;
    }
}
ul.three-columns{
    column-count: 3;
    @include media-breakpoint-down(md) {
        column-count: 2;
    }
    @include media-breakpoint-down(sm) {
        column-count: 1;
    }
}
